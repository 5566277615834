import { lazy } from 'react';
import { Routes } from 'routing/route';
import { RouteTypeMassive } from 'routing/routesTypes';
import { FileOutlined } from '@ant-design/icons';

const TmsReportsOverviewPage = lazy(() =>
    import('pages/tms-reports/TmsReportsOverviewPage').then((module) => ({
        default: module.TmsReportsOverviewPage
    }))
);
const MarginOrders_Page = lazy(() =>
    import('pages/tms-reports/margin-orders/MarginOrders_Page').then((module) => ({
        default: module.MarginOrders_Page
    }))
);
const RequestSchedulersPage = lazy(() =>
    import('pages/tms-reports/request-schedulers/RequestSchedulersPage').then((module) => ({
        default: module.RequestSchedulersPage
    }))
);
const VehicleProvisionPage = lazy(() =>
    import('pages/tms-reports/vehicle-provision/VehicleProvisionPage').then((module) => ({
        default: module.VehicleProvisionPage
    }))
);

const TmsReportApps: RouteTypeMassive[] = [
    {
        path: '',
        tablePage: TmsReportsOverviewPage,
        config: {
            icon: <FileOutlined />
        }
    },
    {
        path: 'margin-orders',
        tablePage: MarginOrders_Page,
        config: {
            icon: <FileOutlined />
        }
    },
    {
        path: 'request-schedulers',
        tablePage: RequestSchedulersPage,
        config: {
            icon: <FileOutlined />
        }
    },
    {
        path: 'vehicle-provision',
        tablePage: VehicleProvisionPage,
        config: {
            icon: <FileOutlined />
        }
    }
];

export const TmsReportsRoutes = new Routes('/tms-reports', TmsReportApps);
