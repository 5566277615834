import { DEFAULT_FILTER_OPERTAION, FilterField } from '../types';
import { isRangeType } from './isRangeType';
import { isArrayType } from './isArrayType';

export const getDefaultFilterOperator = (filterField: FilterField) => {
    if (
        filterField.field.ValueType?.includes('code') ||
        filterField.field.ValueType?.includes('key') ||
        filterField.field.ValueType?.includes('id') ||
        filterField.field.ValueType?.includes('type') ||
        filterField.field.ValueType?.includes('status') ||
        filterField.field.ValueType?.includes('provision_indicator')
    ) {
        return 'equals';
    }

    if (filterField.field.ValueType?.includes('array_id')) {
        return 'contains';
    }

    if (
        filterField.field.ValueType?.includes('date') ||
        filterField.field.ValueType?.includes('datetime')
    ) {
        return 'between';
    }

    if (
        filterField.field.ValueType?.includes('text') ||
        filterField.field.ValueType?.includes('multilang_text')
    ) {
        return 'contains';
    }

    if (isRangeType(filterField.field.ValueType)) {
        return 'contains';
    }

    if (isArrayType(filterField.field.ValueType)) {
        return 'contains';
    }

    return DEFAULT_FILTER_OPERTAION;
};
