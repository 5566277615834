import { createItvecturaClient } from './itvecturaClient';
import { createKeycloakClient } from './keycloakClient';
import { createSupabaseClient } from './supabaseClient';
import { ApiClient, ApiClientConfig, Session } from './types';

export const createApiClient = (config: ApiClientConfig): ApiClient => {
    const { authProvider, session } = config;

    if (authProvider === 'supabase') {
        const supabaseClient = createSupabaseClient();
        if (session) {
            // Восстанавливаем сессию, если передана
            supabaseClient.session = session;
        }
        return supabaseClient;
    }

    if (authProvider === 'keycloak') {
        const keycloakClient = createKeycloakClient();
        if (session) {
            keycloakClient.session = session;
        }
        return keycloakClient;
    }

    // if (authProvider === 'itvectura') {
    const itvecturaClient = createItvecturaClient();
    if (session) {
        // Восстанавливаем сессию, если передана
        itvecturaClient.session = session;
    }
    return itvecturaClient;
    // }

    // throw new Error(`Unsupported auth provider: ${authProvider}`);
};
