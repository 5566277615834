import { MetaField } from '../../modules/services/backend-api/generated_info';
import { parseValueType } from './parseValueType';
import { metaStore } from '../../utils/store/MetaStore';

export const transformFilterString = async (
    filterString: string,
    fields: MetaField[],
    language: string
): Promise<Record<string, any>> => {
    const result: Record<string, any> = {};

    // Разделение строки на пары FieldName=operator.value
    const pairs = filterString.split('&');

    for (const pair of pairs) {
        const [fieldPart, valuePart] = pair.split('=');
        const [operator, value] = valuePart.split('.');

        // Мы работаем только с оператором "eq"
        if (operator !== 'eq') continue;

        // Находим соответствующее описание поля
        const fieldMeta = fields.find((field) => field.FieldName === fieldPart);
        if (!fieldMeta?.ValueType) continue;
        // Парсим ValueType

        const { type, options } = parseValueType(fieldMeta.ValueType, language);

        // Преобразуем value на основе типа
        let transformedValue;

        if (options?.ref) {
            const meta = options.ref?.meta as string; // metа достаем из ref типе
            const fieldNameForRequestsFilter = options.ref.fieldName;

            if (type === 'code' || type === '*code') {
                // Запрос через metaStore.makeSelect для типа code
                const resultArray = await metaStore.makeSelect({
                    meta,
                    filters: `${fieldNameForRequestsFilter}=eq.${value}`
                });

                transformedValue = resultArray?.objects?.[0]; // Берем первый элемент, так как должен быть один
            } else if (type === 'object_id' || type === '*object_id') {
                // Запрос через metaStore.makeGet для типа object_id
                const resultObject = await metaStore.makeGet({ meta, id: value });
                transformedValue = resultObject?.object;
            }
        } else {
            // Для других типов без ref, например *code, оставляем строку
            transformedValue = value;
        }

        // Записываем результат
        result[fieldPart] = transformedValue;
    }

    return result;
};
