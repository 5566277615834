import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { removeUndefinedFilters } from 'smart/utils';
import { FilterField } from '../../components/SmartTableFilterMenu/types';
import {
    buildFilter,
    convertComplexFilterToSupabaseFilter
} from '../../components/SmartTableFilterMenu/utils';

export const useFilterToStringConverter = () => {
    const { i18n } = useTranslation();

    const converter = useCallback(
        (filters: FilterField[]) => {
            // console.log('convertable filters', filters);
            const convertedToSupabase = convertComplexFilterToSupabaseFilter(
                filters,
                i18n.language
            );

            const result = removeUndefinedFilters(
                buildFilter(convertedToSupabase)
                    .filter(
                        (f) =>
                            !f.includes('eq.undefined') ||
                            (f.includes('eq.undefined') && f.includes('or('))
                    )
                    .join('&')
            );
            // .replaceAll('neq.undefined', 'isnot.null')
            // .replaceAll('eq.undefined', 'is.null');

            // console.log('cnvertation result', result);

            return result;
        },
        [i18n.language]
    );

    return converter;
};
