import { Filter } from 'modules/supabase/utils/supabaseClient';

import { ConvertFilterFunctionType } from '../../../types';
import { convertUIFilterColumnName } from './convertUIFilterColumnName';
import { convertUIFilterValue } from './convertUIFilterValue';
import { mapMUITableFilterOperationAndSupabaseFilterOperation } from './mapMUITableFilterOperationAndSupabaseFilterOperation';

export const convertRangeFieldWithContainOptionToFilter = (
    rawFilterSetting: ConvertFilterFunctionType
): Filter => {
    const lessThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('lessThanOrEqualTo');

    const filterValueFrom: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field.ColumnName,
            lessThanOrEqualTo,
            rawFilterSetting.language,
        ),
        operator: lessThanOrEqualTo,
        value: rawFilterSetting.complexFilterValue.value
    };

    filterValueFrom.value = convertUIFilterValue(
        filterValueFrom.value,
        filterValueFrom.column,
        rawFilterSetting.language,
        filterValueFrom.operator,
        rawFilterSetting.complexFilter.field.ValueType
    );

    const greaterThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('greaterThanOrEqualTo');

    const filterValueTo: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field.ColumnName,
            greaterThanOrEqualTo,
            rawFilterSetting.language,
        ),
        operator: greaterThanOrEqualTo,
        value: rawFilterSetting.complexFilterValue.value,
        ANDCondition: filterValueFrom
    };

    filterValueTo.value = convertUIFilterValue(
        filterValueTo.value,
        filterValueTo.column,
        rawFilterSetting.language,
        filterValueTo.operator,
        rawFilterSetting.complexFilter.field.ValueType
    );

    return filterValueTo;
};
