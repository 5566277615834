import { ConfigProvider, ThemeConfig } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import merge from 'deepmerge';
import { I18nextProvider, useTranslation } from 'react-i18next';
//
import { Locale } from 'antd/lib/locale';
import AZLocale from 'antd/lib/locale/az_AZ';
import ENLocale from 'antd/lib/locale/en_US';
import KKLocale from 'antd/lib/locale/kk_KZ';
import RULocale from 'antd/lib/locale/ru_RU';
import TRLocale from 'antd/lib/locale/tr_TR';
// import { TablePageInfoProvider } from 'components/DataTable/useTablePageInfo';
import { i18n } from 'utils/i18n/i18n';
import { metaStore } from 'utils/store/MetaStore';
import { toJS } from 'mobx';
import { useLogos } from 'App';
import { parseEnvTheme } from 'utils/helpers/parseEnvTheme';

interface AppProvidersWrapperProps extends React.PropsWithChildren {
    // user: User | null;
    i18n: typeof i18n;
}

const locales: Record<string, Locale> = {
    ru: RULocale,
    en: ENLocale,
    kk: KKLocale,
    tr: TRLocale,
    az: AZLocale
};

export type THEME = ThemeConfig & {
    token: ThemeConfig['token'] & {
        asideMenuBg?: string;
        colorAccent?: string;
        colorAccentHover?: string;
        logoLarge?: string;
        logoMedium?: string;
        logoSmall?: string;
        favicon?: string;
    };
};

const defaultTheme: THEME = {
    token: {
        colorPrimary: '#3287d3',
        asideMenuBg: '#07204a',
        colorError: '#fc5e53'
        // colorTextSecondary: '#d3d3d3',
        // colorFillSecondary: '#d3d3d3'
    },
    components: {
        Button: {
            colorError: '#fc5e53',
            colorErrorHover: '#fc5e53e0'
        },
        Progress: {
            defaultColor: '#3287d3e0'
            // colorPrimary: '#3287d3'
        }
    }
};

type Token = THEME['token'];

export const AppProvidersWrapper = memo<AppProvidersWrapperProps>(({ i18n, children }) => {
    const { i18n: i18nT } = useTranslation();
    const [logos] = useLogos();

    const [theme, setTheme] = useState<THEME>(defaultTheme);
    const serverUserTheme = metaStore.meta.get('all')?.params?.APP_THEME;

    const constructAntDTheme = useCallback(
        (externalTheme?: Token) => {
            if (externalTheme) {
                const primaryColor = externalTheme.colorPrimary ?? defaultTheme.token.colorPrimary;

                const accentColor = externalTheme.colorAccent ?? defaultTheme.token.colorError;
                const accentHoverColor = externalTheme.colorAccentHover;

                const accentHoverColorDefault = accentColor ? `${accentColor}e0` : undefined;
                const accentHover = accentHoverColor ?? accentHoverColorDefault;

                return {
                    token: {
                        ...toJS(externalTheme),
                        logoSmall: logos.small,
                        logoMedium: logos.medium,
                        logoLarge: logos.large,
                        favicon: logos.favicon
                    },
                    components: {
                        Button: {
                            colorError: accentColor,
                            colorErrorHover: accentHover
                        },
                        Progress: {
                            defaultColor: `${primaryColor}e0`
                        }
                    }
                } as THEME;
            }

            return {};
        },
        [logos]
    );

    useEffect(() => {
        const envTheme = parseEnvTheme(window.env.APP_THEME);
        const envAntdTheme = constructAntDTheme(envTheme as Token);
        // console.log(envAntdTheme);

        const userTheme = constructAntDTheme(toJS(serverUserTheme) as Token);
        // console.log(userTheme);

        // Слияние темы из env, сервера и дефолтной темы
        const mergedTheme = merge.all([defaultTheme, envAntdTheme, userTheme]) as THEME;
        // console.log(mergedTheme);

        setTheme(mergedTheme);
    }, [constructAntDTheme, serverUserTheme]);

    return (
        <ConfigProvider theme={theme} locale={locales[i18nT.language || 'en']}>
            <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
        </ConfigProvider>
    );
});
