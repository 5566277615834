import { LabelValue } from '@gilbarbara/types';

import { parseTemplate } from 'smart/utils';
import { IObjectWithId } from 'utils/store/MetaStore';

import { renderOptionLabel } from './renderOptionLabel';

interface SelectTreeOption extends LabelValue {
    children?: SelectTreeOption[];
}

export const transformDataToOptions = (
    data: IObjectWithId[],
    viewFieldName: string,
    language: string,
    viewTemplate?: string
): SelectTreeOption[] => {
    // if (data.every((item) => !item.children))
    //     return data.map((item) => ({
    //         value: item.Code || item.Id,
    //         label: renderOptionLabel(item, myViewFieldName || viewFieldName)
    //     }));

    return data.map<SelectTreeOption>((item) => ({
        value: item.Id,
        label:
            parseTemplate(viewTemplate || '', language, { current: item, self: item }) ||
            renderOptionLabel(item, viewFieldName),
        children: item.children
            ? transformDataToOptions(item.children, viewFieldName, language, viewTemplate)
            : undefined
    }));
};
