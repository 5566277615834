import { Filter } from 'modules/supabase/utils/supabaseClient';
import { parseValueType } from 'smart/utils';

import dayjs from 'dayjs';
import { FilterField, FilterOption } from '../../types';
import { isArrayIdType } from '../isArrayIdType';
import { isRangeType } from '../isRangeType';
import {
    convertBetweenFilter,
    convertDynamicDateRangeToFiler,
    convertIdArrayFilterWithContainsOptionToFilter,
    convertInListToFilter,
    convertNotInListToFilter,
    convertRangeFieldWithContainOptionToFilter,
    convertUIFilterColumnName,
    convertUIFilterValue,
    mapMUITableFilterOperationAndSupabaseFilterOperation
} from './helpers';
import {
    DATE_FORMAT,
    DATE_TIME_LOCAL_FORMAT,
    TIME_FORMAT
} from '../../../../../../../utils/helpers/dates';

export const convertComplexFilterToSupabaseFilter = (
    complexFilters: FilterField[],
    language: string
): Filter[] => {
    const filter: Filter[] = [];

    for (let i = 0; i < complexFilters.length; i++) {
        const complexFilter = complexFilters[i];

        // console.log('complexFilters', complexFilter);

        const { type: dataType } = parseValueType(complexFilter.field.ValueType!, language);

        for (let j = 0; j < complexFilter.values.length; j++) {
            const complexFilterValue = complexFilter.values[j];
            const filterOperator = complexFilterValue.operator as string;
            const fValue = complexFilterValue.value;

            if (filterOperator) {
                // Обработка оператора in_list
                if (filterOperator === 'in_list') {
                    const inListFilter = convertInListToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...inListFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка оператора not_in_list
                if (filterOperator === 'not_in_list') {
                    const inListFilter = convertNotInListToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...inListFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка оператора dynamic_range
                if (filterOperator === 'dynamic_range') {
                    const dynamicRangeFilter = convertDynamicDateRangeToFiler({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...dynamicRangeFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка поля с типом массив ID [1,2,3...]
                if (isArrayIdType(dataType) && filterOperator === 'contains') {
                    const arrayIdContainsFilter = convertIdArrayFilterWithContainsOptionToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...arrayIdContainsFilter, field: complexFilter.field });
                    continue;
                }

                // Обработка поля с типом RANGE с оператором contains
                if (isRangeType(dataType) && filterOperator === 'contains') {
                    const rangeContainFilter = convertRangeFieldWithContainOptionToFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...rangeContainFilter, field: complexFilter.field });

                    continue;
                }

                // Обработка оператора between для полей с типом ДАТА / ДАТАВРЕМЯ
                if (filterOperator === 'between') {
                    // console.log(complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue,
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                const format =
                    dataType === 'date'
                        ? DATE_FORMAT
                        : dataType === 'time'
                        ? TIME_FORMAT
                        : DATE_TIME_LOCAL_FORMAT;

                if (filterOperator === 'lastHour') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs().subtract(1, 'hours').format(format),
                                ToDate: dayjs().format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'lastWeek') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs().subtract(7, 'days').format(format),
                                ToDate: dayjs().format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'lastMonth') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs().subtract(1, 'months').format(format),
                                ToDate: dayjs().format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'currentHour') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs()
                                    // .set('hours', 0)
                                    .set('minutes', 0)
                                    .set('seconds', 0)
                                    .set('millisecond', 0)
                                    .format(format),
                                ToDate: dayjs()
                                    // .set('hours', 23)
                                    .set('minutes', 59)
                                    .set('seconds', 59)
                                    .set('millisecond', 99)
                                    .format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'today') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs()
                                    .set('hours', 0)
                                    .set('minutes', 0)
                                    .set('seconds', 0)
                                    .set('millisecond', 0)
                                    .format(format),
                                ToDate: dayjs()
                                    .set('hours', 23)
                                    .set('minutes', 59)
                                    .set('seconds', 59)
                                    .set('millisecond', 99)
                                    .format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'tomorrow') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs()
                                    .add(1, 'days')
                                    .set('hours', 0)
                                    .set('minutes', 0)
                                    .set('seconds', 0)
                                    .set('millisecond', 0)
                                    .format(format),
                                ToDate: dayjs()
                                    .add(1, 'days')
                                    .set('hours', 23)
                                    .set('minutes', 59)
                                    .set('seconds', 59)
                                    .set('millisecond', 99)
                                    .format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'currentWeek') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs()
                                    .startOf('weeks')
                                    .add(1, 'days')
                                    .set('hours', 0)
                                    .set('minutes', 0)
                                    .set('seconds', 0)
                                    .set('millisecond', 0)
                                    .format(format),
                                ToDate: dayjs()
                                    .endOf('weeks')
                                    .add(1, 'days')
                                    .set('hours', 23)
                                    .set('minutes', 59)
                                    .set('seconds', 59)
                                    .set('millisecond', 99)
                                    .format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                if (filterOperator === 'currentMonth') {
                    // console.log(complexFilter, complexFilterValue);
                    const betweenFilter = convertBetweenFilter({
                        complexFilter,
                        complexFilterValue: {
                            ...complexFilterValue,
                            value: {
                                FromDate: dayjs()
                                    .startOf('months')
                                    .set('hours', 0)
                                    .set('minutes', 0)
                                    .set('seconds', 0)
                                    .set('millisecond', 0)
                                    .format(format),
                                ToDate: dayjs()
                                    .endOf('months')
                                    .set('hours', 23)
                                    .set('minutes', 59)
                                    .set('seconds', 59)
                                    .set('millisecond', 99)
                                    .format(format)
                            }
                        },
                        language
                    });

                    filter.push({ ...betweenFilter, field: complexFilter.field });

                    continue;
                }

                // Обработка всех остальных полей
                let operator = mapMUITableFilterOperationAndSupabaseFilterOperation(
                    filterOperator as FilterOption
                );

                if (operator !== 'neq' && !fValue) {
                    operator = 'eq';
                }

                const filterValue: Filter = {
                    column: convertUIFilterColumnName(complexFilter.field, operator, language),
                    operator,
                    value: undefined
                };

                // Обработка опции пусто/не пусто
                if (filterOperator === 'empty') {
                    filter.push({
                        ...filterValue,
                        operator: 'is',
                        value: null,
                        field: complexFilter.field
                    });
                    continue;
                }

                if (filterOperator === 'notEmpty') {
                    filter.push({
                        ...filterValue,
                        operator: 'isnot',
                        value: null,
                        field: complexFilter.field
                    });
                    continue;
                }

                let value;

                if (fValue) {
                    if (dataType.includes('code')) {
                        value = typeof fValue === 'object' ? fValue.Code : fValue;
                    } else if (dataType.includes('id')) {
                        value = typeof fValue === 'object' ? fValue.Id : fValue;
                    } else if (dataType.includes('multilang_text')) {
                        value = fValue[language];
                    } else if (dataType.includes('jsonb')) {
                        value = JSON.stringify(fValue);
                    } else {
                        value = typeof fValue === 'object' ? fValue[filterValue.column] : fValue;
                    }
                }

                filterValue.value = convertUIFilterValue(
                    value,
                    filterValue.column,
                    language,
                    filterValue.operator,
                    dataType
                );

                filter.push({ ...filterValue, field: complexFilter.field });
            }
        }
    }
    return filter;
};
