import { Modal, Table } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { metaStore } from 'utils/store/MetaStore';

interface ViewDisplayPreferencesModalProps {
    open: boolean;
    onCancel: () => void;
    onOk: () => void;
}
// TODO: создать отдельный простой компонент таблицы (вынести из SmartTablePage) и наследовать от него компонент редактируемой таблички
export const ViewDisplayPreferencesModal = observer<ViewDisplayPreferencesModalProps>(
    ({ open, onCancel, onOk }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        const dpModalTitle = useMemo(() => {
            const meta = toJS(metaStore.meta.get('SettingsUserViewDisplayPreferences')?.info);

            if (meta) {
                return meta.PluralName?.[language] || meta.PluralName?.en;
            }

            return '';
        }, [language]);

        const dataSource = useMemo(() => {
            const dataSource = [
                ...toJS(
                    metaStore.meta.get('SettingsUserViewDisplayPreferences')?.select?.objects || []
                )
            ];
            console.log('[SmartTablePage] table data:', dataSource);

            return dataSource;
        }, []);

        const columns = useMemo(() => {
            const columns = [];

            columns.push({
                title: 'Title',
                dataIndex: 'Title',
                key: 'title',
                width: 100
            });
            columns.push({
                title: 'As Default',
                dataIndex: 'AsDefault',
                key: 'as_default',
                width: 100
            });
            columns.push({
                title: 'As Global',
                dataIndex: 'AsGlobal',
                key: 'as_global',
                width: 100
            });

            console.log('[SmartTablePage] table columns:', columns);

            return columns;
        }, []);

        return (
            <Modal open={open} centered title={dpModalTitle} onCancel={onCancel} onOk={onOk}>
                <Table dataSource={dataSource} columns={columns} />
            </Modal>
        );
    }
);
