import { IHookStateSetAction } from 'react-use/lib/misc/hookState';
import { metaStore } from 'utils/store/MetaStore';
import { useEffect, useState } from 'react';
import { DctLanguage, InfoUser } from 'modules/services/backend-api/generated_models';
import { Meta } from 'modules/services/backend-api/generated_info';
import { toJS } from 'mobx';
import { TypeAuth, UseAuthUser, User } from './types';

export const useAuthUser = (): UseAuthUser => {
    const [user, setUser] = useState<User['data'] | null>(null);
    const [loading, setLoading] = useState(true);
    const [isPowerUser, setIsPowerUser] = useState(false);
    const [provider, setProvider] = useState<TypeAuth | null>(null);

    useEffect(() => {
        const initAuthUser = async () => {
            setLoading(true);
            try {
                // Получаем пользователя и провайдера из metaStore.api.client

                const { data: clientUser, error } = await metaStore?.api?.client?.getUser();
                if (error) throw error;

                setUser(clientUser);
                const authProvider = metaStore?.api?.client?.authProvider;
                setProvider(authProvider);

                // Если провайдер `supabase`, проверяем роль "Admin"
                if (clientUser?.id) {
                    const isAdmin = await isRoleExists(clientUser.id, 'Admin');
                    setIsPowerUser(isAdmin);
                }
            } catch (error) {
                // console.error('Ошибка при получении пользователя:', error);
            } finally {
                setLoading(false);
            }
        };

        initAuthUser();
    }, [metaStore?.api?.client?.authProvider]); // перезапускается при изменении провайдера

    return { user, loading, isPowerUser, provider, setUser };
};

export const useUserData = () => {
    const [data, setData] = useState<InfoUser | null>(null);
    const [info, setInfo] = useState<Meta | null>(null);
    const [loading, setLoading] = useState(false);
    const { user, isPowerUser, loading: loadingSup } = useAuthUser();

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            const info =
                toJS(metaStore.meta.get('InfoUsers')?.info) ??
                (await metaStore.getInfo('InfoUsers'));

            const data =
                toJS(metaStore.meta.get('InfoUsers')?.select) ??
                (await metaStore.makeSelect({
                    meta: 'InfoUsers',
                    filters: `Id=eq.${user?.id}`
                }));

            if (info) setInfo(info);

            if (data && data?.objects.length > 0) {
                const userData = data?.objects[0] as InfoUser;

                if (!userData.Language) {
                    let defaultLanguage = metaStore.meta.get('all')?.params?.DEFAULT_LANGUAGE;
                    if (!defaultLanguage) {
                        defaultLanguage = await metaStore.getParam({
                            param_name: 'DEFAULT_LANGUAGE'
                        });
                    }
                    userData.Language = defaultLanguage as unknown as DctLanguage;
                }

                setData(userData);
            }

            setLoading(false);
        };

        if (user) init();
    }, [user]);

    return { data: data ? data : user, info, isPowerUser, loading: loading || loadingSup };
};

export const isRoleExists = async (userUuid: string, role = 'Admin') => {
    let userId = '';

    let response = await window.api.select({
        meta: 'InfoUsers',
        filters: `Id=eq.${userUuid}`
    });

    if (response && response.objects.length > 0) {
        userId = response.objects[0].Id;
    }

    if (!userId) return false;

    response = await window.api.select({
        meta: 'InfoUserRoles',
        filters: `Role=eq.${role}&User=eq.${userId}`
    });

    return response && response.objects.length > 0;
};
