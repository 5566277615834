import { MetaField } from 'modules/services/backend-api/generated_info';
import { parseValueType } from 'smart/utils';

import { SupabaseFilterOperator } from '../../../types';

export const convertUIFilterColumnName = (
    metaField: MetaField,
    operator: SupabaseFilterOperator,
    language: string
): string => {
    const { type: dataType } = parseValueType(metaField.ValueType!, language);

    let columnName = metaField.FieldName;

    if (!dataType) return columnName;

    // if (columnName.includes('Name')) {
    //     return `${columnName}->>'${language}'`;
    // }

    if (dataType.includes('daterange')) {
        // Если DateRange и опция меньше - фильтруем как меньше чем первая дата
        if (operator === 'lt' || operator === 'lte') {
            return `${columnName}->>'from_date'`;
        }

        // Если DateRange и опция больше - фильтруем как больше чем вторая дата
        if (operator === 'gt' || operator === 'gte') {
            return `${columnName}->>'to_date'`;
        }
    }

    if (dataType.includes('datetimerange') || dataType.includes('datetimelocalrange')) {
        // Если DateTimeRange и опция меньше - фильтруем как меньше чем первая дата
        if (operator === 'lt' || operator === 'lte') {
            return `${columnName}->>'from_datetime'`;
        }

        // Если DateTimeRange и опция больше - фильтруем как больше чем вторая дата
        if (operator === 'gt' || operator === 'gte') {
            return `${columnName}->>'to_datetime'`;
        }
    }

    if (dataType.includes('timerange')) {
        // Если TimeRange и опция меньше - фильтруем как меньше чем первое время
        if (operator === 'lt' || operator === 'lte') {
            return `${columnName}->>'from_time'`;
        }

        // Если TimeRange и опция больше - фильтруем как больше чем второе время
        if (operator === 'gt' || operator === 'gte') {
            return `${columnName}->>'to_time'`;
        }
    }

    return columnName;
};
