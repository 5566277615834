import { isArray, isBoolean, isPlainObject, isPropertyOf } from 'is-lite/exports';
import { PlainObject, StringOrNull } from '@gilbarbara/types';
import { i18n } from '../../utils/i18n/i18n';
import { MetaField } from '../../modules/services/backend-api/generated_info';
import { UserSpecificFormat } from '../../utils/helpers/dates';
import { IObjectWithId } from '../../utils/store/MetaStore';

interface RenderOptions {
    value: any;
    dataSource: IObjectWithId;
    language: string;
    field?: MetaField;
}

export const renderExcelToPrintCells = ({
    value,
    dataSource,
    language,
    field
}: RenderOptions): string | null | undefined => {
    const fieldName = field?.FieldName ?? '';

    if (field?.ValueType?.includes('coordinate')) {
        return value && value.Latitude && value.Longitude
            ? `${value.Latitude} ${value.Longitude}`
            : '';
    }

    if (
        fieldName.endsWith('Value') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('Value', 'MeasureUnit'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName?.replace('Value', 'MeasureUnit')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('CurrencyValue') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('CurrencyValue', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('CurrencyValue', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('Value') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('Value', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('Value', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('Amount') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('Amount', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('Amount', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('CostValue') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('CostValue', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('CostValue', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (isPlainObject(value)) {
        if (isPropertyOf(value, language)) return value?.[language] as StringOrNull;
        if (isPropertyOf(value, 'Name') && isPlainObject(value.Name))
            return value.Name[language] as StringOrNull;
        if (isPropertyOf(value, 'ShortTitle') && isPlainObject(value.ShortTitle))
            return value.ShortTitle[language] as StringOrNull;
        if (isPropertyOf(value, 'PluralName') && isPlainObject(value.PluralName))
            return value.PluralName[language] as StringOrNull;

        if (isPropertyOf(value, 'Key')) return value.Key as StringOrNull;
        if (isPropertyOf(value, 'Code')) return value.Code as StringOrNull;

        return '';
    }

    if (isArray(value)) {
        const result = [];

        for (const valueElement of value) {
            result.push(
                renderExcelToPrintCells({
                    value: valueElement,
                    language,
                    field: {
                        ...field,
                        ValueType: field?.ValueType?.replace('[]', '')
                    } as MetaField,
                    dataSource
                })
            );
        }

        return result.join(',');
    }

    if (isBoolean(value)) {
        return value ? 'Да' : 'Нет';
    }

    if (
        value?.FromDatetime &&
        value?.ToDatetime &&
        (field?.ValueType?.includes('datetime_range') ||
            field?.ValueType?.includes('datetimerange'))
    ) {
        return `${UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
            value.FromDatetime,
            language
        )} - ${UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
            value.ToDatetime,
            language
        )}`;
    }

    if (value?.FromTime && value?.ToTime && field?.ValueType?.includes('time_range')) {
        return `${value.FromTime} - ${value.ToTime}`;
    }

    if (value && field?.ValueType?.includes('datetime')) {
        return UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(value, language);
    }

    if (value && field?.ValueType?.includes('time')) {
        return UserSpecificFormat.convertFromDbTimeToUiTime(value, language);
    }

    if (value && field?.ValueType?.includes('date')) {
        return UserSpecificFormat.convertFromDbDateToUiDate(value, language);
    }

    return value?.toString();
};
