import { metaStore } from 'utils/store/MetaStore';

// export const getFileUrlForDownload = async (bucket: string, path: string) => {
//     // Скачиваем файл из хранилища
//     const url = await metaStore?.api?.client?.storage.download(bucket, path);
//     // const { data, error } = await supabaseClient.storage.from(bucket).download(path);

//     // Создаем ссылку на скачивание файла
//     return url;
// };
export const getFileUrlForDownload = async (bucket: string, path: string) => {
    // Скачиваем файл из хранилища
    const blob = await metaStore?.api?.client?.storage?.download(bucket, path);
    // const { data, error } = await supabaseClient.storage.from(bucket).download(path);

    // // Если ошибка прилетела, то бросаем исключение
    // if (error) throw error;

    // Создаем ссылку на скачивание файла
    if (blob) {
        return URL.createObjectURL(blob);
    }
    throw new Error('File not found');
};
