import { FullscreenOutlined, InfoCircleOutlined } from '@ant-design/icons';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { Flex, Modal, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rehypeRewrite, { RehypeRewriteOptions } from 'rehype-rewrite';
import { PluggableList } from 'unified';

import { observer } from 'mobx-react-lite';
import { ButtonWithTooltips } from 'ui';
import { i18n } from 'utils/i18n/i18n';
import { modalStores, useStoreNavigate } from 'utils/store';
import { useDebounce, useMedia } from 'react-use';
import { isDefined } from 'is-lite/exports';
import { metaStore } from 'utils/store/MetaStore';
import { useAuthUser } from 'modules/client/useAuthUser';

const handleUpdateChangelog = async (markdown: string) => {
    await metaStore?.api?.client?.storage?.delete('public', 'changelog.md');
    const resultUpload = await metaStore?.api?.client?.storage?.upload(
        'public',
        'changelog.md',
        markdown
    );
    const { data, error } = resultUpload;
    // const { data, error } = await metaStore?.api?.client?.storage.upsertMdFile(
    //     'public',
    //     'changelog.md',
    //     markdown
    // );
    // const { data, error } = await supabaseClient.storage
    // .from('public')
    // .update('changelog.md', markdown, {
    //     contentType: 'application/octet-stream',
    //     upsert: true
    // });

    if (error) {
        notification.error({ message: 'Ошибка при обнавление changelog.md' });
        return;
    }

    if (!error) {
        notification.success({ message: i18n.t('saved') });
    }
};

const rehypePlugins: PluggableList = [
    [
        rehypeRewrite,
        {
            rewrite: (node, index, parent) => {
                if (
                    node.type === 'element' &&
                    node.tagName === 'a' &&
                    parent &&
                    parent.type === 'element' &&
                    /^h(1|2|3|4|5|6)/.test(parent.tagName)
                ) {
                    parent.children = [parent.children[1]];
                }
            }
        } as RehypeRewriteOptions
    ]
];

export const Changelog: FC = observer(() => {
    const { t } = useTranslation();
    const [markdown, setMarkdown] = useState<string>();
    const [innerValue, setInnerValue] = useState<string>();
    const [isEditMode, setIsEditMode] = useState(false);
    const [modalSizes, setModalSizes] = useState({
        fullscreen: false,
        width: 800 as number | string,
        height: 'calc(100vh - 300px)' as number | string
    });
    const { isPowerUser } = useAuthUser();
    // const isSmallTablet = useMedia('(max-width: 620px)');
    const isBigMobile = useMedia('(max-width: 480px)');

    useDebounce(
        () => {
            if (isDefined(innerValue)) {
                setMarkdown(innerValue);
            }
        },
        500,
        [innerValue]
    );

    useEffect(() => {
        document.documentElement.setAttribute('data-color-mode', 'light');
    }, []);

    useEffect(() => {
        const fetchChangelog = async () => {
            const resuldDownload = await metaStore?.api?.client?.storage?.download(
                'public',
                'changelog.md'
            );
            // const { data, error } = await supabaseClient.storage
            // .from('public')
            // .download('changelog.md');

            // if (error) {
            //     notification.error({ message: error.message });
            //     return;
            // }

            const markdown = (await resuldDownload?.text()) || '';
            // debugger;
            setMarkdown(markdown);
            setInnerValue(markdown);
        };

        if (modalStores.changelog.isOpen) {
            fetchChangelog();
        }
    }, [modalStores.changelog.isOpen]);

    const handleSave = () => {
        setIsEditMode(false);
        handleUpdateChangelog(markdown!);
    };

    const handleClose = () => {
        modalStores.changelog.close();
        setIsEditMode(false);
        setMarkdown(undefined);
        setInnerValue(undefined);
    };

    const handleEdit = () => {
        setIsEditMode(true);
    };

    const handleChange = (value: string) => {
        setMarkdown(value);
    };

    const storeNavigate = useStoreNavigate();

    const handleClick = (to: string) => {
        if (to.includes('http')) {
            window.open(to, '_blank');
        } else {
            storeNavigate(to);
        }
    };

    return (
        <>
            <ButtonWithTooltips
                id="help"
                size={isBigMobile ? 'large' : 'middle'}
                tooltipTitle={t('help')}
                type="text"
                className="header__help"
                icon={<InfoCircleOutlined />}
                onClick={() => modalStores.changelog.open()}
            />
            <Modal
                centered
                title={t('changelog')}
                styles={{ body: { overflowY: 'scroll', height: modalSizes.height } }}
                onCancel={handleClose}
                loading={typeof markdown === 'undefined'}
                open={modalStores.changelog.isOpen}
                width={modalSizes.width}
                footer={
                    <Flex gap={5} justify="flex-end">
                        {isEditMode ? (
                            <ButtonWithTooltips id="save" onClick={handleSave}>
                                {t('save')}
                            </ButtonWithTooltips>
                        ) : (
                            <>
                                {isPowerUser && (
                                    <ButtonWithTooltips id="edit" onClick={handleEdit}>
                                        {t('edit')}
                                    </ButtonWithTooltips>
                                )}
                                <ButtonWithTooltips type="default" id="close" onClick={handleClose}>
                                    {t('close')}
                                </ButtonWithTooltips>
                            </>
                        )}
                    </Flex>
                }
            >
                {isEditMode ? (
                    <MarkdownEditor
                        toolbarsMode={[
                            'preview',
                            {
                                icon: <FullscreenOutlined />,
                                name: 'fullscreen',
                                execute: () => {
                                    setModalSizes(({ fullscreen }) => ({
                                        fullscreen: !fullscreen,
                                        width: fullscreen ? 800 : '100vw',
                                        height: fullscreen
                                            ? 'calc(100vh - 300px)'
                                            : 'calc(100vh - 116px)'
                                    }));
                                }
                            }
                        ]}
                        value={markdown}
                        onChange={setInnerValue}
                    />
                ) : (
                    <MarkdownEditor.Markdown
                        components={{
                            a: (props) => {
                                return (
                                    <a
                                        href={props.href}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleClick(props.href);
                                        }}
                                    >
                                        {props.children}
                                    </a>
                                );
                            }
                        }}
                        rehypePlugins={rehypePlugins}
                        source={markdown}
                    />
                )}
            </Modal>
        </>
    );
});
