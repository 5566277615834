import { PaperClipOutlined } from '@ant-design/icons';
import { Badge, Checkbox, Flex, Typography } from 'antd';
import { MultilanguageValueType } from 'components/fields';
import { isArray, isDefined, isObject, isPlainObject } from 'is-lite/exports';

import { MetaField } from 'modules/services/backend-api/generated_info';
import {
    BarcodeField,
    DaysPatternField,
    DynamicTypeField,
    JsonField,
    ProgressBarField
} from 'smart/components';
import { LogsField } from 'smart/components/LogsField/LogsField';
import { formatNumberWithSpaces } from 'smart/components/MoneyField/MoneyField';
import { durationPrettyOutput } from 'smart/components/SmartDurationField/SmartDurationField';
import { LazyIcon, SmartTooltip } from 'smart/ui';
import { downloadFile, JSONSafeParse, parseTemplate, parseValueType } from 'smart/utils';
import { StoreLink } from 'ui';
import { FILE_BUCKET } from 'utils/config/constants';
import { UserSpecificFormat } from 'utils/helpers/dates';
import { toPascalCase } from 'utils/helpers/toPascalCase';
import { LANGUAGES } from 'utils/i18n/i18n';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

type FieldRenderType = {
    (options: {
        data: any;
        language: LANGUAGES;
        metaFieldData?: MetaField[] | MetaField;
        fieldName?: string;
        dataSource?: IObjectWithId;
        isForTable?: boolean;
        isViewAsLink?: boolean;
        rootMeta?: string;
        rootDataSource?: IObjectWithId;
    }): React.ReactNode;
};

export const fieldRender: FieldRenderType = ({
    data,
    language,
    metaFieldData,
    fieldName,
    dataSource,
    isForTable,
    rootMeta,
    rootDataSource,
    isViewAsLink = false
}) => {
    const value = data;

    let field: MetaField | undefined;

    if (Array.isArray(metaFieldData) && fieldName) {
        field = metaFieldData.find(({ FieldName }) => FieldName === fieldName);
    } else if (metaFieldData) {
        field = metaFieldData as MetaField;
    }

    const isValueNulled = !value;
    const isValueObject = typeof value === 'object';

    if (field) {
        // Лог для дебага данных филдов
        // console.log('Field', `"${field?.Name?.[language]}" (${field.FieldName})`, value, field);
        // const rootInfo = toJS(metaStore.meta.get(rootMeta || '')?.info);
        const rootInfo = metaStore.meta.get(rootMeta || '')?.info;

        // TODO: Одинаков для каждой колонки и не меняется от строки (TypeInfo) вынести наверх ^^^^
        const { type, options } = parseValueType(field.ValueType || '', language, {
            root: rootDataSource || dataSource,
            current: dataSource,
            info: rootInfo,
            self: value
        });

        const isInteger = type.startsWith('int');
        const isUtilization =
            type === 'utilization' || (isInteger && field.FieldName.includes('Utilization'));
        const isFile = type === 'file';
        const isIdArray = type === '[]id' || type === '[]object_id';
        const isId = type === 'id' || type === 'object_id';
        const isMultiLanguage = type === 'jsonb_multilang_text';
        const isKey = type === 'key';
        const isCodeArray = type === '[]code';
        const isCode = type === 'code';
        const isMeasureUnit = type === 'decimal' || type === 'money';
        const isType = field.FieldName.endsWith('Value') && type === 'jsonb';
        const isBoolean = type === 'bool' || type === 'boolean';
        const isBooleanArray = type === '[]bool' || type === '[]boolean';
        const isInterval = isBooleanArray && field.FieldName === 'DaysPattern' && !isForTable;
        const isText = type === 'text' || type === 'string';
        const isTableName = type === 'table_name';
        const isLocalDateTimeRange = type === 'local_datetime_range';
        const isDateTimeRange = type === 'datetime_range';
        const isDatesRange = type === 'dates_range';
        const isTimeRangeArray = type === '[]time_range';
        const isTimeRange = type === 'time_range';
        const isDateTime = type === 'datetime';
        const isTime = type === 'time';
        const isMoney = type === 'money';
        const isDuration = type === 'duration' || type === 'seconds';
        const isDate = type === 'date';
        const isReal = type === 'number' || type === 'numeric' || type === 'float';
        const isCoordinate = type === 'coordinate';
        const isColor = type === 'color';
        const isJson = type === 'jsonb';
        const isLogs = type === 'logs';
        const isScript = type === 'script';
        const isTextArray = type === '[]text';
        const isBarcode = type === 'barcode';
        const isIcon = field.FieldName === 'Icon';
        // const isObjectMeta = field.FieldName === 'Object_Ref' || field.FieldName === 'Object_Id';
        // const isObjectMeta = type === 'jsonb' && options?.is_dynamic_ref;
        const isObjectMeta = options?.is_dynamic_ref;
        const isDynamicType = options?.is_dynamic_type && isDefined(dataSource);
        // field.FieldName === 'ObjectType' && !isDefined(options?.ref) && isDefined(dataSource);
        // const isSavedQuery = field.FieldName === 'SavedQuery';

        // if (field.FieldName === 'Request') console.log(field, value);
        // if (field.FieldName === 'ObjectIds') console.log(field, value);

        // if (isSavedQuery) {
        //     return <span>{value?.filter}</span>;
        // }

        if (isDynamicType) {
            return <DynamicTypeField objectMeta={dataSource?.Meta?.Code} value={value} readOnly />;
        }

        if (isIcon) {
            return (
                <span>
                    <LazyIcon icon={value} /> {value ? `(${value})` : ''}
                </span>
            );
        }

        if (isObjectMeta) {
            // console.log(data);
            if (data) {
                const meta = data.Meta?.Code ?? data.Meta_Code;

                const metaRoutesMap = metaStore.meta.get('all')?.routesMap;
                const toMetaRoute = metaRoutesMap?.get(meta)?.[0];

                const displayName = data.DisplayName;
                const title =
                    displayName && typeof displayName === 'object'
                        ? displayName[language]
                        : displayName;

                return (
                    <StoreLink
                        style={{ padding: 0 }}
                        to={{
                            pathname: `${
                                toMetaRoute?.path
                                    ? toMetaRoute.path.split('?')[0]
                                    : `/other/${meta}`
                            }/${data.Id}`,
                            search: ''
                        }}
                    >
                        {title}
                    </StoreLink>
                );
            }

            return data;
        }

        if (isBarcode) {
            let value = data;

            if (options?.value) {
                value = JSONSafeParse(options.value);
            }

            return <BarcodeField value={value} readOnly />;
        }

        if (isUtilization) {
            return (
                <div style={{ width: 200, paddingRight: 10 }}>
                    <ProgressBarField value={Number(value)} />
                </div>
            );
        }

        if (isLogs) {
            return (
                <LogsField
                    requestId={value || ''}
                    btnSize={isForTable ? 'small' : 'middle'}
                    hardDuration={dataSource?.DurationSec}
                />
            );
        }

        if (isFile) {
            let value = data;
            if (isValueNulled) value = options?.value;
            if (!isValueNulled && isValueObject) return <></>;

            if (typeof value === 'string') {
                const fileNameField = isArray(metaFieldData)
                    ? metaFieldData.find((field) => field.FieldName.endsWith('FileName'))
                    : undefined;

                const fileName =
                    options?.file_name ||
                    (fileNameField ? dataSource?.[fileNameField.FieldName] : undefined);

                return (
                    <Typography.Link
                        ellipsis
                        style={{ padding: 0 }}
                        onClick={async () => {
                            await downloadFile(options?.bucket || FILE_BUCKET, value, fileName);
                        }}
                    >
                        <PaperClipOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />{' '}
                        {fileName || value}
                    </Typography.Link>
                );
            }
        }

        if (isIdArray) {
            if (isValueNulled) return value;

            // TODO:  JSON.parse это дорогая операция. мы разве не получаем от бэкенда сразу данные в нужном формате?
            let v = isValueObject ? value : JSON.parse(value);

            if (!isObject(v)) return <SmartTooltip>{value}</SmartTooltip>;
            if (!isArray(v)) v = [v];

            const Wrapper = ({ children }: React.PropsWithChildren) => {
                if (isForTable)
                    return (
                        <SmartTooltip style={{ display: 'flex', gap: 5, flexWrap: 'nowrap' }}>
                            {children}
                        </SmartTooltip>
                    );

                return (
                    <Flex gap={5} wrap={false}>
                        {children}
                    </Flex>
                );
            };

            return (
                <Wrapper>
                    {(v as any[]).map((data, index, array) => (
                        <div style={{ display: 'inline-flex' }}>
                            {fieldRender({
                                data,
                                language,
                                metaFieldData: {
                                    ...field,
                                    ValueType: field.ValueType?.replace('[]', '')
                                },
                                fieldName,
                                dataSource,
                                isForTable,
                                rootMeta
                            })}
                            {array.length !== index + 1 ? ',' : null}&nbsp;
                        </div>
                    ))}
                </Wrapper>
            );
        }

        if (isId) {
            let value = data;
            // console.log(value, field, options?.filters, dataSource?.Meta);

            if (isValueNulled || !isValueObject) {
                if (options?.value) {
                    value = JSONSafeParse(options.value);
                } else return value;
            }

            if (!value?.Id && options?.value) return <SmartTooltip>{value}</SmartTooltip>;

            if (options && options.ref) {
                const idForLink = value.Id;

                // ^^^
                const metaInfo = metaStore.meta.get(options?.ref?.meta)?.info;

                // console.log(field.FieldName, metaInfo);

                const uiAllowView = metaInfo?.UiAllowView ?? true;

                const viewFieldName = toPascalCase(metaInfo?.ViewFieldName || '');

                // ^^^
                const refViewTemplate = metaInfo?.RefViewTemplate;
                const refViewValue = parseTemplate(refViewTemplate || '', language, {
                    root: rootDataSource || dataSource,
                    current: dataSource,
                    info: rootInfo,
                    self: value
                    // user:
                });

                const valueByMeta =
                    viewFieldName && value[viewFieldName]
                        ? typeof value[viewFieldName] === 'object'
                            ? value[viewFieldName]?.[language]
                            : value[viewFieldName]
                        : undefined;

                const multilangField: MultilanguageValueType =
                    value.Title || value.ShortTitle || value.Name || value.PluralName;

                const displayValue =
                    refViewValue.replaceAll('undefined', '').replaceAll('null', '') ||
                    valueByMeta ||
                    multilangField?.[language] ||
                    value.Code ||
                    value.Key ||
                    value.Id;

                // const metaRoutes = metaStore.meta.get('all')?.routes;
                const metaRoutesMap = metaStore.meta.get('all')?.routesMap;

                if (metaRoutesMap) {
                    const toMetaRoute = metaRoutesMap?.get(options?.ref?.meta)?.[0];
                    // ^^^
                    // const toMetaRoute = metaRoutes.find(
                    //     (route) => route.meta === options.ref?.meta
                    // );

                    return toMetaRoute && !field.IsLinkHidden && uiAllowView ? (
                        <StoreLink
                            style={{ padding: 0 }}
                            to={{
                                pathname: `${toMetaRoute?.path?.split('?')[0]}/${idForLink}`,
                                search: ''
                            }}
                            state={{ data: value }}
                        >
                            <SmartTooltip>{displayValue}</SmartTooltip>
                        </StoreLink>
                    ) : (
                        <SmartTooltip>{displayValue}</SmartTooltip>
                    );
                }

                return <SmartTooltip>{displayValue}</SmartTooltip>;
            }

            return <SmartTooltip>{value?.Id}</SmartTooltip>;
        }

        if (isMultiLanguage) {
            const renderValue = !isValueNulled && isValueObject ? value[language] : value;

            return <SmartTooltip>{renderValue}</SmartTooltip>;
        }

        if (isKey) {
            if (isValueNulled || !isValueObject) {
                // const metaInfo = metaStore.meta.get(field.Meta_Code)?.info;

                // if (metaInfo?.Type.ViewFieldName === field.FieldName) {
                if (isViewAsLink) {
                    const metaInfo = metaStore.meta.get(field.Meta_Code || '')?.info;
                    const uiAllowView = metaInfo?.UiAllowView ?? true;

                    // const metaRoutes = metaStore.meta.get('all')?.routes;
                    // const toMetaRoute = metaRoutes?.find((route) => route.meta === field.Meta_Code);

                    const metaRoutesMap = metaStore.meta.get('all')?.routesMap;
                    const toMetaRoute = metaRoutesMap?.get(field.Meta_Code ?? 'empty')?.[0];

                    return toMetaRoute && uiAllowView ? (
                        <StoreLink
                            style={{ padding: 0 }}
                            to={{
                                pathname: `${toMetaRoute?.path?.split('?')[0]}/${dataSource?.Id}`,
                                search: ''
                            }}
                            state={{ data: value }}
                        >
                            <SmartTooltip>{value}</SmartTooltip>
                        </StoreLink>
                    ) : (
                        <SmartTooltip>{value}</SmartTooltip>
                    );
                }

                // }

                return <SmartTooltip>{value}</SmartTooltip>;
            }

            const displayValue = value.Key;

            if (!field.IsLinkHidden && options?.ref) {
                const idForLink = value.Id;

                const metaInfo = metaStore.meta.get(options?.ref?.meta)?.info;
                const uiAllowView = metaInfo?.UiAllowView ?? true;

                const metaRoutesMap = metaStore.meta.get('all')?.routesMap;
                const toMetaRoute = metaRoutesMap?.get(options?.ref?.meta)?.[0];

                return toMetaRoute && uiAllowView ? (
                    <StoreLink
                        style={{ padding: 0 }}
                        to={{
                            pathname: `${toMetaRoute?.path?.split('?')[0]}/${idForLink}`,
                            search: ''
                        }}
                        state={{ data: value }}
                    >
                        <SmartTooltip>{displayValue}</SmartTooltip>
                    </StoreLink>
                ) : (
                    <SmartTooltip>{displayValue}</SmartTooltip>
                );
            }

            return <SmartTooltip>{displayValue}</SmartTooltip>;
        }

        if (isCodeArray) {
            if (isValueNulled) return value;

            // TODO: JSON.parse это - дорогая операция. мы не можем обойтись без нее? почему не подохдит результат возвращаемый из бкенда as-is?
            let v = isValueObject ? value : JSON.parse(value);

            if (!isObject(v)) return <SmartTooltip>{value}</SmartTooltip>;
            if (!isArray(v)) v = [v];

            const Wrapper = ({ children }: React.PropsWithChildren) => {
                if (isForTable)
                    return (
                        <SmartTooltip style={{ display: 'flex', gap: 5, flexWrap: 'nowrap' }}>
                            {children}
                        </SmartTooltip>
                    );

                return (
                    <Flex gap={5} wrap={false}>
                        {children}
                    </Flex>
                );
            };

            return (
                <Wrapper>
                    {(v as any[]).map((data, index, array) => (
                        <div style={{ display: 'inline-flex' }}>
                            {fieldRender({
                                data,
                                language,
                                metaFieldData: {
                                    ...field,
                                    ValueType: field.ValueType?.replace('[]', '')
                                },
                                fieldName,
                                dataSource,
                                isForTable,
                                rootMeta
                            })}
                            {array.length !== index + 1 ? ',' : null}&nbsp;
                        </div>
                    ))}
                </Wrapper>
            );
        }

        if (isCode) {
            let value = data;

            if (isValueNulled || !isValueObject) {
                if (options?.value) {
                    value = JSONSafeParse(options?.value);
                } else return value;
            }

            if (!value?.Id && options?.value) return <SmartTooltip>{value}</SmartTooltip>;
            if (!value?.Id) return null;

            const metaInfo = metaStore.meta.get(options?.ref?.meta || '')?.info;
            const uiAllowView = metaInfo?.UiAllowView ?? true;
            const viewFieldName = toPascalCase(metaInfo?.ViewFieldName || '');
            const refViewTemplate = metaInfo?.RefViewTemplate;
            const refViewValue = parseTemplate(refViewTemplate || '', language, {
                root: rootDataSource || dataSource,
                current: dataSource,
                info: rootInfo,
                self: value
            });

            const valueByMeta =
                viewFieldName && value[viewFieldName]
                    ? typeof value[viewFieldName] === 'object'
                        ? value[viewFieldName]?.[language]
                        : value[viewFieldName]
                    : undefined;

            const multilangField: MultilanguageValueType = value.Name || value.PluralName;

            const displayValue =
                refViewValue.replaceAll('undefined', '') ||
                valueByMeta ||
                multilangField?.[language] ||
                value.Code ||
                value.Id;

            const codeColor = value?.Color || '#b4b4b4';

            const isStatusField = field.FieldName.includes('Status');

            if (!field.IsLinkHidden && options?.ref) {
                const idForLink = value.Id;
                const isStatusField = options.ref.meta.includes('Status');

                // const metaRoutes = metaStore.meta.get('all')?.routes;
                // const toMetaRoute = metaRoutes?.find((route) => route.meta === options.ref?.meta);
                const metaRoutesMap = metaStore.meta.get('all')?.routesMap;
                const toMetaRoute = metaRoutesMap?.get(options?.ref?.meta)?.[0];

                if (toMetaRoute && uiAllowView) {
                    const storeProps = {
                        style: { padding: 0 },
                        to: {
                            pathname: `${toMetaRoute?.path?.split('?')[0]}/${idForLink}`,
                            search: ''
                        },
                        state: { data: value }
                    };

                    if (isStatusField && codeColor) {
                        return (
                            <StoreLink {...storeProps}>
                                <Flex style={{ width: '100%' }} gap={5}>
                                    <Badge color={codeColor} />
                                    <SmartTooltip
                                        style={{ width: 'calc(100% - 11px)' }}
                                        title={displayValue}
                                    >
                                        {displayValue}
                                    </SmartTooltip>
                                </Flex>
                            </StoreLink>
                        );
                    }

                    return (
                        <StoreLink {...storeProps}>
                            <SmartTooltip>{displayValue}</SmartTooltip>
                        </StoreLink>
                    );
                }
            }

            if (isStatusField && codeColor) {
                return (
                    <Flex style={{ width: '100%' }} gap={5}>
                        <Badge color={codeColor} />
                        <SmartTooltip style={{ width: 'calc(100% - 11px)' }} title={displayValue}>
                            {displayValue}
                        </SmartTooltip>
                    </Flex>
                );
            }

            return <SmartTooltip>{displayValue}</SmartTooltip>;
        }

        if (isMeasureUnit) {
            let selectAfter;

            if (Array.isArray(metaFieldData)) {
                const measureUnitField = metaFieldData.find(
                    (metaField) =>
                        // composite measure value
                        metaField.FieldName ===
                            `${field.FieldName.split('Value').join('')}MeasureUnit` ||
                        // composite curreny value
                        metaField.FieldName ===
                            `${field.FieldName.split('CurrencyValue').join('')}Currency` ||
                        metaField.FieldName ===
                            `${field.FieldName.split('Amount').join('')}Currency` ||
                        metaField.FieldName ===
                            `${field.FieldName.split('Value').join('')}Currency` ||
                        metaField.FieldName ===
                            `${field.FieldName.split('CostValue').join('')}Currency`
                );

                if (measureUnitField && dataSource) {
                    const measureUnitValue = dataSource[measureUnitField?.FieldName];
                    const { options } = parseValueType(measureUnitField.ValueType || '', language);

                    if (options?.ref) {
                        selectAfter = measureUnitValue;
                    }
                }
            }

            const displayValue = Number(selectAfter ? value || '0' : value || '');

            const multilangUnitField =
                selectAfter?.Title || selectAfter?.ShortTitle || selectAfter?.Name;

            const displayUnit = selectAfter
                ? multilangUnitField
                    ? multilangUnitField[language]
                    : selectAfter.Code || ''
                : '';

            // TODO: вынести в компонент

            if (type === 'money') {
                return (
                    <SmartTooltip>
                        {formatNumberWithSpaces(displayValue)} {displayUnit || ''}
                    </SmartTooltip>
                );
            }
            return (
                <SmartTooltip>
                    {displayValue} {displayUnit || ''}
                </SmartTooltip>
            );
        }

        if (isType) {
            if (Array.isArray(metaFieldData)) {
                const valueTypeField = metaFieldData.find(
                    (metaField) =>
                        metaField.FieldName.includes('ValueType') ||
                        // metaField.FieldName.includes(`${field.FieldName.replace('Value', '')}`) ||
                        metaField.FieldName === 'Parameter' ||
                        metaField.FieldName === 'Property'
                );

                if (dataSource && valueTypeField) {
                    const selectValue = dataSource[valueTypeField?.FieldName];
                    const isTypeStructString = typeof selectValue === 'string';

                    const valueType = isTypeStructString ? selectValue : selectValue?.ValueType;

                    const typeName = isTypeStructString
                        ? valueType
                        : selectValue?.Name?.[language] ?? selectValue?.Code;

                    const data = value; // && typeof value === 'string' ? JSON.parse(value) : value;

                    return (
                        <Flex vertical gap={5} style={{ width: '100%' }}>
                            {valueType ? (
                                <span style={{ display: 'flex' }}>
                                    [<SmartTooltip>{typeName}</SmartTooltip>]
                                </span>
                            ) : null}

                            {fieldRender({
                                data,
                                language,
                                metaFieldData: {
                                    ...field,
                                    ValueType: valueType
                                },
                                rootMeta
                            })}
                        </Flex>
                    );
                }
            }
        }

        if (isInterval) {
            let selectAfter;
            if (Array.isArray(metaFieldData)) {
                const intervalField = metaFieldData.find(
                    (metaField) => metaField.FieldName === 'Interval'
                );

                if (dataSource && intervalField) {
                    const intervalValue = dataSource[intervalField?.FieldName] as any;
                    const { options } = parseValueType(intervalField.ValueType || '', language);

                    const multilangIntervalField =
                        intervalValue?.Title || intervalValue?.ShortTitle || intervalValue?.Name;

                    const displayIntervalValue = intervalValue
                        ? multilangIntervalField
                            ? multilangIntervalField[language]
                            : intervalValue.Code || ''
                        : '';

                    if (options && options.ref) {
                        selectAfter = (
                            <Flex style={{ paddingLeft: '7px' }} align="center" gap={10}>
                                {displayIntervalValue}
                            </Flex>
                        );
                    }
                }
            }

            return (
                <Flex vertical>
                    {selectAfter}
                    <DaysPatternField
                        dataSource={dataSource}
                        onChange={() => {}}
                        mode={'view'}
                        value={value}
                        centered={false}
                    />
                </Flex>
            );
        }

        if (isBooleanArray) {
            if (value && Array.isArray(value)) {
                return (
                    <Flex gap={5}>
                        {value.map((v) =>
                            v ? (
                                <Checkbox disabled defaultChecked={true}></Checkbox>
                            ) : (
                                <Checkbox disabled defaultChecked={false}></Checkbox>
                            )
                        )}
                    </Flex>
                );
            }

            if (value && typeof value === 'boolean') {
                return <Checkbox disabled defaultChecked={true}></Checkbox>;
            }

            return '';
        }

        if (isBoolean) {
            const defaultValue = options?.default === 'true';

            let value = data;
            if (options?.value) value = options?.value;

            return <Checkbox disabled checked={value} defaultChecked={defaultValue} />;
        }

        if (isTextArray) {
            let value = data;
            if (options?.value) value = options?.value;

            if (isArray(value)) {
                return (
                    <SmartTooltip>
                        {value
                            .map((item) => {
                                if (item && isPlainObject(item))
                                    return (
                                        item.ShortTitle?.[language] ??
                                        item.Code ??
                                        item.Key ??
                                        item.Id
                                    );
                                return item;
                            })
                            .join(', ')}
                    </SmartTooltip>
                );
            }

            if (value && isPlainObject(value))
                return (
                    <SmartTooltip>
                        {value.ShortTitle?.[language] ?? value.Code ?? value.Key ?? value.Id}
                    </SmartTooltip>
                );
            return <SmartTooltip>{value}</SmartTooltip>;
        }

        if (isText || isTableName) {
            let value = data;
            if (options?.value) value = options?.value;

            if (!!value && typeof value === 'object')
                return (
                    <SmartTooltip lineClamp={field.Options?.RowsCount}>
                        {value.ShortTitle ? value.ShortTitle[language] : value.Code || value.Id}
                    </SmartTooltip>
                );
            return <SmartTooltip lineClamp={field.Options?.RowsCount}>{value}</SmartTooltip>;
        }

        if (isLocalDateTimeRange) {
            let value = data;
            if (options?.value) value = options?.value;

            if (value && value.FromDatetime && value.ToDatetime) {
                return (
                    <SmartTooltip>
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.FromDatetime,
                            language
                        )}
                        {' - '}
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.ToDatetime,
                            language
                        )}
                    </SmartTooltip>
                );
            }
            return '';
        }

        if (isDateTimeRange) {
            let value = data;
            if (options?.value) value = options?.value;

            if (value && value.FromDatetime && value.ToDatetime) {
                return (
                    <SmartTooltip>
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.FromDatetime,
                            language
                        )}
                        {' - '}
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.ToDatetime,
                            language
                        )}
                    </SmartTooltip>
                );
            }
            return '';
        }

        if (isDatesRange) {
            let value = data;
            if (options?.value) value = options?.value;

            if (value && value.FromDate && value.ToDate) {
                return (
                    <SmartTooltip>
                        {UserSpecificFormat.convertFromDbDateToUiDate(value.FromDate)}
                        {' - '}
                        {UserSpecificFormat.convertFromDbDateToUiDate(value.ToDate)}
                    </SmartTooltip>
                );
            }
            return '';
        }

        if (isTimeRangeArray) {
            let value = data;
            if (options?.value) value = options?.value;

            if (value && isArray(value)) {
                return (
                    <SmartTooltip>
                        {value.map((v) => {
                            const value = `${v.FromTime} - ${v.ToTime}`;
                            return <span key={value}>{value}, </span>;
                        })}
                    </SmartTooltip>
                );
            }

            return '';
        }

        if (isTimeRange) {
            let value = data;
            if (options?.value) value = options?.value;

            if (value && value.FromTime && value.ToTime) {
                return (
                    <SmartTooltip>
                        {value.FromTime}
                        {' - '}
                        {value.ToTime}
                    </SmartTooltip>
                );
            }

            return '';
        }

        if (isDateTime) {
            let value = data;
            if (options?.value) value = options?.value;

            return (
                <SmartTooltip>
                    {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(value, language)}
                </SmartTooltip>
            );
        }

        if (isTime) {
            let value = data;
            if (options?.value) value = options?.value;

            return (
                <SmartTooltip>
                    {UserSpecificFormat.convertFromDbTimeToUiTime(value, language)}
                </SmartTooltip>
            );
        }

        if (isDuration) {
            let value = data;
            if (options?.value) value = options?.value;

            return value ? <SmartTooltip>{durationPrettyOutput(value)}</SmartTooltip> : '';
        }

        if (isDate) {
            let value = data;
            if (options?.value) value = options?.value;

            return (
                <SmartTooltip>
                    {UserSpecificFormat.convertFromDbDateToUiDate(value, language)}
                </SmartTooltip>
            );
        }

        if (isInteger || isReal) {
            let value = data;
            if (options?.value) value = options?.value;

            return value === null || value === undefined ? '' : `${value}`;
        }

        if (isMoney) {
            let value = data;
            if (options?.value) value = options?.value;

            return value === null || value === undefined ? '' : `${formatNumberWithSpaces(value)}`;
        }

        if (isCoordinate) {
            let value = data;
            if (options?.value) value = options?.value;

            return value && value.Latitude && value.Longitude ? (
                <SmartTooltip>
                    {value.Latitude} {value.Longitude}
                </SmartTooltip>
            ) : (
                ''
            );
        }

        if (isColor) {
            let value = data;
            if (options?.value) value = options?.value;

            return (
                <div
                    style={{
                        backgroundColor: value,
                        padding: '2px 5px',
                        width: 'max-content',
                        textAlign: 'center'
                    }}
                >
                    <span style={{ backgroundClip: 'text' }}>{value}</span>
                </div>
            );
        }

        if (isScript) {
            const data = isDefined(value) ? String(value) : value;

            if (isForTable) {
                return (
                    <Typography.Text
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '300px',
                            padding: 0
                        }}
                    >
                        {data}
                    </Typography.Text>
                );
            }

            return (
                <JsonField
                    jsonValue={data}
                    displayMode={options?.display}
                    onChange={() => {}}
                    scriptLanguage={options?.language || 'text'}
                    hideEditor={options?.hide_code ?? field.Options?.HideSourceCode}
                    readOnly
                />
            );
        }

        if (isJson) {
            const data = !isValueNulled && isValueObject ? JSON.stringify(value) : value;

            if (options?.json_type) {
                return fieldRender({
                    data,
                    language,
                    metaFieldData: {
                        ...field,
                        ValueType: `${options.json_type};ref:${options.ref?.meta || ''}.${
                            options.ref?.fieldName || ''
                        }${options.filters ? `;filters:${options.filters}` : ''}${
                            options.group ? `;group:${options.group}` : ''
                        }${options.display ? `;display:${options.display}` : ''}`
                    },
                    fieldName,
                    dataSource,
                    isForTable
                });
            }

            if (isForTable) {
                return (
                    <SmartTooltip open={false}>
                        {typeof data === 'string' ? data : JSON.stringify(data)}
                    </SmartTooltip>
                );
            }

            return (
                // TODO: зачем мы сначала JSON перевели в строку выше. а теперь парсим обратно?
                <JsonField
                    jsonValue={typeof data === 'string' ? JSONSafeParse(data) : data}
                    displayMode={options?.display}
                    onChange={() => {}}
                    scriptLanguage="json"
                    readOnly
                />
            );
        }

        if (!isValueNulled && isValueObject) return '';

        if (options && options?.default) {
            let value = data;
            if (options?.value) value = options?.value;

            return <SmartTooltip>{value || options.default}</SmartTooltip>;
        }

        if (options?.value) return <SmartTooltip>{options?.value}</SmartTooltip>;
    }

    return <SmartTooltip>{value}</SmartTooltip>;
};

// export const fieldRender = memoizeOne(render, (...args) => {
//     const res = isEqual(...args);
//
//     console.log(...args, res);
//
//     return res;
// });
