import merge from 'deepmerge';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import {
    GetParamRequestWithDefault,
    ProcessNotificationFn,
    ServerApi
} from 'modules/services/backend-api/api';
import {
    AcquireLockRequest,
    AssociationRequest,
    ExtendLockRequest,
    GetParamResponse,
    GetRequest,
    GetResponse,
    LogsRequest,
    LogsResponse,
    ReleaseLockRequest,
    Response,
    RunRequest,
    SaveRequest,
    SelectRequest
} from 'modules/services/backend-api/generated_api';
import { removeUndefinedFields } from 'smart/utils';
import { CODE } from 'modules/services/backend-api/generated_types';
import { PlainObject } from '@gilbarbara/types';
import { makePersistable } from 'mobx-persist-store';
import { isArray } from 'is-lite/exports';

// import * as merge from 'deepmerge';

export interface IObjectWithId {
    Id: string;
    Parent?: IObjectWithId;
    [key: string]: any;
}

interface IGetResponse {
    object: IObjectWithId;
}

interface ISelectResponse {
    objects: IObjectWithId[];
    filters: string;
}

const initialStore = new Map<
    string,
    Omit<
        Response,
        | 'request_id'
        | 'session_id'
        | 'status_code'
        | 'load_meta'
        | 'load_object'
        | 'get'
        | 'get_param'
        | 'select'
        | 'logs'
    > & {
        get?: { [key: string | number]: IGetResponse };
        params?: { [param_name: string]: GetParamResponse };
        logs?: { [request_id: string]: LogsResponse };
        select?: ISelectResponse;
        routesMap?: Map<string, Response['routes']>;
        [key: string]: unknown | unknown[];
    }
>();

const sortByChildIndexRule = (a: PlainObject<any>, b: PlainObject<any>) => {
    // Если ChildIndex отсутствует, приравниваем его к 0
    const indexA = a.ChildIndex ?? 0;
    const indexB = b.ChildIndex ?? 0;

    return indexA - indexB;
};

export class MetaStore {
    public api;

    meta = initialStore;

    constructor() {
        console.log('[Meta Store] created');
        makeAutoObservable(this);

        this.api = new ServerApi(window.env.BACKEND_API_URL);

        this.getParam({ param_name: 'DONT_RETURN_LOG_MESSAGES' }).then((dontReturnLogMessages) => {
            if (dontReturnLogMessages) {
                this.api.channel?.setReturnLogMessages(false);
            }
        });

        window.api = this.api;
    }

    clear = () => {
        console.log('[Meta Store] clear');
        this.meta.clear();
    };

    delete = (meta: string, category: string) => {
        const prevMeta = this.meta.get(meta);
        this.meta.set(meta, { ...prevMeta, [category]: undefined });
    };

    add = (object: IObjectWithId, meta: string, category: string) => {
        const prevMeta = this.meta.get(meta);
        const metaCategoryData = prevMeta?.[category];
        if (!metaCategoryData) return;

        console.log('[Meta Store] old meta, before adding:', metaCategoryData);

        if (Array.isArray(metaCategoryData)) {
            const notChangedData = metaCategoryData.filter((item) => item?.Id !== object?.Id);

            this.meta.set(meta, {
                ...prevMeta,
                [category]: [...notChangedData, object]
            });
        } else if (Object.hasOwn(metaCategoryData, 'object')) {
            this.meta.set(meta, {
                ...prevMeta,
                [category]: { object: { ...metaCategoryData?.object, ...object } }
            });
        } else if (Object.hasOwn(metaCategoryData, 'objects')) {
            const notChangedData = metaCategoryData?.objects?.filter(
                (item) => item?.Id !== object?.Id
            );

            this.meta.set(meta, {
                ...prevMeta,
                [category]: {
                    ...metaCategoryData,
                    objects: [...notChangedData, object]
                }
            });
        } else {
            this.meta.set(meta, {
                ...prevMeta,
                [category]: { ...metaCategoryData, ...object }
            });
        }

        // console.log('[Meta Store] new meta, after adding:', toJS(this.meta.get(meta)?.[category]));
    };

    getInfo = async (meta: string, currentPath?: string) => {
        const res = await this.api.info({ meta });

        const prevMeta = this.meta.get(meta);
        const all = this.meta.get('all');
        const metaRoutes = all?.routes;

        const hasRoutes = !!(metaRoutes && metaRoutes?.length);
        const cachedRoutes = hasRoutes ? metaRoutes : await this.getRoutes();

        const layout = cachedRoutes?.find(
            (route) => route.meta === meta && route.layout && currentPath === route.path
        )?.layout;

        let layoutInfo = toJS(layout?.Info?.Info);
        const layoutInfoByPlatform = toJS(
            window.innerWidth > 480 ? layout?.Info?.InfoDesktop : layout?.Info?.InfoMobile
        );

        if (!layout?.Info?.Info && layoutInfoByPlatform) layoutInfo = layoutInfoByPlatform;

        // console.log(meta, res, layoutInfo);

        if (res) {
            let mergedRes = res;

            if (layoutInfo) {
                const combineMerge = (
                    target: any[],
                    source: any[],
                    options: merge.ArrayMergeOptions
                ) => {
                    const destination = target.slice();

                    source.forEach((item, index) => {
                        const destIndex = destination.findIndex((destItem) => {
                            if (destItem.FieldName) return destItem.FieldName === item.FieldName;
                            if (destItem.Code) return destItem.Code === item.Code;
                            return destItem.Id === item.Id;
                        });
                        if (destIndex !== -1) {
                            destination[destIndex] = merge(destination[destIndex], item, options);
                        } else {
                            destination.push(item);
                        }
                    });

                    if (destination.length === 0 && source.length !== 0) return source;

                    return destination;
                };

                if (layoutInfoByPlatform && layout?.Info?.Info) {
                    layoutInfo = merge(layoutInfo, layoutInfoByPlatform, {
                        arrayMerge: combineMerge
                    });

                    // console.log(layoutInfo);
                }

                mergedRes = merge(res, removeUndefinedFields(layoutInfo), {
                    arrayMerge: combineMerge
                });

                mergedRes = { ...mergedRes, Fields: mergedRes.Fields.sort(sortByChildIndexRule) };
                // console.log('MERGED', mergedRes);
                // const mergedInfoFields = _.merge(mergedInfo.Fields, layoutInfo.Fields);
                // console.log(mergedInfoFields);
            }

            runInAction(() => {
                this.meta.set(meta, { ...prevMeta, info: { ...prevMeta?.info, ...mergedRes } });
            });

            // console.log({ ...prevMeta?.info, ...mergedRes });
        }

        return res;
    };

    makeRun = async (options: RunRequest) => {
        const res = await this.api.run(options);
        const metaCode = options.meta;

        const prevMeta = this.meta.get(metaCode);

        if (res) {
            this.meta.set(metaCode, { ...prevMeta, run: { ...prevMeta?.run, ...res.run! } });
        }

        return res;
    };

    getRoutes = async () => {
        const res = await this.api.routes();
        const prevMeta = this.meta.get('all');

        if (res && isArray(res)) {
            const routesMap = new Map<string, NonNullable<Response['routes']>>();

            for (const re of res) {
                routesMap.set(
                    re.meta ?? 'empty',
                    res.filter((route) => route.meta === re.meta)
                );
            }

            runInAction(() => {
                this.meta.set('all', { ...prevMeta, routesMap, routes: res });
            });
        }

        return res;
    };

    getMenu = async () => {
        const res = await this.api.menu();

        const prevMeta = this.meta.get('all');

        if (res) {
            runInAction(() => {
                this.meta.set('all', { ...prevMeta, menu: { ...prevMeta?.menu, ...res } });
            });
        }

        return res;
    };

    getAssociation = async (options: AssociationRequest) => {
        const res = await this.api.association(options);

        const prevMeta = this.meta.get('all');

        if (res) {
            this.meta.set('all', {
                ...prevMeta,
                association: { ...prevMeta?.association, ...res }
            });
        }

        return res;
    };

    makeSelect = async (options: { meta: CODE } & Partial<Omit<SelectRequest, 'meta'>>) => {
        const res = await (this.api.select(options) as Promise<ISelectResponse>);
        const metaCode = options.meta;

        const prevMeta = this.meta.get(metaCode);

        if (res) {
            runInAction(() => {
                this.meta.set(metaCode, {
                    ...prevMeta,
                    select: { ...prevMeta?.select, ...res, filters: options.filters }
                });
            });
        }

        return res;
    };

    makeGet = async (options: GetRequest): Promise<GetResponse> => {
        const res = await this.api.get(options);
        const metaCode = options.meta;

        const prevMeta = this.meta.get(metaCode);

        if (res) {
            runInAction(() => {
                this.meta.set(metaCode, {
                    ...prevMeta,
                    get: { ...prevMeta?.get, [options.id]: res }
                });
            });
        }

        return res;
    };

    acquireLock = async (options: AcquireLockRequest) => {
        const res = await this.api.acquireLock(options);
        const metaCode = options.meta;

        const prevMeta = this.meta.get(metaCode);

        if (res) {
            runInAction(() => {
                this.meta.set(metaCode, {
                    ...prevMeta,
                    acquire_lock: { ...prevMeta?.acquire_lock, ...res }
                });
            });
        }

        return res;
    };

    releaseLock = async (options: ReleaseLockRequest) => {
        const res = await this.api.releaseLock(options);

        return res;
    };

    extendLock = async (options: ExtendLockRequest) => {
        const res = await this.api.extendLock(options);

        return res;
    };

    makeSave = async (options: SaveRequest) => {
        const res = await this.api.save(options);
        const metaCode = options.meta;

        const prevMeta = this.meta.get(metaCode);

        if (res) {
            runInAction(() => {
                this.meta.set(metaCode, {
                    ...prevMeta,
                    save: { ...prevMeta?.save, ...res }
                });
            });
        }

        return res;
    };

    getParam = async (options: GetParamRequestWithDefault) => {
        const res = await this.api.get_param(options);

        const prevMeta = this.meta.get('all');

        if (res) {
            runInAction(() => {
                this.meta.set('all', {
                    ...prevMeta,
                    params: { ...prevMeta?.params, [options.param_name]: res }
                });
            });
        }

        return res;
    };

    getLogs = async (options: LogsRequest) => {
        const res = await this.api.logs(options);

        const prevMeta = this.meta.get('all');

        if (res) {
            runInAction(() => {
                this.meta.set('all', {
                    ...prevMeta,
                    logs: { ...prevMeta?.logs, [options.request_id]: res }
                });
            });
        }

        return res;
    };

    subscribeNotifications = async (process: ProcessNotificationFn) => {
        return this.api.subscribeNotifications(process);
    };

    // getAllInfos = async () => {
    //     const res = await this.api.select({ meta: 'InfoMeta' });
    //
    //     if (res) {
    //         res.objects?.forEach((meta) => {
    //             this.meta.set(meta.Code, {});
    //         });
    //     }
    // };
}

export const metaStore = new MetaStore();
