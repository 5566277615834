import { Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface EmptyMarkerProps {
    noDescription?: boolean;
    noImage?: boolean;
    title?: string | null;
    titleCentered?: boolean;
    size?: 'small' | 'default';
    style?: React.CSSProperties;
}

export const EmptyMarker = ({
    noDescription = false,
    noImage = false,
    title,
    titleCentered,
    size = 'default',
    style
}: EmptyMarkerProps) => {
    const { t } = useTranslation();

    if (noImage && noDescription) {
        return <></>;
    }

    const noImageLabel = (
        <Typography.Text
            style={{ padding: 0, textAlign: titleCentered ? 'center' : undefined }}
            type="secondary"
        >
            {title || t('no_data')}
        </Typography.Text>
    );

    if (noImage) {
        return noImageLabel;
    }

    return (
        <Empty
            style={{
                marginInline: size === 'default' ? '18px' : '9px',
                marginTop: size === 'default' ? '32px' : '1px',
                marginBottom: size === 'default' ? '32px' : '1px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ...style
            }}
            description={!noDescription ? noImageLabel : ' '}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    );
};
