import { makeAutoObservable } from 'mobx';
import { DctLanguage } from 'modules/services/backend-api/generated_models';
import { WindowENV } from 'utils/i18n/i18n';

export class LanguagesStore {
    private api;

    languages: DctLanguage[] = [];

    current = 'ru';

    constructor() {
        makeAutoObservable(this);
        this.api = window.api;
    }

    fetch = async () => {
        console.debug('[LanguagesStore] fetch');
        if (this.api.isConnectedAndAuthenticated()) {
            const response = await this.api.select({ meta: 'DctLanguages' });

            if (response && response.objects.length > 0) {
                this.languages = response.objects as DctLanguage[];
                if (window.env && (window.env as WindowENV).ENABLE_TECHNICAL_LANGUAGE) {
                    this.languages.push({ Code: 'tech' } as DctLanguage);
                }
            }
        } else {
            this.languages = [
                { Code: 'ru', Name: { ru: 'Русский', en: 'Russian' } } as DctLanguage,
                { Code: 'en', Name: { ru: 'Английский', en: 'English' } } as DctLanguage,
                { Code: 'tech', Name: { ru: 'Технический', en: 'Technical' } } as DctLanguage
            ];
        }
    };

    setCurrent(language: string) {
        this.current = language;
    }
}

export const languagesStore = new LanguagesStore();
