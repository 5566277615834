import { Filter } from 'modules/supabase/utils/supabaseClient';

const withoutAny = (filter: string) => filter;

const buildANDConditionString = (filter: Filter): string => {
    const withAny = filter.field.withAny ?? withoutAny;

    const getFilterText = (filter: Filter): string => {
        let filterOut = withAny(`${filter.column}=${filter.operator}.${filter.value}`);
        const andCondition = filter.ANDCondition;

        if (andCondition) {
            const anotherFilter = getFilterText(andCondition);

            if (anotherFilter) {
                filterOut = `${filterOut}&${anotherFilter}`;
            }
        }

        return filterOut;
    };

    return `${getFilterText(filter)}`;
};

export const buildFilter = (filters: Filter[]) => {
    const applyFilter: string[] = [];

    const copyFilter = [...filters];

    const combinedFilter: {
        columnName: string;
        filter: Filter[];
    }[] = [];

    // Группируем фильтры
    for (let i = 0; i < copyFilter.length; i++) {
        const copyFilterItem = copyFilter[i];
        const fieldName = copyFilterItem.column;
        const findCombinedFilter = combinedFilter.find((filter) => filter.columnName === fieldName);

        if (findCombinedFilter) {
            findCombinedFilter.filter.push(copyFilterItem);
        } else {
            combinedFilter.push({
                columnName: fieldName,
                filter: [copyFilterItem]
            });
        }
    }

    // console.log(filters, combinedFilter);

    for (let i = 0; i < combinedFilter.length; i++) {
        const filter = combinedFilter[i].filter;

        // Если одно значение в фильтре по поле - обычная фильтрация
        if (filter.length === 1) {
            const filterItem = filter[0];

            if (filterItem.ANDCondition) {
                const andFilters = buildANDConditionString(filterItem);

                applyFilter.push(andFilters);
            } else {
                const withAny = filterItem.field.withAny ?? withoutAny;
                const filterString = `${filterItem.column}=${filterItem.operator}.${filterItem.value}`;
                const filterStringWithAnyMaybe = withAny(filterString);

                applyFilter.push(filterStringWithAnyMaybe);
            }
        }

        // Если значений больше одному по полю - ставим условие ИЛИ
        if (filter.length > 1) {
            let orCondition = null;

            for (let j = 0; j < filter.length; j++) {
                const filterItem = filter[j];
                const withAny = filterItem.field.withAny ?? withoutAny;

                if (orCondition) {
                    orCondition = `${orCondition},${
                        filterItem.ANDCondition
                            ? buildANDConditionString(filterItem)
                            : withAny(
                                  `${filterItem.column}=${filterItem.operator}.${filterItem.value}`
                              )
                    }`;
                } else {
                    orCondition = filterItem.ANDCondition
                        ? buildANDConditionString(filterItem)
                        : withAny(
                              `${filterItem.column}=${filterItem.operator}.${filterItem.value}`
                          );
                }
            }

            if (orCondition) {
                applyFilter.push(`or(${orCondition})`);
            }
        }
    }

    return applyFilter;
};
