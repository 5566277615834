import { memo, useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';

import * as beautiful from 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-noconflict/mode-tsx';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-dockerfile';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-gitignore';
import 'ace-builds/src-noconflict/mode-abap';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-pgsql';
import 'ace-builds/src-noconflict/snippets/json';
import 'ace-builds/src-noconflict/snippets/markdown';
import 'ace-builds/src-noconflict/snippets/sql';
import 'ace-builds/src-noconflict/snippets/pgsql';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/snippets/xml';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Button, Flex, Modal, Typography } from 'antd';
import { useBoolean, useMedia, useUpdateEffect } from 'react-use';

import './JsonField.scss';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { JSONSafeParse } from 'smart/utils';
import { isDefined } from 'is-lite/exports';
import { useTranslation } from 'react-i18next';

interface JsonFieldType {
    jsonValue: any | undefined;
    onChange: (newFieldValue: any) => void;
    readOnly: boolean;
    scriptLanguage?: string;
    displayMode?: 'inline' | 'dialog';
    hideEditor?: boolean;
}

const unbeautify = (value: string, scriptLanguage: string) => {
    if (scriptLanguage === 'json') {
        return JSON.stringify(JSONSafeParse(value));
    }

    return value;
};

export const JsonField = memo<JsonFieldType>(
    ({
        jsonValue,
        readOnly,
        onChange,
        hideEditor,
        scriptLanguage = 'text',
        displayMode = 'modal'
    }) => {
        const { t } = useTranslation();
        const mode = scriptLanguage.toLowerCase();

        const isBigMobile = useMedia('(max-width: 480px)');
        const isSmallTablet = useMedia('(max-width: 620px)');

        const editorRef = useRef<AceEditor | null>(null);

        const [openModal, setOpenModal] = useBoolean(false);

        const stringifiedValue = jsonValue
            ? mode === 'json'
                ? JSON.stringify(jsonValue)
                : jsonValue
            : undefined;

        const [value, setValue] = useState(stringifiedValue);

        const [modalValue, setModalValue] = useState(stringifiedValue);

        // console.log(modalValue, value, stringifiedValue, jsonValue);

        useUpdateEffect(() => {
            if (isDefined(value) && mode === 'json') onChange(JSONSafeParse(value as string));
            else onChange(value);
        }, [value]);

        const beautify = () => {
            if (mode === 'json' && editorRef.current?.editor.getSession())
                beautiful.beautify(editorRef.current.editor.getSession());
        };

        useEffect(() => {
            setTimeout(() => {
                if (mode === 'json' && editorRef.current?.editor.getSession() && openModal)
                    beautify();
            }, 1);
        }, [openModal, mode]);

        if (displayMode === 'inline') {
            if (!hideEditor) {
                return (
                    <AceEditor
                        ref={editorRef}
                        mode={mode}
                        value={value}
                        name="json-editor"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true
                        }}
                        onBlur={beautify}
                        fontSize="1em"
                        width="100%"
                        readOnly={readOnly}
                        // Enable live editing for syntax highlighting and error detection
                        onChange={setValue}
                    />
                );
            }
            if (mode === 'html') {
                return (
                    <div
                        style={{
                            // maxHeight: '70vh',
                            width: '100%',
                            overflow: 'auto',
                            padding: 3,
                            border: '1px solid #f0f0f0',
                            borderRadius: 6
                        }}
                    >
                        <iframe
                            style={{ maxHeight: '300px' }}
                            srcDoc={isDefined(modalValue) ? modalValue : stringifiedValue}
                        />
                    </div>
                );
            }

            return null;
        }

        return (
            <>
                <Typography.Link
                    ellipsis
                    className="json_field"
                    style={{
                        color: 'rgba(0,0,0,.88)',
                        padding: 0,
                        marginBottom: 0
                    }}
                    onClick={() => setOpenModal(true)}
                >
                    {stringifiedValue || (
                        <Button
                            block={isBigMobile}
                            style={{ justifyContent: isBigMobile ? 'flex-start' : undefined }}
                            type={isBigMobile ? 'text' : 'default'}
                            size={isBigMobile ? 'small' : 'middle'}
                            disabled={readOnly}
                            icon={<EditOutlined />}
                            onClick={() => setOpenModal(true)}
                        >
                            {mode.toUpperCase()}
                        </Button>
                    )}
                </Typography.Link>
                <Modal
                    open={openModal}
                    okText={t('ok')}
                    cancelText={t('cancel')}
                    onOk={() => {
                        let preValue = modalValue;
                        if (preValue === '') preValue = undefined;
                        else if (preValue === undefined) preValue = stringifiedValue;

                        const value = unbeautify(preValue, mode);

                        beautify();
                        setValue(value);
                        setOpenModal(false);
                    }}
                    onCancel={() => {
                        setOpenModal(false);
                        setModalValue(undefined);
                    }}
                    centered
                    width={mode === 'html' ? '99vw' : '75vw'}
                    height={mode === 'html' ? '90vh' : '75vh'}
                    title={<></>}
                    style={{ padding: 20 }}
                    destroyOnClose
                >
                    <Flex gap={5} align={'center'}>
                        {!hideEditor && (
                            <AceEditor
                                ref={editorRef}
                                mode={mode}
                                value={isDefined(modalValue) ? modalValue : stringifiedValue}
                                onBlur={beautify}
                                name="json-editor"
                                style={{
                                    padding: 5,
                                    border: '1px solid #f0f0f0',
                                    borderRadius: 6,
                                    height: '70vh',
                                    width: mode === 'html' && !isSmallTablet ? '55%' : '100%'
                                }}
                                editorProps={{ $blockScrolling: true }}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: true
                                }}
                                fontSize="1em"
                                width="100%"
                                readOnly={readOnly}
                                // Enable live editing for syntax highlighting and error detection
                                onChange={setModalValue}
                            />
                        )}
                        {mode === 'html' && !isSmallTablet && (
                            <div>
                                <ArrowRightOutlined />
                            </div>
                        )}
                        {mode === 'html' && !isSmallTablet && (
                            <div
                                style={{
                                    maxHeight: '70vh',
                                    width: !hideEditor ? '45%' : '100%',
                                    overflow: 'auto',
                                    padding: 5,
                                    border: '1px solid #f0f0f0',
                                    borderRadius: 6
                                }}
                            >
                                <iframe
                                    style={{ height: '69vh' }}
                                    srcDoc={isDefined(modalValue) ? modalValue : stringifiedValue}
                                />
                            </div>
                        )}
                    </Flex>
                </Modal>
            </>
        );
    }
);
