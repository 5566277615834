import { isEqual } from 'lodash';
import { RegLocationResourceSlot } from '../../../../modules/services/backend-api/generated_models';

function generateHourRange(from: number, to: number): number[] {
    const range: number[] = [];
    for (let i = from; i <= to; i++) {
        range.push(i);
    }
    return range;
}

const memoized_getFreeHoursByDate = () => {
    const cache: {
        freeSlots?: RegLocationResourceSlot[];
        resourceId?: string;
        result?: Map<string, number[]>;
    } = {
        result: undefined,
        freeSlots: undefined,
        resourceId: undefined
    };

    return (
        freeSlots: RegLocationResourceSlot[],
        resourceId: string,
        firstColumnFieldName: string
    ) => {
        if (isEqual(cache.freeSlots, freeSlots) && cache.resourceId === resourceId) {
            console.log('cached');
            return cache.result as Map<string, number[]>;
        }

        // let totalFreeHours = 0;
        const result = new Map<string, number[]>();

        freeSlots.forEach((slot) => {
            const dateString = String(slot.SlotDate);

            if (slot[firstColumnFieldName]?.Id === resourceId) {
                // const currentFrom = dayjs(slot.SlotFromAt ?? '').utc();
                // const currentTo = dayjs(slot.SlotToAt ?? '').utc();
                //
                // const existingSlot = result.get(dateString);
                // if (existingSlot) {
                //     const existingFrom = dayjs(existingSlot[0]).utc();
                //     const existingTo = dayjs(existingSlot[1]).utc();
                //
                //     const newFrom = currentFrom.isBefore(existingFrom) ? currentFrom : existingFrom;
                //     const newTo = currentTo.isAfter(existingTo) ? currentTo : existingTo;
                //
                //     result.set(dateString, [newFrom.toISOString(), newTo.toISOString()]);
                // } else {
                //     result.set(dateString, [slot.SlotFromAt ?? '', slot.SlotToAt ?? '']);
                // }

                // const slotDate = slot.SlotDate;

                // Разбираем временные интервалы начала и конца
                const startHour = new Date(slot.SlotFromAt).getUTCHours();
                let endHour = new Date(slot.SlotToAt).getUTCHours() - 1; // Конечный час не включаем в диапазон
                if (endHour === -1 || endHour === 0) endHour = 23;

                // Если в Map уже есть запись для этой даты
                if (!result.has(dateString)) {
                    result.set(dateString, []);
                }

                // Добавляем часы из диапазона в массив доступных часов
                const availableHours = result.get(dateString);
                const hoursInSlot = generateHourRange(startHour, endHour);

                // Добавляем в Map для данной даты
                result.set(dateString, [...availableHours!, ...hoursInSlot]);
            }
        });

        // // Преобразуем временные диапазоны в массив часов
        // for (const [date, [from, to]] of result.entries()) {
        //     result.set(date, getHoursArray({ from, to }));
        // }

        cache.result = result;
        cache.freeSlots = freeSlots;
        cache.resourceId = resourceId;

        return result;
    };
};

export { memoized_getFreeHoursByDate as getGetFreeHoursByDate };
