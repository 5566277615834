export function decodeJWT(token) {
    const base64Url = token.split('.')[1]; // Вытаскиваем часть полезной нагрузки
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export function decodeObjectGUID(base64) {
    // Декодирование Base64
    const buffer = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));

    // Преобразование в формат GUID
    const hex = [...buffer].map((byte) => byte.toString(16).padStart(2, '0')).join('');

    // Формирование GUID
    const result = `${hex.substr(0, 8)}-${hex.substr(8, 4)}-${hex.substr(12, 4)}-${hex.substr(
        16,
        4
    )}-${hex.substr(20)}`;
    
    return result;
}
