import dayjs from 'dayjs';

import { VALUE_TYPE } from 'modules/services/backend-api/generated_types';
import { SupabaseFilterOperator } from '../../../types';

const convertDatetimeFilter = (dateTimeValue: string, utc: boolean) => {
    const date = dayjs(dateTimeValue).utc(utc);
    return date.format('YYYY-MM-DDTHH:mm:ss').toString();
};

export const convertUIFilterValue = (
    filterValue: unknown,
    columnName: string,
    language: string,
    operator: SupabaseFilterOperator,
    dataType?: VALUE_TYPE | undefined
): unknown => {
    if (!dataType) return filterValue;

    if (operator === 'like') {
        return `%${filterValue}%`;
    }

    // Фильтр по таким полям превращается в больше/меньше конкретной даты/время ИЛИ разбивается на два фильтра, поэтому сюда уже приходит не структура
    if (dataType && (dataType === 'datetimelocalrange' || dataType === 'datetimelocal')) {
        return convertDatetimeFilter(filterValue as string, true);
    }

    if (dataType && (dataType === 'datetimerange' || dataType === 'datetime')) {
        return convertDatetimeFilter(filterValue as string, false);
    }

    if (dataType && dataType === 'array_id') {
        return `{${filterValue}}`;
    }

    if (!filterValue) return undefined;

    return filterValue;
};
