import { PlainObject } from '@gilbarbara/types';

import { MetaField } from 'modules/services/backend-api/generated_info';
import { toPascalCase } from 'utils/helpers/toPascalCase';

export const getExcludeFieldsPattern = (
    self: PlainObject<any>,
    list: PlainObject<any>[],
    options?: {
        isDetail?: boolean;
        isHandlerModal?: boolean;
        replaceFieldNameKey?: string;
        isSnakeCaseValue?: boolean;
        diableCodeSuffix?: boolean;
    }
) => {
    const FieldNameKey = (options?.replaceFieldNameKey || 'FieldName') as keyof MetaField;

    const FieldName = (
        options?.isSnakeCaseValue ? toPascalCase(self[FieldNameKey]) : self[FieldNameKey]
    ).replace('Code', options?.diableCodeSuffix ? '' : 'Code');

    const defaultPattern =
        // !FieldName?.includes('ValueType') &&
        // FieldName !== 'Property' &&
        // FieldName !== 'Parameter' &&
        // composite measure value
        !(
            FieldName?.includes('MeasureUnit') &&
            list.find((field) => {
                const FieldNameInList = (
                    options?.isSnakeCaseValue
                        ? toPascalCase(field[FieldNameKey])
                        : field[FieldNameKey]
                ).replace('Code', options?.diableCodeSuffix ? '' : 'Code');

                return FieldNameInList?.includes(
                    `${FieldName?.split('MeasureUnit')?.join('')}Value`
                );
            })
        ) &&
        // composite curreny value
        !(
            FieldName?.includes('Currency') &&
            FieldName !== 'Currency' &&
            !FieldName?.includes('CurrencyValue') &&
            list.find((field) => {
                const FieldNameInList = (
                    options?.isSnakeCaseValue
                        ? toPascalCase(field[FieldNameKey])
                        : field[FieldNameKey]
                ).replace('Code', options?.diableCodeSuffix ? '' : 'Code');

                return FieldNameInList?.includes(`${FieldName?.split('Currency')?.join('')}Value`);
            })
        );

    if (options?.isHandlerModal) {
        return defaultPattern && !FieldName?.includes('ValueType');
    }

    if (options?.isDetail)
        return (
            !(
                FieldName?.includes('Interval') &&
                list.find((field) => {
                    const FieldNameInList = (
                        options?.isSnakeCaseValue
                            ? toPascalCase(field[FieldNameKey])
                            : field[FieldNameKey]
                    ).replace('Code', options?.diableCodeSuffix ? '' : 'Code');

                    return FieldNameInList === 'DaysPattern';
                })
            ) &&
            defaultPattern &&
            !FieldName?.includes('ValueType')
        );

    return defaultPattern;
};
