// import { MetaField } from 'modules/services/backend-api/generated_info';
// import { LANGUAGES } from 'utils/i18n/i18n';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { isArray, isObject } from 'is-lite/exports';
import { Badge, Checkbox, Flex, Typography } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons'; // а зачем мы сделали алиас на fieldRender как render?
import { formatNumberWithSpaces } from 'smart/components/MoneyField/MoneyField';
import { PlainObject } from '@gilbarbara/types';
import {
    downloadFile,
    fieldRender as render,
    parseValueType,
    ColumnOptionsType
} from '../../../utils';
import { BarcodeField } from '../../BarcodeField/BarcodeField';
import { ProgressBarField } from '../../ProgressBarField/ProgressBarField';
import { FILE_BUCKET } from '../../../../utils/config/constants';
import { StoreLink } from '../../../../ui';
import { LazyIcon, SmartTooltip } from '../../../ui';
import { UserSpecificFormat } from '../../../../utils/helpers/dates';
import { durationPrettyOutput } from '../../SmartDurationField/SmartDurationField';
import { LogsField } from '../../LogsField/LogsField';
import { ANY_DATA } from '../../../../modules/services/backend-api/generated_types';
import { MetaField } from '../../../../modules/services/backend-api/generated_info';
import { LANGUAGES } from '../../../../utils/i18n/i18n';

type FieldRenderType = {
    (options: {
        field: MetaField;
        language: LANGUAGES;
        fields: MetaField[];
        fieldsMap: Map<string, MetaField>;
        isViewAsLink?: boolean;
        rootMeta?: string;
        rootDataSource?: IObjectWithId;
        type: string;
        options: ColumnOptionsType | null;

        // TODO : типизировать
        fieldProps?: Record<string, any>;
        metaAndRouteProps?: Record<string, any>;
    }): (value: any, row: IObjectWithId, rowIndex: number) => React.ReactNode;
};

interface WrapperProps {
    children: React.ReactNode;
    codeColor?: string;
}

const StatusWrapper: React.FC<WrapperProps> = ({ children, codeColor = 'gray' }) => (
    <Flex style={{ width: '100%' }} gap={5}>
        <Badge color={codeColor} />
        <SmartTooltip>{children}</SmartTooltip>
    </Flex>
);

const NonStatusWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // return <>{children}</>;
    return <SmartTooltip>{children}</SmartTooltip>;
};

// );

const getDisplayValueMultiLang = (value: ANY_DATA, viewFieldName: string, language: string) => {
    return value[viewFieldName] ? value[viewFieldName][language] : null;
};

const getDisplayValueSimple = (value: ANY_DATA, viewFieldName: string, language: string) => {
    return value[viewFieldName];
};

const getDisplayValueDefault = (value: ANY_DATA, language: string) => {
    return value.Name?.[language] ?? value.Code ?? value.PropertyName;
};

const getDisplayValueDisplayName = (value: ANY_DATA, language: string) => {
    const displayName = value?.DisplayName;

    return displayName && typeof displayName === 'object' ? displayName[language] : displayName;
};

function renderBarcodeField(value: string) {
    return <BarcodeField value={value} readOnly />;
}

function renderUtilizationField(value: string | number) {
    return (
        <div style={{ width: 200, paddingRight: 10 }}>
            <ProgressBarField value={Number(value)} />
        </div>
    );
}

const renderIconField = (value: string) => (
    <span>
        <LazyIcon icon={value} /> {value ? `(${value})` : ''}
    </span>
);

function renderLogsField(value: string, row: { DurationSec?: number; Id: string }) {
    // FIXME: почему мы не используем row.DurationSec? никто не гарантирует такого названия поля. оно может как угодно называться. в value должны быть секунды!
    // RE: так используем же. таков был кейс когда разрабатывали данный контрол. что нужно пофиксить?
    return <LogsField requestId={row.Id} btnSize="small" hardDuration={row?.DurationSec} />;
}

// Helper function to extract both meta info and route-related properties
export const getMetaAndRouteProperties = (
    options: any,
    language: string,
    field: any,
    isViewAsLink: boolean
) => {
    const metaInfo = metaStore.meta.get(options?.ref?.meta ?? '')?.info;

    const viewFieldName = metaInfo?.ViewFieldName;
    const viewFieldNameType = viewFieldName
        ? metaInfo?.Fields.find((f) => f.FieldName === viewFieldName)?.ValueType
        : undefined;

    const uiAllowView = metaInfo?.UiAllowView ?? true;

    const getDisplayValue = !viewFieldName
        ? (value: ANY_DATA) => getDisplayValueDefault(value, language)
        : viewFieldName === 'DisplayName'
        ? (value: ANY_DATA) => getDisplayValueDisplayName(value, language)
        : viewFieldNameType?.includes('jsonb_multilang_text')
        ? (value: ANY_DATA) => getDisplayValueMultiLang(value, viewFieldName as string, language)
        : (value: ANY_DATA) => getDisplayValueSimple(value, viewFieldName as string, language);

    // Handle meta route logic
    const metaCodeForGetRoute =
        options?.ref?.meta ?? ((isViewAsLink ? field.Meta_Code : '') as string);
    const metaRoutesMap = metaStore.meta.get('all')?.routesMap;
    const toMetaRoute = metaRoutesMap?.get(metaCodeForGetRoute)?.[0];
    const pathnameBody = toMetaRoute?.path?.split('?')?.[0];

    return {
        metaInfo,
        viewFieldName,
        viewFieldNameType,
        uiAllowView,
        getDisplayValue,
        metaCodeForGetRoute,
        metaRoutesMap,
        toMetaRoute,
        pathnameBody
    };
};

export const getFieldProperties = (
    type: string,
    field: any,
    options: any,
    isViewAsLink: boolean
) => {
    const metaCodeForGetRoute =
        options?.ref?.meta ?? ((isViewAsLink ? field.Meta_Code : '') as string);

    const isInteger = type.startsWith('int');
    const isUtilization =
        type === 'utilization' || (isInteger && field.FieldName.includes('Utilization'));
    const isFile = type === 'file';
    const isIdArray = type === '[]id' || type === '[]object_id';
    const isId = type === 'id' || type === 'object_id';
    const isMultiLanguage = type === 'jsonb_multilang_text';
    const isKey = type === 'key';
    const isCodeArray = type === '[]code';
    const isCode = type === 'code';
    const isStatusField =
        field.FieldName.includes('Status') || options?.ref?.meta?.includes('Status');
    const isMeasureUnit = type === 'decimal' || type === 'money';
    const isType = type === 'value_type';
    const isTypeValue = type === 'jsonb' && field.FieldName.endsWith('Value');
    const isBoolean = type === 'bool' || type === 'boolean';
    const isBooleanArray = type === '[]bool' || type === '[]boolean';
    const isText = type === 'text' || type === 'string';
    const isTableName = type === 'table_name';
    const isLocalDateTimeRange = type === 'local_datetime_range';
    const isDateTimeRange = type === 'datetime_range';
    const isDatesRange = type === 'dates_range';
    const isTimeRangeArray = type === '[]time_range';
    const isTimeRange = type === 'time_range';
    const isDateTime = type === 'datetime';
    const isTime = type === 'time';
    const isDuration = type === 'duration' || type === 'seconds';
    const isDate = type === 'date';
    const isMoney = type === 'money';
    const isMoneyAmount = type === 'money' && field.FieldName.includes('Amount');
    const isReal =
        type === 'number' || type === 'numeric' || type === 'float32' || type === 'float64';
    const isCoordinate = type === 'coordinate';
    const isColor = type === 'color';
    const isJson = type === 'jsonb';
    const isLogs = type === 'logs';
    const isScript = type === 'script';
    const isTextArray = type === '[]text';
    const isBarcode = type === 'barcode';
    const isObjectMeta = options?.is_dynamic_ref;
    const isIcon = field.FieldName === 'Icon';
    // const isDynamicType = field.FieldName === 'ObjectType' && options?.ref === undefined;
    const isDynamicType = options?.is_dynamic_type;

    return {
        metaCodeForGetRoute,
        isInteger,
        isUtilization,
        isFile,
        isIdArray,
        isId,
        isMultiLanguage,
        isKey,
        isCodeArray,
        isCode,
        isStatusField,
        isMeasureUnit,
        isType,
        isTypeValue,
        isBoolean,
        isBooleanArray,
        isText,
        isTableName,
        isLocalDateTimeRange,
        isDateTimeRange,
        isDatesRange,
        isTimeRangeArray,
        isTimeRange,
        isDateTime,
        isTime,
        isDuration,
        isDate,
        isMoney,
        isMoneyAmount,
        isReal,
        isCoordinate,
        isColor,
        isJson,
        isLogs,
        isScript,
        isTextArray,
        isBarcode,
        isObjectMeta,
        isIcon,
        isDynamicType
    };
};

export const fieldRender: FieldRenderType = ({
    field,
    language,
    fields,
    fieldsMap,
    rootMeta,
    rootDataSource,
    type,
    options,
    isViewAsLink = false,
    fieldProps,
    metaAndRouteProps
}) => {
    // Get meta info and route-related properties from the new helper function
    const { uiAllowView, getDisplayValue, pathnameBody } =
        metaAndRouteProps ?? getMetaAndRouteProperties(options, language, field, isViewAsLink);

    // Get field properties from the other helper function
    const {
        isInteger,
        isUtilization,
        isFile,
        isIdArray,
        isId,
        isMultiLanguage,
        isKey,
        isCodeArray,
        isCode,
        isStatusField,
        isMeasureUnit,
        isType,
        isTypeValue,
        isBoolean,
        isBooleanArray,
        isText,
        isTableName,
        isLocalDateTimeRange,
        isDateTimeRange,
        isDatesRange,
        isTimeRangeArray,
        isTimeRange,
        isDateTime,
        isTime,
        isDuration,
        isDate,
        isMoney,
        isMoneyAmount,
        isReal,
        isCoordinate,
        isColor,
        isJson,
        isLogs,
        isScript,
        isTextArray,
        isBarcode,
        isObjectMeta,
        isIcon,
        isDynamicType
    } = fieldProps ?? getFieldProperties(type, field, options, isViewAsLink);

    if (isIcon) return renderIconField;

    if (isObjectMeta || isDynamicType || options?.file_name?.includes('{{')) {
        // console.log(field);
        // TODO: а зачем здесь рекурсия?
        // RE: Это не рекурсия, а вызов старого рендеринга для этих кейсов. Заберите file_name и objectMeta на бэкенд
        return (value: any, row: IObjectWithId) => {
            // console.log(value, row);
            return render({
                data: value,
                dataSource: row,
                language,
                metaFieldData: fields,
                fieldName: field.FieldName,
                isForTable: true,
                isViewAsLink,
                rootMeta,
                rootDataSource
            });
        };
    }

    if (isBarcode) {
        return renderBarcodeField;
    }

    if (isUtilization) {
        return renderUtilizationField;
    }

    if (isLogs) {
        return renderLogsField;
    }

    if (isFile) {
        const fileNameFieldName = isArray(fields)
            ? fields.find((field) => field.FieldName.endsWith('FileName'))?.FieldName
            : undefined;

        return (value: string | unknown, row: IObjectWithId) => {
            if (typeof value !== 'string') return <></>;

            const fileName = // TODO: file_name тоже надо забрать бэкенду. -> Да
                options?.file_name || (fileNameFieldName ? row?.[fileNameFieldName] : undefined);

            return (
                <Typography.Link
                    ellipsis
                    style={{ padding: 0 }}
                    onClick={async () => {
                        await downloadFile(options?.bucket || FILE_BUCKET, value, fileName);
                    }}
                >
                    <PaperClipOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />{' '}
                    {fileName || value}
                </Typography.Link>
            );
        };
    }

    if (isIdArray || isCodeArray) {
        return (value: object | string | undefined) => {
            if (!value) return value;

            // TODO: JSON.parse(value) это очень дорогая операция - мы не можем без нее обойтись?
            // -> Можем, если будут гарантии, что value всегда будет прилетать структурой. Ранее такой гарантии не было
            let v = typeof value === 'object' ? value : JSON.parse(value);

            if (!isObject(v)) return <SmartTooltip>{v}</SmartTooltip>;
            if (!isArray(v)) v = [v];

            return (
                <SmartTooltip style={{ display: 'flex', gap: 5, flexWrap: 'nowrap' }}>
                    {(v as any[]).map((data, index, array) => (
                        <div style={{ display: 'inline-flex' }}>
                            <StoreLink
                                style={{ padding: 0 }}
                                to={{
                                    pathname: `${pathnameBody}/${data.Id}`,
                                    search: ''
                                }}
                                state={{ data }}
                            >
                                <SmartTooltip>{getDisplayValue(data)}</SmartTooltip>
                            </StoreLink>
                            {array.length !== index + 1 ? ',' : null}&nbsp;
                        </div>
                    ))}
                </SmartTooltip>
            );
        };
    }

    if (isId || isCode) {
        // wrapper for case: is status field OR not
        // done: нужно убрать IF с isStatusField ("isStatusField ?") изнутри <Flex> ведь выше уже проверили его
        // done: убрал создание кучи лишних анонимных функций
        const Wrapper = isStatusField ? StatusWrapper : NonStatusWrapper;

        // is ID, not link
        if (!pathnameBody || field.IsLinkHidden || !uiAllowView) {
            return (value: PlainObject<string | undefined | unknown>) =>
                value && typeof value === 'object' ? (
                    // TODO: а разве это правильно для объекта вызыать getDisplayValue - и внутри у value будет браться ViewFieldName текущей меты.
                    // Ведь если здесь объект то значит это ref-объект и у него своя мета и свой ViewFieldName!?
                    // и что тут будет - объект или не объект мы знаем не в в момент рендера ячейки а в момент рендера колонки - мы заранее знаем что там будет ref-объект и знаем его мету
                    // RE: мы как раз и берем viewFieldName рефа на уровне колонки. все нормально Кость

                    <SmartTooltip>
                        <Wrapper codeColor={(value.Color as string) ?? '#b4b4b4'}>
                            {getDisplayValue(value)}
                        </Wrapper>
                    </SmartTooltip>
                ) : (
                    <SmartTooltip>{value}</SmartTooltip>
                );
        }

        // is ID, is link
        return (value: ANY_DATA) => {
            if (!value || typeof value !== 'object') return value;

            return (
                <SmartTooltip>
                    <StoreLink
                        style={{ padding: 0 }}
                        to={{
                            pathname: `${pathnameBody}/${value.Id}`,
                            search: ''
                        }}
                        state={{ data: value }}
                    >
                        <Wrapper codeColor={(value.Color as string) ?? '#b4b4b4'}>
                            {getDisplayValue(value)}
                        </Wrapper>
                    </StoreLink>
                </SmartTooltip>
            );
        };
    }

    if (isMultiLanguage) {
        return (value: ANY_DATA, row) => {
            const renderValue = !!value && typeof value === 'object' ? value[language] : value;

            return (
                <SmartTooltip
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {renderValue}
                </SmartTooltip>
            );
        };
    }

    if (isKey) {
        return (value: any, row: IObjectWithId) => {
            // console.log('key render');
            if (!value) return value;

            if (typeof value !== 'object') {
                if (isViewAsLink && pathnameBody && uiAllowView) {
                    return (
                        <StoreLink
                            style={{ padding: 0 }}
                            to={{
                                pathname: `${pathnameBody}/${row.Id}`,
                                search: ''
                            }}
                            state={{ data: value }}
                        >
                            <SmartTooltip>{value}</SmartTooltip>
                        </StoreLink>
                    );
                }

                return <SmartTooltip>{value}</SmartTooltip>;
            }

            const displayValue = value.Key;

            if (pathnameBody && uiAllowView && !field.IsLinkHidden) {
                return (
                    <StoreLink
                        style={{ padding: 0 }}
                        to={{
                            pathname: `${pathnameBody}/${value.Id}`,
                            search: ''
                        }}
                        state={{ data: value }}
                    >
                        <SmartTooltip>{displayValue}</SmartTooltip>
                    </StoreLink>
                );
            }

            return <SmartTooltip>{displayValue}</SmartTooltip>;
        };
    }

    if (isMeasureUnit) {
        const measureUnitField = fields.find(
            (metaField: MetaField) =>
                // composite measure value
                metaField.FieldName === `${field.FieldName.split('Value').join('')}MeasureUnit` ||
                // composite curreny value
                metaField.FieldName ===
                    `${field.FieldName.split('CurrencyValue').join('')}Currency` ||
                metaField.FieldName === `${field.FieldName.split('Amount').join('')}Currency` ||
                metaField.FieldName === `${field.FieldName.split('Value').join('')}Currency` ||
                metaField.FieldName === `${field.FieldName.split('CostValue').join('')}Currency`
        );

        const { options: measureUnitOptions } = parseValueType(
            measureUnitField?.ValueType || '',
            language
        );

        const moneyTextAlign = isMoneyAmount ? 'end' : undefined;
        const moneyStyle: React.CSSProperties = { textAlign: moneyTextAlign };

        if (isMoney) {
            return (value, row: IObjectWithId) => {
                let selectAfter;

                if (measureUnitField && measureUnitOptions?.ref) {
                    selectAfter = row[measureUnitField?.FieldName];
                }

                const displayValue = Number(selectAfter ? value || '0' : value || '');

                const multilangUnitField = selectAfter?.Name;

                const displayUnit = selectAfter
                    ? multilangUnitField?.[language] ?? (selectAfter.Code || '')
                    : '';

                return (
                    <SmartTooltip style={moneyStyle}>
                        {formatNumberWithSpaces(Number(displayValue))} {displayUnit || ''}
                    </SmartTooltip>
                );
            };
        }

        return (value, row) => {
            let selectAfter;

            if (measureUnitField && measureUnitOptions?.ref) {
                selectAfter = row[measureUnitField?.FieldName];
            }

            const displayValue = Number(selectAfter ? value || '0' : value || '');

            const multilangUnitField = selectAfter?.Name;

            const displayUnit = selectAfter
                ? multilangUnitField?.[language] ?? (selectAfter.Code || '')
                : '';

            return (
                <SmartTooltip>
                    {displayValue} {displayUnit || ''}
                </SmartTooltip>
            );
        };
    }

    if (isMoney) {
        return (value) => (typeof value === 'number' ? `${formatNumberWithSpaces(value)}` : null);
    }

    if (isType) {
        return (value) => {
            // console.log(value);
            if (!value) return null;
            return <SmartTooltip>{value.ValueType ?? value.toString()}</SmartTooltip>;
        };
    }

    if (isTypeValue) {
        const valueTypeFieldName = fields.find(
            (metaField) =>
                metaField.ValueType?.includes('value_type') ||
                // metaField.FieldName.includes(`${field.FieldName.replace('Value', '')}`) ||
                metaField.FieldName === 'Parameter' ||
                metaField.FieldName === 'Property'
        )?.FieldName;

        return valueTypeFieldName
            ? (value, row, index) => {
                  const typeStruct = row[valueTypeFieldName];
                  const isTypeStructString = typeof typeStruct === 'string';

                  const valueType = isTypeStructString ? typeStruct : typeStruct?.ValueType;
                  //   const typeName = isTypeStructString
                  //       ? valueType
                  //       : typeStruct?.Name?.[language] ?? typeStruct?.Code;

                  const { type, options } = parseValueType(valueType || '', language);

                  return (
                      <Flex gap={5} style={{ width: '100%' }}>
                          {/* {valueType ? (
                              <span style={{ display: 'flex' }}>
                                  [
                                  <SmartTooltip style={{ display: 'inline', maxWidth: 75 }}>
                                      {valueType}
                                  </SmartTooltip>
                                  ]
                              </span>
                          ) : null} */}
                          {fieldRender({
                              field: {
                                  ...field,
                                  FieldName: `${field.FieldName}*`, // for fix rec-loop for `jsonb` ValueType
                                  ValueType: valueType
                              },
                              language,
                              fields,
                              fieldsMap,
                              rootMeta,
                              rootDataSource,
                              isViewAsLink,
                              type: type,
                              options: options
                          })(value, row, index)}
                      </Flex>
                  );
              }
            : () => null;
    }

    if (isBooleanArray) {
        return (value) => {
            if (value && Array.isArray(value)) {
                return (
                    <Flex gap={5}>
                        {value.map((v) => (
                            <Checkbox disabled defaultChecked={!!v} />
                        ))}
                    </Flex>
                );
            }

            if (value && typeof value === 'boolean') {
                return <Checkbox disabled defaultChecked={true} />;
            }

            return null;
        };
    }

    if (isBoolean) {
        return (value) => (
            <Checkbox disabled checked={!!value} defaultChecked={options?.default === 'true'} />
        );
    }

    if (isTextArray) {
        return (value) => {
            if (isArray(value)) {
                return <SmartTooltip>{value.join(', ')}</SmartTooltip>;
            }

            if (value)
                return (
                    // <SmartTooltip lineClamp={field.Options?.RowsCount}>
                    <SmartTooltip>{value?.toString()}</SmartTooltip>
                );

            return value;
        };
    }

    if (isText || isTableName) {
        return (value: any) => {
            if (value)
                return (
                    <>
                        <SmartTooltip
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {value?.toString()}
                        </SmartTooltip>
                    </>
                    // <SmartTooltip lineClamp={field.Options?.RowsCount}>
                );

            return value;
        };
    }

    if (isLocalDateTimeRange) {
        return (value: any) => {
            if (value?.FromDatetime && value?.ToDatetime) {
                return (
                    <SmartTooltip>
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.FromDatetime,
                            language
                        )}
                        {' - '}
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.ToDatetime,
                            language
                        )}
                    </SmartTooltip>
                );
            }

            return null;
        };
    }

    if (isDateTimeRange) {
        return (value: any) => {
            if (value?.FromDatetime && value?.ToDatetime) {
                return (
                    <SmartTooltip>
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.FromDatetime,
                            language
                        )}
                        {' - '}
                        {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
                            value.ToDatetime,
                            language
                        )}
                    </SmartTooltip>
                );
            }

            return null;
        };
    }

    if (isDatesRange) {
        return (value: any) => {
            if (value?.FromDate && value?.ToDate) {
                return (
                    <SmartTooltip>
                        {UserSpecificFormat.convertFromDbDateToUiDate(value.FromDate)}
                        {' - '}
                        {UserSpecificFormat.convertFromDbDateToUiDate(value.ToDate)}
                    </SmartTooltip>
                );
            }

            return null;
        };
    }

    if (isTimeRangeArray) {
        return (value) => {
            if (value && isArray(value)) {
                return (
                    <SmartTooltip>
                        {value.map((v) => {
                            return `${v.FromTime} - ${v.ToTime}, `;
                        })}
                    </SmartTooltip>
                );
            }

            return null;
        };
    }

    if (isTimeRange) {
        return (value: any) => {
            if (value?.FromTime && value?.ToTime) {
                return (
                    <SmartTooltip>
                        {value.FromTime}
                        {' - '}
                        {value.ToTime}
                    </SmartTooltip>
                );
            }

            return null;
        };
    }

    if (isDateTime) {
        return (value: string) => (
            <SmartTooltip>
                {UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(value, language)}
            </SmartTooltip>
        );
    }

    if (isTime) {
        return (value: string) => (
            <SmartTooltip>
                {UserSpecificFormat.convertFromDbTimeToUiTime(value, language)}
            </SmartTooltip>
        );
    }

    if (isDuration) {
        return (value: string) =>
            value ? <SmartTooltip>{durationPrettyOutput(value)}</SmartTooltip> : null;
    }

    if (isDate) {
        return (value: string) => (
            <SmartTooltip>
                {UserSpecificFormat.convertFromDbDateToUiDate(value, language)}
            </SmartTooltip>
        );
    }

    if (isInteger || isReal) {
        return (value, row) => {
            return typeof value === 'number' ? `${value}` : null;
        };
    }

    if (isCoordinate) {
        return (value: { Latitude: string; Longitude: string }) =>
            value?.Latitude && value?.Longitude ? (
                <SmartTooltip>
                    {value.Latitude} {value.Longitude}
                </SmartTooltip>
            ) : null;
    }

    if (isColor) {
        return (value: string) => (
            <div
                style={{
                    backgroundColor: value,
                    padding: '2px 5px',
                    width: 'max-content',
                    textAlign: 'center'
                }}
            >
                <span style={{ backgroundClip: 'text' }}>{value}</span>
            </div>
        );
    }

    if (isScript) {
        return (value: string) => (
            <Typography.Text
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                    padding: 0
                }}
            >
                {value}
            </Typography.Text>
        );
    }

    if (isJson) {
        if (options?.json_type) {
            const valueType = `${options.json_type};ref:${options.ref?.meta || ''}.${
                options.ref?.fieldName || ''
            }${options.filters ? `;filters:${options.filters}` : ''}${
                options.group ? `;group:${options.group}` : ''
            }${options.display ? `;display:${options.display}` : ''}`;

            const { type: jsonFieldType, options: jsonFieldOption } = parseValueType(
                valueType || '',
                language
            );

            return fieldRender({
                language,
                field: {
                    ...field,
                    ValueType: valueType
                },
                fields,
                fieldsMap,
                rootMeta,
                isViewAsLink,
                rootDataSource,
                type: jsonFieldType,
                options: jsonFieldOption
            });
        }

        return (value) => (
            <SmartTooltip>{typeof value === 'string' ? value : JSON.stringify(value)}</SmartTooltip>
        );
    }

    if (options && options?.default) {
        return (value) => <SmartTooltip>{value?.toString() || options.default}</SmartTooltip>;
    }

    if (options?.value) return () => <SmartTooltip>{options?.value}</SmartTooltip>;

    return () => null;
};
