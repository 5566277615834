import { THEME } from 'App/contexts/AppProvidersWrapper';

// Функция для обработки темы из строки APP_THEME
export const parseEnvTheme = (themeStr?: string): Partial<THEME['token']> | null => {
    if (!themeStr) return null;
    try {
        const parsed = JSON.parse(themeStr);
        return typeof parsed === 'object' ? parsed : null;
    } catch (error) {
        console.error('Ошибка парсинга APP_THEME:', error);
        return null;
    }
};
