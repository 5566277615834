// ServerApi.ts
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import WebSocketService from './WebSocketService';
import {
    AcquireLockRequest,
    AssociationRequest,
    ExchangeInviteTokenForSession,
    ExtendLockRequest,
    GetParamRequest,
    GetRequest,
    LogsRequest,
    MetaRequest,
    MetasRequest,
    NotificationResponse,
    ReleaseLockRequest,
    Response,
    RunRequest,
    SaveRequest,
    SelectRequest,
    StatusCodeOK
} from './generated_api';
import { CODE } from './generated_types';
import { loadClientFromLocalStorage } from 'modules/client/localStorage';
import { ApiClient } from 'modules/client/types';

// Define the extended interface with additional specific properties
export interface GetParamRequestWithDefault extends GetParamRequest {
    default_value?: any; // Add or override specific properties here
}

export type ProcessNotificationFn = (notification: NotificationResponse) => void;

export class ServerApi {
    public channel: WebSocketService | null = null;
    public client: ApiClient | null = null;

    private token: string = '';
    private auth_provider: string = '';

    isConnected: boolean = false;

    private connectionPromise: Promise<void> | null = null;

    constructor(url: string) {
        console.log('ServerApi: constructor');
        // const provider = client.authProvider;
        // this.auth_provider = provider;
        // debugger;
        if (!this.client) {
            const client = loadClientFromLocalStorage();
            if (client) {
                this.client = client;
            }
        }
        if (!this.channel) {
            this.channel = new WebSocketService(url);
            this.checkAndRefreshToken();
        }
        // makeAutoObservable(this);
    }

    private checkAndRefreshToken = async () => {
        // console.debug('checkAndRefreshToken');
        // debugger;
        if (this.client && (await this.client.isAuthenticated())) {
            // console.debug('checkAndRefreshToken cilent is auth');
            const session = await this.client.getSession();
            const provider = this.client.authProvider;
            if (!session) return;
            const { data, error } = session;
            if (error) {
                throw error;
            }
            // debugger;
            if (data && data?.access_token) {
                this.token = data.access_token;
                // this.auth_provider = provider;
            }
        } else {
            return;
        }
        // if (supabaseClient.auth && supabaseClient.auth_provider === 'supabase') {
        //     const { data, error } = await supabaseClient.auth.getSession();
        //     if (error) {
        //         throw error;
        //     }

        //     if (data) {
        //         const session = data.session;
        //         if (!session) {
        //             if (
        //                 window.location.pathname.includes('/login') ||
        //                 window.location.pathname.includes('/signup') ||
        //                 window.location.pathname.includes('/verify') ||
        //                 window.location.pathname.includes('/invited')
        //             ) {
        //                 return;
        //             }

        //             throw new Error(makeErrorReadable('Нет сессии'));
        //         }
        //         this.auth_provider = supabaseClient.auth_provider;
        //         this.token = session.access_token;
        //     } else {
        //         throw new Error(makeErrorReadable('Данные для определения сессии пусты'));
        //     }
        // } else if (supabaseClient.auth_provider === 'keycloak') {
        //     //TODO: поддержка рефреш токена
        //     this.token = supabaseClient.keycloak_access_token || '';
        //     this.auth_provider = supabaseClient.auth_provider;
        //     // return;
        // } else {
        //     const supabaseKey = process.env.SUPABASE_SERVICE_ROLE_KEY;
        //     this.token = supabaseKey || '';
        // }

        if (!this.isConnected || this.channel?.getWs()?.readyState === WebSocket.CLOSED) {
            await this.connect();
        }
    };

    isConnectedAndAuthenticated = () => {
        return this.isConnected && !!this.token;
    };
    connect = async (): Promise<void> => {
        if (this.connectionPromise) {
            return this.connectionPromise; // Return existing promise if connection is in progress
        }

        if (!this.channel) {
            return Promise.reject(new Error('WebSocket channel is not initialized.'));
        }

        // Ensure that connect() always returns a Promise<void>
        this.connectionPromise = this.channel
            .connect()
            .then(() => {
                this.isConnected = true;
                this.connectionPromise = null; // Reset promise after connection is established
            })
            .catch((err) => {
                console.error('Failed to connect WebSocket:', err);
                this.connectionPromise = null; // Reset promise on failure
                throw err; // Re-throw error to propagate it up the call stack
            });

        return this.connectionPromise;
    };

    setClient = (client: ApiClient) => {
        this.client = client;
    };

    routes = async () => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            // auth_provider: this.auth_provider,
            routes: {}
        });

        if (result && result.status_code === StatusCodeOK && result.routes) {
            return result.routes;
        }

        const error = new Error(
            `Routes returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    menu = async () => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            // auth_provider: this.auth_provider,
            menu: {}
        });

        if (result && result.status_code === StatusCodeOK && result.menu) {
            return result.menu;
        }

        const error = new Error(
            `Menu returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    infos = async (options: MetasRequest) => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            // auth_provider: this.auth_provider,
            infos: options
        });

        if (result && result.status_code === StatusCodeOK && result.infos) {
            return result.infos;
        }

        const error = new Error(
            `Info returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    info = async (options: MetaRequest) => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            // auth_provider: this.auth_provider,
            info: options
        });

        if (result && result.status_code === StatusCodeOK && result.info) {
            return result.info;
        }

        const error = new Error(
            `Info returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    get = async (options: GetRequest) => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            // auth_provider: this.auth_provider,
            get: options
        });

        if (result && result.status_code === StatusCodeOK && result.get) {
            return result.get;
        }

        const error = new Error(
            `Get returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    get_param = async (options: GetParamRequestWithDefault) => {
        await this.checkAndRefreshToken();

        const { default_value, ...restOptions } = options;

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            // auth_provider: this.auth_provider,
            get_param: restOptions // omitted default_value
        });

        if (result && result.status_code === StatusCodeOK && result.get_param) {
            return result.get_param.param_value;
        }

        // Handle error - use default value
        // Log the warning with the default value

        console.warn(
            `GetParam failed: ${JSON.stringify(
                options
            )}. Using default value = '${default_value}'. RequestId: ${result?.request_id}`
        );
        return default_value;
    };

    save = async (options: SaveRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            save: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.save) {
            return result.save;
        }

        const error = new Error(
            `Save returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error);
        throw { error, result };
    };

    run = async (options: RunRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            run: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK) {
            console.log('Run result:', result);
            return result;
        }

        const error = new Error(
            `Run returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return result;
    };

    association = async (options: AssociationRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            association: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.association) {
            return result.association;
        }

        const error = new Error(
            `Association returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    select = async (options: { meta: CODE } & Partial<Omit<SelectRequest, 'meta'>>) => {
        await this.checkAndRefreshToken();

        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            select: options
        };

        // const start = performance.now(); // Log start time
        const result = await this.channel?.sendRequest(paramRequest);
        // const end = performance.now();
        // console.log(`api.select with request_id=${result?.request_id} took ${end - start} ms`);

        if (result && result.status_code === StatusCodeOK && result.select) {
            return result.select;
        }

        const error = new Error(
            `Select returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        // return error;
        throw { error, result };
    };

    acquireLock = async (options: AcquireLockRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            acquire_lock: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.acquire_lock) {
            return result.acquire_lock;
        }

        const error = new Error(
            `AcquireLock returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        // return error;
        throw { error, result };
    };

    extendLock = async (options: ExtendLockRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            extend_lock: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.extend_lock) {
            return result.extend_lock;
        }

        const error = new Error(
            `ExtendLock returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        throw { error, result };
    };

    releaseLock = async (options: ReleaseLockRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            release_lock: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.release_lock) {
            return result.release_lock;
        }

        const error = new Error(
            `ReleaseLock returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        throw { error, result };
    };

    logs = async (options: LogsRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            logs: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.logs) {
            return result.logs;
        }

        const error = new Error(
            `Logs returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error; // done: надо здесь и везде выше решить что возвращать в таких случаях. Ничего нельзя - потребитель же ждет результат!
    };

    subscribeNotifications = async (process: ProcessNotificationFn) => {
        await this.checkAndRefreshToken();

        const paramRequest = {
            access_token: this.token,
            // auth_provider: this.auth_provider,
            subscribe_notifications: {}
        };

        const internalProcess = (response: Response) => {
            const notification = response.notification;
            if (!notification) {
                console.error('subscribeNotifications: No notification in response:', response);
                return;
            }

            process(notification);
        };

        return this.channel?.subscribe(paramRequest, internalProcess);
    };

    exchangeInviteTokenForSession = async (options: ExchangeInviteTokenForSession) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: '',
            // auth_provider: this.auth_provider,
            exchange_invite_token_for_session: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (
            result &&
            result.status_code === StatusCodeOK &&
            result.exchange_invite_token_for_session
        ) {
            return result.exchange_invite_token_for_session;
        }

        const error = new Error(
            `ExchangeInviteTokenForSession returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );

        console.error(error.message);
        return error;
    };
}
