import dayjs from 'dayjs';
import { range } from 'lodash';
import { getGetFreeHoursByDate } from './getFreeHoursByDate';
import { RegLocationResourceSlot } from '../../../../modules/services/backend-api/generated_models';
import { DATE_FORMAT } from '../../../../utils/helpers/dates';

export const calculateFreeHoursForResource = (
    freeSlots: RegLocationResourceSlot[],
    resourceId: string,
    daysCount: number,
    selectedDate: dayjs.Dayjs,
    firstColumnFieldName: string
) => {
    let totalFreeHours = 0;

    for (const day of range(0, daysCount)) {
        const showDate = selectedDate.local().add(day, 'days');
        const showDateString = showDate.format(DATE_FORMAT);

        const freeHours =
            getGetFreeHoursByDate()(freeSlots, resourceId, firstColumnFieldName).get(showDateString)
                ?.length ?? 0;

        totalFreeHours += freeHours;
    }

    return totalFreeHours;
};
