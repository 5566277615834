import { Meta } from 'modules/services/backend-api/generated_info';

import { isArray, isFunction, isPropertyOf } from 'is-lite/exports';
import { UserSpecificFormat } from 'utils/helpers/dates';
import { InfoUser } from 'modules/services/backend-api/generated_models';
import { getNestedValueFromObject } from './getNestedValueFromObject';
import { JSONSafeParse } from './JSONSafeParse';

type ColumnOptionsRefType = { meta: string; fieldName?: string };

interface Context {
    root?: any;
    current?: any;
    self?: any;
    info?: Meta;
    user?: InfoUser;
}

export type ColumnOptionsType = {
    ref?: ColumnOptionsRefType;
    default?: number | string | boolean;
    filters?: string;
    fields?: string[];
    path?: string;
    file_name?: string;
    bucket?: string;
    group?: string;
    display?: 'inline' | 'dialog';
    is_root_id?: boolean;
    json_type?: string;
    value?: string;
    language?: string;
    is_reorder?: boolean;
    is_dynamic_ref?: boolean;
    is_dynamic_type?: boolean;
    hide_code?: boolean;
    values?: string;
    [key: string]: any; // TODO: а зачем мы это поддерживаем? Может, лучше убрать и не создавать рисков с неожиданными ключами?
};

// Helper function to retrieve the function by name
const getFunctionByName = (name: string) => {
    const functionsMap: { [key: string]: Function } = {
        toLocalDateTime: (value: any, language: string) => {
            // Example implementation for toLocalDateTime
            // return new Date(value).toLocaleString();
            return UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(value, language);
        },
        currentDateTime: (value: any, language: string) => {
            return UserSpecificFormat.currentDateTime();
        },

        // currentDateTimeInUserZone:(value: any, language: string) => {
        //     return UserSpecificFormat.currentDateTimeInUserZone();
        // },

        // userTimeZone:(value: any, language: string) => {
        //     return Intl.DateTimeFormat().resolvedOptions().timeZone
        // },

        currentDateRangeToday: (value: any, language: string) => {
            return {
                FromDate: UserSpecificFormat.currentDate(),
                ToDate: UserSpecificFormat.currentDate()
            };
        },

        mapPartnerRoleCode: (value: any) => {
            const data = JSONSafeParse(value);

            if (isArray(data) && data.at(0) && isPropertyOf(data.at(0), 'PartnerRole_Code')) {
                return `[${data.map(({ PartnerRole_Code }) => PartnerRole_Code).join(',')}]`;
            }

            return value;
        }
        // Add more functions here as needed
    };

    return functionsMap[name];
};

// export function removeUndefinedFilters(input: string): string {
//     // Регулярное выражение для поиска фильтров вида `&Code=eq.undefined`
//     // const regex = /&[^&]+?=eq\.undefined/g;
//     // const regex = /&[^&]+?=n?eq\.undefined/g;
//     const regex = /(^|&)?.+?=n?eq\.undefined(&|$)/g;

//     // Удаляем все совпадения из строки
//     // return input.replace(regex, '');
//     // return input.replace(regex, '');
//     return input.replace(regex, (_, start, end) => (start === '&' && end === '&' ? '&' : ''));
// }

export function removeUndefinedFilters(input: string): string {
    // Регулярное выражение для поиска фильтров вида `Name=eq.undefined` или `Name=neq.undefined`
    const regex = /(?:^|&)([^&=]+)=n?eq\.undefined(?=&|$)/g;

    // Удаляем совпадения и корректно обрабатываем символы `&`
    const result = input.replace(regex, (match, _, offset, str) => {
        if (match.startsWith('&')) {
            return ''; // Удаляем фильтр, если он в середине строки
        }
        if (offset === 0) {
            return ''; // Удаляем фильтр, если он в начале строки
        }
        return match; // Оставляем все остальное
    });

    if (result.startsWith('&')) return result.slice(1);
    return result;
}

// NOT STRING FUNCTION
const NOT_STRING_FUNCTIONS: Record<string, boolean> = {
    currentDateRangeToday: true
};

export const parseTemplate = (template: string, language: string, contextData: Context) => {
    if (template.includes('{{') && template.includes('}}')) {
        const regex = /{{\s*(?:(\w+)\s+)?((?:\w+\.)*\w+)\s*}}/g;

        let returnNoString = false;

        const parsedTemplate = template.replace(regex, (match, funcName, path) => {
            const objectPath = path
                ? path
                      .replace('Current.Root.', 'Current.root.') // case 1
                      .replace('Root.', '')
                      .replace('Current.', '')
                      .replace('Self.', '')
                      .replace('Info.', '')
                      .replace('User.', '')
                      .replace('root.', 'Root.') // case 1
                : '';

            let nestedValue;

            if (path?.includes('Root') && contextData.root) {
                nestedValue = getNestedValueFromObject(contextData.root, objectPath, language);
            } else if (path?.includes('Current') && contextData?.current) {
                nestedValue = getNestedValueFromObject(contextData.current, objectPath, language);
            } else if (path?.includes('Self') && contextData?.self) {
                nestedValue = getNestedValueFromObject(contextData.self, objectPath, language);
            } else if (path?.includes('Info') && contextData?.info) {
                nestedValue = getNestedValueFromObject(contextData.info, objectPath, language);
            } else if (path?.includes('User') && contextData?.user) {
                nestedValue = getNestedValueFromObject(contextData.user, objectPath, language);
            } else {
                nestedValue = getNestedValueFromObject(contextData.current, objectPath, language);
            }

            if (funcName) {
                try {
                    const func = getFunctionByName(funcName);
                    if (isFunction(func) && NOT_STRING_FUNCTIONS[funcName]) {
                        const notStringResult = func(nestedValue, language);
                        template = notStringResult;
                        returnNoString = true;
                        // notStringResult = true
                        return;
                    }
                    if (isFunction(func)) return func(nestedValue, language);
                } catch (error) {
                    console.error(`Error applying function ${funcName}:`, error);
                }
            }

            return nestedValue;
        });

        if (returnNoString) {
            return template;
        }

        return parsedTemplate;
    }

    return template;
};

export const parseValueType = (valueType: string, language: string, contextData?: Context) => {
    if (!valueType) return { type: 'text', isOptional: true, isArray: false, options: null };

    // console.log(valueType);
    const [typeDef, ...optionParts] = valueType.split(';');

    let type = typeDef;
    const isOptional = typeDef.startsWith('*');
    if (isOptional) type = type.slice(1);

    const isArray = type.startsWith('[]');
    // if (isArray) type = type.slice(2);

    const options: ColumnOptionsType = optionParts.reduce<ColumnOptionsType>((acc, part) => {
        if (part.startsWith('ref:')) {
            const [meta, fieldName] = part.replace('ref:', '').split('.');
            acc.ref = { meta, fieldName };
        } else if (part === 'is_reorder') {
            acc.is_reorder = true;
        } else if (part === 'is_dynamic_ref') {
            acc.is_dynamic_ref = true;
        } else if (part === 'is_dynamic_type') {
            acc.is_dynamic_type = true;
        } else if (part === 'hide_code') {
            acc.hide_code = true;
        } else if (part.startsWith('default:')) {
            const value = part.replace('default:', '');
            acc.default = Number.isNaN(Number(value)) ? value : Number(value);
        } else if (part.startsWith('json_type:')) {
            const value = part.replace('json_type:', '');
            acc.json_type = value;
        } else if (part.startsWith('filters:')) {
            acc.filters = part.replace('filters:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.filters, language, contextData);

                if (parsedValue !== acc.filters) {
                    acc.filters = removeUndefinedFilters(parsedValue).replaceAll(
                        'undefined',
                        'null'
                    );
                    // .replaceAll('neq.null', 'isnot.null')
                    // .replaceAll('eq.null', 'is.null');
                }
            }
        } else if (part.startsWith('values:')) {
            acc.values = part.replace('values:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.values, language, contextData);
                acc.values = parsedValue === 'undefined' ? undefined : parsedValue;
            }
        } else if (part.startsWith('value:')) {
            acc.value = part.replace('value:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.value, language, contextData);
                acc.value = parsedValue === 'undefined' ? undefined : parsedValue;
            }
        } else if (part.startsWith('file_name:')) {
            acc.file_name = part.replace('file_name:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.file_name, language, contextData);
                acc.file_name = parsedValue.replaceAll('undefined', '');
            }
        } else if (part.startsWith('path:')) {
            acc.path = part.replace('path:', ''); // TODO: прикрутить тут шаблон
        } else if (part.startsWith('language:')) {
            acc.language = part.replace('language:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.language, language, contextData);
                acc.language = parsedValue === 'undefined' ? undefined : parsedValue;
            }
        } else if (part.startsWith('bucket:')) {
            acc.bucket = part.replace('bucket:', '');
        } else if (part.startsWith('fields:')) {
            acc.fields = part.replace('fields:', '').split(',');
        } else if (part.startsWith('group:')) {
            acc.group = part.replace('group:', '');
        } else if (part.startsWith('display:')) {
            acc.display = part.replace('display:', '') as 'inline' | 'dialog';
        } else if (part === 'is_root_id') {
            acc.is_root_id = true;
        } else {
            const [key, value] = part.split(':');
            acc[key] = Number.isNaN(Number(value)) ? value : Number(value);
        }
        return acc;
    }, {});

    return {
        type,
        isOptional,
        isArray,
        options: Object.keys(options).length ? options : null
    };
};
