import { Checkbox, Flex } from 'antd';
import { t } from 'i18next';
import { isArray, isDefined, isObject } from 'is-lite/exports';

import { MetaField } from 'modules/services/backend-api/generated_info';
import {
    BarcodeField,
    ColorField,
    DaysPatternField,
    DynamicTypeField,
    FilePickerField,
    JsonField,
    MoneyField,
    NumberField,
    ProgressBarField,
    RefField,
    SmartDateField,
    SmartMultilanguageField,
    SmartMultiSelectField,
    SmartSelectField,
    SmartTimeField,
    StringField,
    ValueTypeField
} from 'smart/components';
import { SmartCoordinateField } from 'smart/components/SmartCoordinateField/SmartCoordinateField';
import { SmartDateRangeField } from 'smart/components/SmartDateRangeField/SmartDateRangeField';
import { SmartDatetimeRangeField } from 'smart/components/SmartDatetimeRangeField/SmartDatetimeRangeField';
import { SmartDurationField } from 'smart/components/SmartDurationField/SmartDurationField';
import { SmartTimeRangeField } from 'smart/components/SmartTimeRangeField/SmartTimeRangeField';
import { i18n } from 'utils/i18n/i18n';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { LogsField } from 'smart/components/LogsField/LogsField';

import { parseValueType } from './parseValueType';
import { getNestedValueFromObject } from './getNestedValueFromObject';
import './style.scss';
import { JSONSafeParse } from './JSONSafeParse';
import { StoreLink } from '../../ui';

type FieldRenderType = {
    (options: {
        data: any;
        onChange: (key: string, value: any) => void;
        language: string;
        metaFieldData?: MetaField[] | MetaField;
        fieldName?: string;
        dataSource?: IObjectWithId;
        isEditable?: boolean;
        isForTable?: boolean;
        rootMeta?: string;
        rootDataSource?: IObjectWithId;
    }): React.ReactNode;
};

const dateFieldWidth = 170;
const numberFieldWidth = 170;
const dateRangeFieldWidth = 400;
const cachedPrevMeasureUnitNestedValue: { [key: string]: string } = {};
// const cachedStatusGroupNestedValue: { [key: string]: string } = {};

export const fieldEditRender: FieldRenderType = ({
    data,
    onChange,
    language,
    metaFieldData,
    fieldName,
    isEditable = false,
    dataSource,
    isForTable,
    rootMeta,
    rootDataSource
}) => {
    const value = data;

    let field: MetaField | undefined;

    if (isArray(metaFieldData) && fieldName) {
        field = metaFieldData.find(({ FieldName }) => FieldName === fieldName);
    } else if (metaFieldData) {
        field = metaFieldData as MetaField;
    }

    const isValueNulled = !value;
    const isValueObject = typeof value === 'object';

    if (field) {
        // Лог для дебага данных филдов
        // console.log('Field', `"${field?.Name?.[language]}" (${field.FieldName})`, value, field);

        const rootInfo = metaStore.meta.get(rootMeta || '')?.info;

        const { type, options } = parseValueType(field.ValueType || '', language, {
            root: rootDataSource || dataSource,
            current: dataSource,
            info: rootInfo,
            self: value
        });

        // cases constants
        const isCode = type === 'code';
        const isId = type === 'id' || type === 'object_id';
        const isFile = type === 'file';
        const isLogs = type === 'logs';
        const isScript = type === 'script';
        const isDynamicType = options?.is_dynamic_type && isDefined(dataSource);
        // field.FieldName === 'ObjectType' && !isDefined(options?.ref) && isDefined(dataSource);
        const isBarcode = type === 'barcode';
        // const isObjectMeta = type === 'jsonb' && options?.is_dynamic_ref;
        const isObjectMeta = options?.is_dynamic_ref;
        // const isSavedQuery = field.FieldName === 'SavedQuery';
        const isText = type === 'text' || type === 'string';
        const isTableName = type === 'table_name';

        const handleChange = (newValue: any) => {
            onChange(field.FieldName, newValue);
        };

        // if (isSavedQuery) {
        //     return <QueriesField />;
        // }

        if (isObjectMeta) {
            if (data) {
                const meta = data.Meta?.Code ?? data.Meta_Code;

                const metaRoutesMap = metaStore.meta.get('all')?.routesMap;
                const toMetaRoute = metaRoutesMap?.get(meta)?.[0];

                const displayName = data.DisplayName;
                const title =
                    displayName && typeof displayName === 'object'
                        ? displayName[language]
                        : displayName;

                return (
                    <StoreLink
                        style={{ padding: 0 }}
                        to={{
                            pathname: `${
                                toMetaRoute?.path
                                    ? toMetaRoute.path.split('?')[0]
                                    : `/other/${meta}`
                            }/${data.Id}`,
                            search: ''
                        }}
                    >
                        {title}
                    </StoreLink>
                );
            }

            return data;
        }

        if (isBarcode) {
            return (
                <BarcodeField
                    value={value}
                    optionsValue={JSONSafeParse(options?.value || '') as string}
                    onChange={handleChange}
                    readOnly={false}
                />
            );
        }

        if (
            type.includes('utilization') ||
            (type.includes('int') && field.FieldName.includes('Utilization'))
        ) {
            return (
                <div style={{ width: 200, paddingRight: 10 }}>
                    <ProgressBarField value={Number(value)} />
                </div>
            );
        }

        if (isDynamicType) {
            return (
                <DynamicTypeField
                    objectMeta={dataSource?.Meta?.Code}
                    onChange={handleChange}
                    value={value}
                />
            );
        }

        if (isFile) {
            // if (isValueNulled) return value;
            if (isValueObject) return <></>;

            if (isValueNulled || typeof value === 'string') {
                const meta =
                    (Array.isArray(metaFieldData)
                        ? metaFieldData[0]?.Meta_Code
                        : metaFieldData?.Meta_Code) || 'all';

                const fileNameField = isArray(metaFieldData)
                    ? metaFieldData.find((field) => field.FieldName.endsWith('FileName'))
                    : undefined;

                const fileName =
                    options?.file_name ||
                    (fileNameField ? dataSource?.[fileNameField.FieldName] : undefined);

                const isAttachments = options?.bucket === 'attachments';

                return (
                    <FilePickerField
                        path={isAttachments ? `${meta}/${dataSource?.Id}` : options?.path || ''}
                        bucket={options?.bucket}
                        value={{
                            file_path: value,
                            file_name: fileName || value?.split('/').at(-1) || '' // TODO: сделать необязательным ???
                        }}
                        onChange={(newValue) => handleChange(newValue.file_path)}
                        onChangeExternalFileName={
                            fileNameField
                                ? (newValue) => onChange(fileNameField.FieldName, newValue)
                                : undefined
                        }
                    />
                );
            }
        }

        if (type.includes('[]id') || type.includes('[]object_id')) {
            if (isValueNulled || isValueObject || isObject(JSON.parse(value))) {
                if (options && options.ref) {
                    const v = value && !isValueObject ? JSON.parse(value) : value;

                    return (
                        <SmartMultiSelectField
                            metaName={options.ref.meta}
                            value={isArray(v) || !v ? v : [v]}
                            onChange={handleChange}
                            filters={options.filters}
                            treeOptions={{
                                groupKeys: options?.group ? [options.group] : undefined,
                                parentField: 'Parent'
                            }}
                        />
                    );
                }

                const displayValue = !isValueNulled ? (
                    JSON.parse(value).map((v: IObjectWithId) => v.Name?.[language] ?? v.Id)
                ) : (
                    <StringField
                        value={value ? JSON.parse(value) : value}
                        onChange={(newValue) =>
                            handleChange(newValue ? JSON.stringify(newValue) : newValue)
                        }
                        style={{ maxWidth: '250px' }}
                    />
                );

                return displayValue;
            }

            return (
                <StringField
                    value={value ? JSON.parse(value) : value}
                    onChange={(newValue) =>
                        handleChange(newValue ? JSON.stringify(newValue) : newValue)
                    }
                    style={{ maxWidth: '250px' }}
                />
            );
        }

        if (type.includes('multilang_text')) {
            const fieldName =
                field.Name[i18n.language || 'en'] || i18n.t((field.FieldName || '')?.toLowerCase());

            return (
                <SmartMultilanguageField
                    fieldName={fieldName}
                    value={value}
                    onChange={handleChange}
                />
            );
        }

        if (type.includes('key')) {
            if (isEditable && (isValueNulled || !isValueObject)) {
                return (
                    <StringField
                        value={value}
                        onChange={(newValue) => handleChange(newValue || null)}
                    />
                );
            }

            if (isValueNulled || isValueObject) {
                if (options && options.ref) {
                    return (
                        <SmartSelectField
                            value={value}
                            onChange={handleChange}
                            meta={options.ref.meta}
                            filters={options.filters}
                            navigateMetaOptions={{ metaName: options.ref.meta }}
                            treeOptions={{
                                groupKeys: options?.group ? [options.group] : undefined,
                                parentField: 'Parent'
                            }}
                        />
                    );
                }

                return !isValueNulled ? (
                    value.Key
                ) : (
                    <StringField
                        value={value}
                        onChange={(newValue) => handleChange(newValue || null)}
                        style={{ maxWidth: '250px' }}
                    />
                );
            }

            return (
                <StringField
                    value={value}
                    onChange={(newValue) => handleChange(newValue || null)}
                    style={{ maxWidth: '250px' }}
                />
            );
        }

        if (type.includes('[]code')) {
            if (isValueNulled || isValueObject || isObject(JSON.parse(value))) {
                if (options && options.ref) {
                    const v = value && !isValueObject ? JSON.parse(value) : value;

                    return (
                        <SmartMultiSelectField
                            metaName={options.ref.meta}
                            value={isArray(v) || !v ? v : [v]}
                            onChange={handleChange}
                            filters={options.filters}
                            treeOptions={{
                                groupKeys: options?.group ? [options.group] : undefined,
                                parentField: 'Parent'
                            }}
                        />
                    );
                }

                const displayValue = !isValueNulled ? (
                    JSON.parse(value).map(
                        (v: { Name: { [x: string]: any; en: any }; Code: any }) =>
                            v.Name?.[language] ?? v.Code
                    )
                ) : (
                    <StringField
                        value={value ? JSON.parse(value) : value}
                        onChange={(newValue) =>
                            handleChange(newValue ? JSON.stringify(newValue) : newValue)
                        }
                        style={{ maxWidth: '250px' }}
                    />
                );

                return displayValue;
            }

            return (
                <StringField
                    value={value ? JSON.parse(value) : value}
                    onChange={(newValue) =>
                        handleChange(newValue ? JSON.stringify(newValue) : newValue)
                    }
                    style={{ maxWidth: '250px' }}
                />
            );
        }

        if (isId || isCode) {
            // console.log(value, field, options?.filters, dataSource?.Meta);

            return (
                <RefField
                    // value={options?.value ? JSONSafeParse(options?.value) : value}
                    value={value}
                    optionsValue={JSONSafeParse(options?.value || '') as IObjectWithId | string}
                    onChange={handleChange}
                    meta={options?.ref?.meta || ''}
                    filters={options?.filters}
                    groupFieldName={options?.group}
                />
            );
        }

        if (type.includes('[]bool') && field.FieldName === 'DaysPattern' && !isForTable) {
            let selectAfter;
            if (Array.isArray(metaFieldData)) {
                const intervalField = metaFieldData.find(
                    (metaField) => metaField.FieldName === 'Interval'
                );

                if (intervalField) {
                    const intervalValue = dataSource?.[intervalField?.FieldName];
                    const { options } = parseValueType(intervalField.ValueType || '', language, {
                        root: rootDataSource || dataSource,
                        current: dataSource,
                        self: intervalValue,
                        info: rootInfo
                    });

                    if (options && options.ref) {
                        selectAfter = (
                            <Flex align="center" gap={10}>
                                <SmartSelectField
                                    className="interval__select_addon"
                                    value={intervalValue}
                                    meta={options?.ref?.meta}
                                    onChange={(newValue) => {
                                        onChange(intervalField?.FieldName, newValue);
                                    }}
                                    style={{ width: 260 }}
                                    filters={options.filters}
                                    simpleMode
                                    treeOptions={{
                                        groupKeys: options?.group ? [options.group] : undefined,
                                        parentField: 'Parent'
                                    }}
                                />
                            </Flex>
                        );
                    }
                }
            }

            return (
                <Flex vertical>
                    {selectAfter}
                    <DaysPatternField
                        dataSource={dataSource}
                        onChange={handleChange}
                        mode={'edit'}
                        value={value}
                        centered={false}
                    />
                </Flex>
            );
        }

        if (type.includes('[]bool')) {
            if (value && Array.isArray(value)) {
                return (
                    <Flex gap={5}>
                        {value.map((v, index) => (
                            <Checkbox
                                key={index}
                                defaultChecked={!!(options && options.default === 'true')}
                                checked={v}
                                onChange={(e) =>
                                    handleChange([
                                        ...value.splice(0, index),
                                        e.target.checked,
                                        ...value.splice(index + 1)
                                    ])
                                }
                            ></Checkbox>
                        ))}
                    </Flex>
                );
            }

            return (
                <Checkbox
                    defaultChecked={!!(options && options.default === 'true')}
                    checked={value}
                    onChange={(e) => handleChange(e.target.checked)}
                ></Checkbox>
            );
        }

        if (type.includes('bool')) {
            return (
                <Checkbox
                    defaultChecked={!!(options && options.default === 'true')}
                    checked={value}
                    onChange={(e) => handleChange(e.target.checked)}
                ></Checkbox>
            );
        }

        if (type.includes('int')) {
            return (
                <NumberField
                    placeholder={t('no_value') as string}
                    style={{ width: '100%', maxWidth: numberFieldWidth }}
                    defaultValue={options ? Number(options.default) : undefined}
                    value={value}
                    onChange={handleChange}
                />
            );
        }

        // if (type.includes('money')) {
        //     return (
        //         <MoneyField
        //             placeholder={t('no_value') as string}
        //             style={{ width: '100%', maxWidth: numberFieldWidth }}
        //             defaultValue={options ? Number(options.default) : undefined}
        //             value={value}
        //             onChange={handleChange}
        //         />
        //     );
        // }

        if (type.includes('number') || type.includes('numeric') || type.includes('float')) {
            return (
                <NumberField
                    placeholder={t('no_value') as string}
                    style={{ width: '100%', maxWidth: numberFieldWidth }}
                    defaultValue={options ? Number(options.default) : undefined}
                    value={value}
                    step="0.05"
                    onChange={handleChange}
                />
            );
        }

        if (type.includes('decimal') || type.includes('money')) {
            let selectAfter;
            if (Array.isArray(metaFieldData)) {
                const measureUnitField = metaFieldData.find(
                    (metaField) =>
                        // composite measure value
                        metaField.FieldName ===
                            `${field?.FieldName.split('Value').join('')}MeasureUnit` ||
                        // composite curreny value
                        metaField.FieldName ===
                            `${field?.FieldName.split('CurrencyValue').join('')}Currency` ||
                        metaField.FieldName ===
                            `${field?.FieldName.split('Amount').join('')}Currency` ||
                        metaField.FieldName ===
                            `${field?.FieldName.split('Value').join('')}Currency`
                );

                // console.log(field, measureUnitField);

                if (measureUnitField) {
                    const measureUnitValue = dataSource?.[measureUnitField?.FieldName];
                    const { options } = parseValueType(measureUnitField.ValueType || '', language, {
                        root: rootDataSource || dataSource,
                        current: dataSource,
                        self: measureUnitValue,
                        info: rootInfo
                    });

                    if (options && options.ref) {
                        const objectPath = options.filters
                            ?.split('{{')[1]
                            ?.replace('Root.', '')
                            ?.replace('Context.', '')
                            ?.replace('Info.', '')
                            ?.replace('}}', '');

                        let nestedValue;

                        if (objectPath) {
                            nestedValue = getNestedValueFromObject(
                                dataSource,
                                objectPath,
                                language
                            );
                        }

                        if (
                            dataSource &&
                            objectPath &&
                            cachedPrevMeasureUnitNestedValue?.[dataSource.Id] !== nestedValue
                        ) {
                            cachedPrevMeasureUnitNestedValue[dataSource.Id] = nestedValue;
                            onChange(measureUnitField?.FieldName, undefined);
                        }
                        // const filters =
                        //     // options.filters?.includes('{{') && options.filters?.includes('Root')
                        //     objectPath
                        //         ? `${options.filters?.split('{{')[0]}${nestedValue}`
                        //         : options.filters;

                        selectAfter = (
                            <SmartSelectField
                                className="measure_value__select_addon"
                                value={measureUnitValue}
                                meta={options?.ref?.meta}
                                onChange={(newValue) => {
                                    onChange(measureUnitField?.FieldName, newValue);
                                }}
                                style={{ width: 75 }}
                                // filters={filters}
                                filters={options.filters}
                                simpleMode
                                treeOptions={{
                                    groupKeys: options?.group ? [options.group] : undefined,
                                    parentField: 'Parent'
                                }}
                            />
                        );
                    }
                }
            }

            if (type.includes('money')) {
                // TODO: вынести в компонент
                return (
                    <MoneyField
                        placeholder={t('no_value') as string}
                        style={{ width: '100%', maxWidth: numberFieldWidth }}
                        // defaultValue={options ? options.default === 'true' : undefined}
                        value={value}
                        step="1"
                        onChange={handleChange}
                        addonAfter={selectAfter}
                    />
                );
            }
            // TODO: вынести в компонент
            return (
                <NumberField
                    placeholder={t('no_value') as string}
                    style={{ width: '100%', maxWidth: numberFieldWidth }}
                    // defaultValue={options ? options.default === 'true' : undefined}
                    value={value}
                    step="1"
                    onChange={handleChange}
                    addonAfter={selectAfter}
                />
            );
        }

        if (field.FieldName.includes('Value') && type.includes('json')) {
            // console.log(field.FieldName, value, field.ValueType);
            if (Array.isArray(metaFieldData)) {
                const valueTypeField = metaFieldData.find(
                    (metaField) =>
                        metaField.FieldName.includes('ValueType') ||
                        metaField.FieldName === 'Parameter' ||
                        metaField.FieldName === 'Property'
                );

                return (
                    <ValueTypeField
                        value={value}
                        typeValue={
                            valueTypeField?.FieldName
                                ? dataSource?.[valueTypeField?.FieldName]
                                : undefined
                        }
                        onChange={(value) => {
                            handleChange(value);
                        }}
                        onTypeChange={(newValue) => {
                            if (valueTypeField?.FieldName)
                                onChange(valueTypeField?.FieldName, newValue);
                        }}
                        valueField={field}
                        valueTypeField={valueTypeField}
                        rootMeta={rootMeta}
                    />
                );
            }
        }

        if (isScript) {
            const data = isDefined(value) ? String(value) : value;

            if (isForTable) {
                return <StringField value={data} onChange={handleChange} />;
            }

            return (
                <JsonField
                    jsonValue={data}
                    displayMode={options?.display}
                    onChange={handleChange}
                    scriptLanguage={options?.language || 'text'}
                    hideEditor={options?.hide_code ?? field.Options?.HideSourceCode}
                    readOnly={!!options?.hide_code}
                />
            );
        }

        if (type.includes('json')) {
            const data = !isValueNulled && isValueObject ? JSON.stringify(value) : value;

            if (options?.json_type) {
                return fieldEditRender({
                    data,
                    onChange,
                    language,
                    metaFieldData: {
                        ...field,
                        ValueType: `${options.json_type};ref:${options.ref?.meta || ''}.${
                            options.ref?.fieldName || ''
                        }${options.filters ? `;filters:${options.filters}` : ''}${
                            options.group ? `;group:${options.group}` : ''
                        }${options.display ? `;display:${options.display}` : ''}`
                    },
                    fieldName,
                    isEditable,
                    dataSource,
                    isForTable,
                    rootMeta
                });
            }

            if (isForTable) {
                return <StringField value={data} onChange={handleChange} />;
            }
            return (
                <JsonField
                    jsonValue={typeof data === 'string' ? JSONSafeParse(data) : data}
                    readOnly={false}
                    onChange={handleChange}
                    displayMode={options?.display}
                    scriptLanguage="json"
                />
            );
        }

        if (isText || isTableName) {
            return <StringField value={value} onChange={handleChange} />;
        }

        if (type.includes('local_datetime_range')) {
            return (
                <SmartDatetimeRangeField
                    style={{ maxWidth: dateRangeFieldWidth }}
                    value={value}
                    onChange={handleChange}
                    showTime={true}
                    utc={false}
                />
            );
        }

        if (type.includes('datetime_range') || type.includes('datetimerange')) {
            return (
                <SmartDatetimeRangeField
                    style={{ maxWidth: dateRangeFieldWidth }}
                    value={value}
                    onChange={handleChange}
                    showTime={true}
                    utc={true}
                />
            );
        }

        if (
            type.includes('dates_range') ||
            type.includes('daterange') ||
            type.includes('datesrange')
        ) {
            return (
                <SmartDateRangeField
                    style={{ maxWidth: dateRangeFieldWidth }}
                    value={value}
                    onChange={handleChange}
                />
            );
        }

        if (type.includes('datetime')) {
            return (
                <SmartDateField
                    style={{ width: dateFieldWidth }}
                    value={value}
                    onChange={handleChange}
                    showTime
                />
            );
        }

        if (type.includes('[]time_range')) {
            if (value && Array.isArray(value)) {
                return value.map((v, index) => (
                    <SmartTimeRangeField
                        style={{ width: dateFieldWidth }}
                        value={v}
                        onChange={(newValue) =>
                            handleChange([
                                ...value.slice(0, index),
                                newValue,
                                ...value.slice(index + 1)
                            ])
                        }
                    />
                ));
            }
            return '';
        }

        if (type.includes('time_range')) {
            return (
                <SmartTimeRangeField
                    style={{ width: dateFieldWidth }}
                    value={value}
                    onChange={(value) => {
                        handleChange(value);
                    }}
                />
            );
        }

        if (type.includes('time')) {
            return (
                <SmartTimeField
                    style={{ width: dateFieldWidth }}
                    value={value}
                    onChange={handleChange}
                />
            );
        }

        if (type.includes('duration') || type.includes('seconds')) {
            return <SmartDurationField value={value} onChange={handleChange} />;
        }

        if (type.includes('date')) {
            return (
                <SmartDateField
                    style={{ width: dateFieldWidth }}
                    value={value}
                    onChange={handleChange}
                />
            );
        }

        if (type.includes('color')) {
            return <ColorField value={value} onChange={handleChange} />;
        }

        if (type.includes('coordinate')) {
            return (
                <SmartCoordinateField
                    value={value}
                    onChange={handleChange}
                    style={{ maxWidth: numberFieldWidth * 2 }}
                />
            );
        }

        if (isLogs) {
            return (
                <LogsField
                    requestId={dataSource?.Id || ''}
                    btnSize={isForTable ? 'small' : 'middle'}
                    hardDuration={dataSource?.DurationSec}
                />
            );
        }

        if (!isValueNulled && isValueObject) return '';

        if (options && options?.default) {
            return value || options.default;
        }

        return <StringField value={value} onChange={handleChange} />;
    }

    return value;
};
