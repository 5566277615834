import { Typography } from 'antd';

import { ButtonWithTooltips } from 'ui';
import { LazyIcon } from '../../../../../../../smart/ui';

const { Text } = Typography;

interface LeftMenuRouteLabelProps {
    isOpen: boolean;
    text: string;
    onClick: () => void;
    buttonId: string;
}

export const LeftMenuRouteLabel: React.FC<LeftMenuRouteLabelProps> = ({
    text,
    isOpen,
    onClick,
    buttonId
}) => {
    return (
        <Text>
            <Text className="left-menu-text">{text}</Text>
            {isOpen ? (
                <Text className="left-menu-text icon-span-menu-open_pazzle">
                    <ButtonWithTooltips
                        onClick={onClick}
                        id={buttonId}
                        className="overview"
                        type="text"
                        icon={<LazyIcon icon={'mdiApps'} />}
                    />
                </Text>
            ) : null}
        </Text>
    );
};
