import React, { memo, useEffect, useState } from 'react';
import { Button, Space } from 'antd';
import { useAsync, useMedia } from 'react-use';
import { toJS } from 'mobx';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useNotifications } from '../../../utils/hooks';
import { IObjectWithId, metaStore } from '../../../utils/store/MetaStore';
import { SmartTable } from '../SmartTable/SmartTable';

interface ArraySortingFieldProps {
    meta: string;
    filters?: string;
    value: IObjectWithId[];
    onChange: (newValue: IObjectWithId[]) => void;
}

export const ArraySortingField = memo<ArraySortingFieldProps>(
    ({ meta, filters, value, onChange }) => {
        const [selectedRows, setSelectedRows] = useState<IObjectWithId[]>([]);
        const isBigMobile = useMedia('(max-width: 480px)');

        const { message } = useNotifications();

        // инициируем мету
        const metaInfo = useAsync(async () => toJS(await metaStore.getInfo(meta)), [meta]);

        const stops = useAsync(
            async () => toJS(await metaStore.makeSelect({ meta, filters }))?.objects,
            [meta, filters]
        );

        useEffect(() => {
            onChange(stops.value ?? []);
        }, [stops.value]);

        // Функция для перемещения строк вверх
        const moveUp = () => {
            if (selectedRows.length === 0) {
                message.warning('Выберите строки для перемещения');
                return;
            }

            const newValue = [...value];
            selectedRows.forEach(({ Id }) => {
                const index = newValue.findIndex((item) => item.Id === Id);
                if (index > 0 && !selectedRows.includes(newValue[index - 1].id)) {
                    [newValue[index], newValue[index - 1]] = [newValue[index - 1], newValue[index]];
                }
            });

            onChange(newValue);
        };

        // Функция для перемещения строк вниз
        const moveDown = () => {
            if (selectedRows.length === 0) {
                message.warning('Выберите строки для перемещения');
                return;
            }

            const newValue = [...value];
            for (let i = selectedRows.length - 1; i >= 0; i--) {
                const Id = selectedRows[i].Id;
                const index = newValue.findIndex((item) => item.Id === Id);
                if (index < newValue.length - 1 && !selectedRows.includes(newValue[index + 1].id)) {
                    [newValue[index], newValue[index + 1]] = [newValue[index + 1], newValue[index]];
                }
            }

            onChange(newValue);
        };

        return (
            <SmartTable
                loading={metaInfo.loading}
                fields={metaInfo.value?.Fields}
                meta={meta}
                data={value}
                selectedRows={selectedRows}
                onRowSelectionChange={setSelectedRows}
                clickable={false}
                doubleClickable={false}
                editable={false}
                titleRender={() => (
                    <Space.Compact>
                        <Button
                            size={isBigMobile ? 'middle' : 'small'}
                            type={'text'}
                            onClick={moveUp}
                            disabled={selectedRows.length === 0}
                        >
                            <CaretUpOutlined />
                        </Button>
                        <Button
                            size={isBigMobile ? 'middle' : 'small'}
                            type={'text'}
                            onClick={moveDown}
                            disabled={selectedRows.length === 0}
                        >
                            <CaretDownOutlined />
                        </Button>
                    </Space.Compact>
                )}
            />
        );
    }
);
