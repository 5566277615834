import { useBoolean } from 'react-use';
import { Flex, Tooltip } from 'antd';

import { PageTitleText } from './ui';

import { PageHeaderProps } from './types';
import './PageHeader.scss';

export const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    pageWidth,
    disabled,
    center,
    right,
    extraContent,
    onVertical = 900,
    state
}) => {
    if (disabled) return <></>;
    const [open, setOpen] = useBoolean(false);

    return (
        <div className="page-header">
            <Flex
                vertical={Boolean(pageWidth && pageWidth < onVertical)}
                align="center"
                justify="space-between"
            >
                <div
                    className="page-title"
                    onMouseEnter={() => setOpen(true)}
                    onMouseLeave={() => setOpen(false)}
                >
                    <Tooltip
                        placement={'right'}
                        open={state?.associationCode ? open : false}
                        title={
                            state?.associationCode ? (
                                <strong>{state?.associationCode}</strong>
                            ) : undefined
                        }
                    >
                        <PageTitleText title={title} />
                    </Tooltip>
                </div>

                {center}
                {right}
            </Flex>
            {extraContent}
        </div>
    );
};
