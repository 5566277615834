import { Filter } from 'modules/supabase/utils/supabaseClient';

import { ConvertFilterFunctionType, FilterOption } from '../../../types';
import { mapMUITableFilterOperationAndSupabaseFilterOperation } from './mapMUITableFilterOperationAndSupabaseFilterOperation';

export const convertInListToFilter = (rawFilterSetting: ConvertFilterFunctionType): Filter => {
    const field = rawFilterSetting.complexFilter.field;
    const operator = rawFilterSetting?.complexFilterValue?.operator as FilterOption;
    const value = rawFilterSetting?.complexFilterValue?.value;

    const valueType = field.ValueType ?? '';
    const [type] = valueType.split(';');

    const isCode = type?.includes('code');
    const isId = type?.includes('id');
    const isKey = type?.includes('key');

    const inOperator = mapMUITableFilterOperationAndSupabaseFilterOperation(operator);

    // if (!value) {
    //     inOperator = 'is';
    // }

    const valueArray = Array.isArray(value)
        ? value.map((val) => {
              if (isCode) {
                  return val.Code;
              }

              if (isId) {
                  return val.Id;
              }

              if (isKey) {
                  return val.Key;
              }

              return val.Id;
          })
        : [];

    return {
        column: field.FieldName,
        operator: inOperator,
        value: `[${valueArray.join(',')}]`,
        field
    };
};
