import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import { MetaField } from 'modules/services/backend-api/generated_info';

import { FilterField, FilterFieldValue } from '../types';
import { getDefaultFilterOperator } from './getDefaultFilterOperator';

const removeDuplicates = memoizeOne((array: { field: MetaField; values: FilterFieldValue[] }[]) => {
    const map = new Map<string, { field: MetaField; values: FilterFieldValue[] }>();

    array.forEach((item) => {
        // console.log(item.field.FieldName, item);
        map.set(item.field.FieldName as string, item); // Если Id одинаковый, элемент заменится
    });

    return Array.from(map.values());
}, isEqual);

export const getActiveFiltersFromFields = memoizeOne((fields: MetaField[]) => {
    // console.log(fields);
    return fields
        .filter((field) => field.FilterIndex && field.FilterIndex > 0)
        .map((field) => {
            const filterField: FilterField = {
                field,
                values: []
            };

            filterField.values.push({
                operator: getDefaultFilterOperator(filterField),
                column: filterField.field.ColumnName
            });

            return filterField;
        });
}, isEqual);

export const getInitialActiveFilters = (fields: MetaField[], filtersFromLayout: FilterField[]) => {
    // console.log(
    //     filtersFromLayout,
    //     ...getActiveFiltersFromFields(fields),
    //     removeDuplicates([...getActiveFiltersFromFields(fields), ...filtersFromLayout])
    // );


    return removeDuplicates([...getActiveFiltersFromFields(fields), ...(filtersFromLayout || [])]);
};
