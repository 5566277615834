import { createApiClient } from './createClient';
import { ApiClient, ApiClientConfig } from './types';

export const CLIENT_KEY_LOCAL_STORAGE = 'itvectura-client';
export const saveClientToLocalStorage = (client: ApiClient): void => {
    const clientData = {
        authProvider: client.authProvider,
        session: client.session
    };
    window.localStorage.setItem(CLIENT_KEY_LOCAL_STORAGE, JSON.stringify(clientData));
};

export const loadClientFromLocalStorage = (): ApiClient | null => {
    const clientDataString = window.localStorage.getItem(CLIENT_KEY_LOCAL_STORAGE);
    if (!clientDataString) return initAnonimSupabaseClient();

    const clientData: ApiClientConfig = JSON.parse(clientDataString);
    return createApiClient(clientData);
};

export const initAnonimSupabaseClient = (): ApiClient => {
    const supabaseClient = createApiClient({ authProvider: 'supabase' });

    saveClientToLocalStorage(supabaseClient);
    return supabaseClient;
};
