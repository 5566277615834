import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { To } from '@remix-run/router';
import { toJS } from 'mobx';
import { routeStore, RouteStoreItemType } from '../../index';

export interface NavigateFunction {
    (to: To, options?: { state: any }, currentLocInteractions?: { replace?: boolean }): void;
    (delta: number): void;
}

export const useStoreNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const storeNavigate: NavigateFunction = useCallback(
        (...args) => {
            const currentRoute = {
                pathname: location.pathname,
                search: location.search,
                state: location.state
            };

            const currentRouteIndex = routeStore.findIndex(currentRoute);
            const idx = currentRouteIndex === -1 ? 0 : currentRouteIndex;
            const openedRouteIndex = idx + (typeof args[0] === 'number' ? args[0] : 1);

            let openedRoute: RouteStoreItemType = { pathname: '' };

            // console.log('store navigate', args, location);
            if (typeof args[0] === 'number') {
                openedRoute = toJS(routeStore.routes[openedRouteIndex]);
            } else if (typeof args[0] === 'string' && typeof args[1] === 'object') {
                const [pathname, search] = args[0].split('?');

                openedRoute = {
                    pathname,
                    search: search ? `?${search}` : '',
                    ...args[1]
                };
            } else if (typeof args[0] === 'string') {
                const [pathname, search] = args[0].split('?');

                openedRoute = {
                    pathname,
                    search: search ? `?${search}` : '',
                    state: null
                };
            } else if (typeof args[0] === 'object' && typeof args[1] === 'object') {
                const pathname = args[0].pathname?.split('?')[0] ?? location.pathname;
                const search = args[0].search || '';

                openedRoute = {
                    pathname,
                    search,
                    ...args[1]
                };
            } else if (typeof args[0] === 'object') {
                const pathname = args[0].pathname?.split('?')[0] ?? location.pathname;
                const search = args[0].search || '';

                openedRoute = {
                    pathname,
                    search,
                    state: null
                };
            }

            if (args[2]?.replace === true) {
                routeStore.remove(currentRoute);
                routeStore.push(openedRoute);
            } else {
                routeStore.insertAt(openedRouteIndex, openedRoute);
            }

            if (typeof args[0] === 'number') {
                navigate(
                    { pathname: openedRoute.pathname, search: openedRoute.search },
                    { state: openedRoute.state }
                );
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                navigate(...args);
            }
        },
        [navigate]
    );

    return storeNavigate;
};
