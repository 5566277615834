import { Button, Menu, MenuProps, Space } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'antd-mobile';
import { useBoolean } from 'react-use';
import { EllipsisOutlined } from '@ant-design/icons';
import { IObjectWithId } from 'utils/store/MetaStore';
import { SmartTooltip } from 'smart/ui';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

interface MobileCardToolbarProps {
    actionsMenuItems: MenuItem[];
    reportsMenuItems: MenuItem[];
    followsMenuItems: MenuItem[];

    selectedRows?: IObjectWithId[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<IObjectWithId[]>>;

    actionButtonsColor?: React.CSSProperties['backgroundColor'];
    actionButtonsTextColor?: React.CSSProperties['color'];
}

export const MobileCardToolbar = memo<MobileCardToolbarProps>(
    ({
        actionsMenuItems,
        followsMenuItems,
        reportsMenuItems,
        selectedRows,
        setSelectedRows,
        actionButtonsColor,
        actionButtonsTextColor
    }) => {
        const { t } = useTranslation();

        const allMenuItems = useMemo(
            () => [...actionsMenuItems, ...followsMenuItems, ...reportsMenuItems],
            [actionsMenuItems, followsMenuItems, reportsMenuItems]
        );

        const [actionsPopup, setActionsPopup] = useBoolean(false);

        const actions = useMemo(() => {
            const button = [];
            const noButton = [];

            let hasButton = false;

            for (const item of allMenuItems) {
                if (item.isButton && !hasButton) {
                    hasButton = true;
                    button.push(item);
                } else noButton.push(item);
            }

            return { button, noButton };
        }, [allMenuItems]);

        return (
            <>
                <Space.Compact block>
                    {actions.button.map((action) => (
                        <Button
                            key={action.key}
                            block
                            style={{
                                maxWidth: actions.noButton.length ? '85%' : '100%',
                                backgroundColor: actionButtonsColor
                            }}
                            // icon={action.icon}
                            onClick={() => {
                                if (setSelectedRows && selectedRows) setSelectedRows(selectedRows);
                                action.onClick();
                            }}
                            children={
                                <SmartTooltip style={{ color: actionButtonsTextColor }}>
                                    {action.label}
                                </SmartTooltip>
                            }
                        />
                    ))}
                    {!!actions.noButton.length && (
                        <Button
                            block={!actions.button.length}
                            style={{ width: actions.button.length ? '15%' : undefined }}
                            icon={<EllipsisOutlined />}
                            onClick={() => {
                                if (setSelectedRows && selectedRows) setSelectedRows(selectedRows);
                                setActionsPopup(true);
                            }}
                        />
                    )}
                </Space.Compact>

                <Popup
                    visible={actionsPopup}
                    onMaskClick={() => {
                        if (setSelectedRows && selectedRows) setSelectedRows(selectedRows);
                        setActionsPopup(false);
                    }}
                    onClose={() => {
                        if (setSelectedRows && selectedRows) setSelectedRows(selectedRows);
                        setActionsPopup(false);
                    }}
                    bodyStyle={{ height: 'max-content' }}
                >
                    <Menu
                        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }}
                        mode="inline"
                        onClick={() => {
                            if (setSelectedRows && selectedRows) setSelectedRows(selectedRows);
                            setActionsPopup(false);
                        }}
                        items={
                            actions.noButton.length > 6
                                ? [
                                      actionsMenuItems.length
                                          ? {
                                                key: 'actions',
                                                label: t('actions'),
                                                children: actionsMenuItems
                                            }
                                          : null,
                                      reportsMenuItems.length
                                          ? {
                                                key: 'reports',
                                                label: t('reports'),
                                                children: reportsMenuItems
                                            }
                                          : null,
                                      followsMenuItems.length
                                          ? {
                                                key: 'follows',
                                                label: t('follows'),
                                                children: followsMenuItems
                                            }
                                          : null
                                  ]
                                : actions.noButton
                        }
                    />
                </Popup>
            </>
        );
    }
);
