import { memo, useState } from 'react';
import { Input, InputNumber, InputNumberProps, InputProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

interface MoneyFieldProps extends Omit<InputProps, 'onChange'> {
    onChange?: (value: string | number | null | undefined) => void;
}

// Function to format the value with thousands separators and a comma as decimal separator
export const formatNumberWithSpaces = (value: number | null): string => {
    if (value === null) return '';

    // Convert the number to a string and split into integer and decimal parts
    const [integerPart, decimalPart = ''] = value.toString().split('.');

    // Add spaces as thousands separators manually for the integer part
    const formattedInteger = integerPart
        .split('')
        .reverse()
        .reduce((acc, num, index) => num + (index % 3 === 0 && index !== 0 ? ' ' : '') + acc, '');

    // Take only the first two digits of the decimal part without rounding
    const truncatedDecimal = decimalPart.substring(0, 2);

    // If there is a decimal part, return integer part + truncated decimal with comma separator
    return truncatedDecimal ? `${formattedInteger},${truncatedDecimal}` : formattedInteger;
};

const parseNumber = (value: string | undefined): string => {
    if (!value) return '';

    // Remove spaces and convert comma to dot
    const parsedValue = value.split(' ').join('').replace(',', '.');
    return parsedValue;
};

export const MoneyField = memo<MoneyFieldProps>(({ value, onChange, ...props }) => {
    const { t } = useTranslation();

    const [innerValue, setInnerValue] = useState(value);

    useDebounce(
        () => {
            if (onChange) onChange(innerValue);
        },
        400,
        [innerValue]
    );

    const [displayValue, setDisplayValue] = useState<string>(
        value ? formatNumberWithSpaces(value as number) : ''
    ); // Formatted display value

    // Function to handle user input and sync raw number state
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let rawValue = e.target.value;

        // Allow the user to input a comma or decimal point for the decimal separator
        // Replace spaces and ensure comma is used for decimals in display value
        rawValue = rawValue.replace(/\s/g, '').replace(/(\,|\.)/g, ',');

        // Validate the numeric input and limit the decimal part to 2 digits
        const match = rawValue.match(/^(\d{0,})(,(\d{0,2})?)?$/);

        if (match) {
            const parsedValue = parseFloat(rawValue.replace(',', '.'));
            setInnerValue(!isNaN(parsedValue) ? parsedValue : undefined);
            setDisplayValue(rawValue);
        } else {
            // Reject invalid inputs while preserving user intent
            setDisplayValue(rawValue.substring(0, displayValue.length));
        }

        // const rawValue = e.target.value;

        // // Replace spaces and comma to convert back to a proper number
        // const parsedValue = parseFloat(rawValue.replace(/\s/g, '').replace(',', '.'));

        // // Update the raw float value only if it's a valid number
        // if (!isNaN(parsedValue)) {
        //     setInnerValue(parsedValue);
        //     setDisplayValue(rawValue); // Keep display value in sync
        // } else {
        //     setDisplayValue(rawValue); // Allow user to type freely (even invalid characters)
        // }
    };

    // Format the value when the input loses focus (on blur)
    const handleBlur = () => {
        setDisplayValue(formatNumberWithSpaces(innerValue as number)); // Apply formatting
    };

    // Handle raw value display when input gets focus
    const handleFocus = () => {
        if (innerValue !== null) {
            setDisplayValue((innerValue as number).toString().replace('.', ',')); // Show plain number on focus
        }
    };

    return (
        <Input
            {...props}
            placeholder={t('no_value') as string}
            onBlur={handleBlur} // Format the value on blur
            onFocus={handleFocus} // Show raw number when focused
            value={displayValue}
            onChange={handleInputChange}
        />
    );
});
