import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Interval {
    StartDateAt: string;
    EndDateAt: string;
    Id: string;
    [key: string]: unknown;
}

interface DailyInterval {
    StartDateAt: string;
    EndDateAt: string;
    Id: string;
    SlotDate: string;
    [key: string]: unknown;
}

function extractTimezone(input: string): string | null {
    // Регулярное выражение для поиска таймзоны в формате +HH:MM или -HH:MM
    const timezoneRegex = /[+-]\d{2}:\d{2}$/;
    const match = input.match(timezoneRegex);

    // Если таймзона найдена, вернуть ее, иначе вернуть null
    return match ? match[0] : null;
}

export function splitIntervalsByDay(intervals: Interval[]): DailyInterval[] {
    const result: DailyInterval[] = [];

    intervals.forEach((interval) => {
        const startDateString = interval.StartDateAt ?? interval.StartAt;
        const startTZ = extractTimezone(startDateString);
        let start = dayjs(startDateString).tz(startTZ || undefined);

        const endDateString = interval.EndDateAt ?? interval.EndAt;
        const endTZ = extractTimezone(endDateString);
        const end = dayjs(endDateString).tz(endTZ || undefined);

        while (start.isBefore(end)) {
            // Конец текущего дня в 00:00 следующего дня
            const currentDayEnd = start.startOf('day').add(1, 'day');

            // Ограничиваем конец подинтервала конечной датой исходного интервала
            const subIntervalEnd = currentDayEnd.isBefore(end) ? currentDayEnd : end;

            result.push({
                ...interval,
                StartDateAt: start.format(), // Сохраняет оригинальный часовой пояс
                EndDateAt: subIntervalEnd.format(),
                Id: uuidv4(),
                SlotDate: start.format('YYYY-MM-DD')
            });

            // Переходим к следующему дню
            start = subIntervalEnd;
        }
    });

    return result;
}

// // Пример использования
// const intervals: Interval[] = [
//     {
//         StartDateAt: '2024-10-15T08:00:00+03:00',
//         EndDateAt: '2024-10-16T20:00:00+03:00',
//         Id: '92b114da-8fa6-11ef-b079-000000cdd23e'
//     },
//     {
//         StartDateAt: '2024-10-16T08:00:00+03:00',
//         EndDateAt: '2024-10-17T20:00:00+03:00',
//         Id: '91b114da-8fa6-11ef-b079-000000cdd23e'
//     },
//     {
//         StartDateAt: '2024-10-15T17:00:00+03:00',
//         EndDateAt: '2024-10-17T15:00:00+03:00',
//         Id: '90b114da-8fa6-11ef-b079-000000cdd23e'
//     },
//     {
//         StartDateAt: '2024-10-17T00:00:00+03:00',
//         EndDateAt: '2024-10-17T14:00:00+03:00',
//         Id: '96b114da-8fa6-11ef-b079-000000cdd23e'
//     },
//     {
//         StartDateAt: '2024-10-14T13:00:00+03:00',
//         EndDateAt: '2024-10-14T22:00:00+03:00',
//         Id: '99b114da-8fa6-11ef-b079-000000cdd23e'
//     }
// ];
//
// const dailyIntervals = splitIntervalsByDay(intervals);
// console.log(dailyIntervals);
