import { PlainObject } from '@gilbarbara/types';
import { Skeleton } from 'antd';
import * as JsSearch from 'js-search';
import {
    lazy,
    memo,
    SetStateAction,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useList } from 'react-use';

import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { SmartTablePageHeaderToolbar } from 'smart/modules/SmartTablePage/components';
import { metaStore } from 'utils/store/MetaStore';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { Response } from 'modules/services/backend-api/generated_api';
import { Filter } from 'modules/supabase/utils/supabaseClient';

const SmartTable = lazy(() =>
    import('../../../SmartTable/SmartTable').then((module) => ({ default: module.SmartTable }))
);

const Modal = lazy(() => import('antd').then((module) => ({ default: module.Modal })));

interface SmartSearchListProps {
    meta: string;
    open: boolean;
    onCancel: () => void;
    onOk: (selectedRows: any[]) => void;
    fields?: MetaField[];
    data?: any[];
    title?: string;
    selectionType?: 'checkbox' | 'radio';
    initialSelectedRows?: any[];
    searchFilter?: string;
}

export const SmartSearchList = memo<SmartSearchListProps>(
    ({
        meta,
        title,
        open,
        onCancel,
        onOk,
        data,
        fields,
        initialSelectedRows,
        selectionType = 'radio',
        searchFilter = ''
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        const [selectedRows, { set: setSelectedRows }] = useList<any>([]);

        useEffect(() => {
            if (initialSelectedRows) setSelectedRows(initialSelectedRows);
        }, [initialSelectedRows, setSelectedRows]);

        const [localSearchFilter, setLocalSearchFilter] = useState('');

        useEffect(() => {
            if (searchFilter) setLocalSearchFilter(searchFilter);
        }, [searchFilter]);

        const titleRender = useCallback(
            () => (
                <SmartTablePageHeaderToolbar
                    meta={meta}
                    searchFilter={localSearchFilter}
                    setSearchFilter={setLocalSearchFilter}
                    filters={[]}
                    showComponents={{
                        isCreate: false,
                        isDelete: false,
                        isDescription: false,
                        isFilter: false,
                        isReload: false,
                        isDisplayViewPreferences: false,
                        isActions: false,
                        isFollows: false,
                        isReports: false,
                        isActionButtons: false
                    }}
                    selectedRowKeys={selectedRows.map((r) => r?.Id)}
                    hasSelected={selectedRows.length > 0}
                    setFilters={(value: SetStateAction<FilterField[]>) => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onRefresh={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onCreate={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onCopy={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    onDelete={() => {
                        console.warn(new Error('Function not implemented.'));
                    }}
                    navigateAfterRun={function (
                        actionResponse: Response,
                        action: Action,
                        filters: FilterField[] | Filter[],
                        metaFields?: MetaField[]
                    ): void {
                        throw new Error('Function not implemented.');
                    }}
                    onClickAction={function (
                        action: Action,
                        hardIds?: string[],
                        hardArgs?: PlainObject
                    ): Promise<Response | undefined> {
                        throw new Error('Function not implemented.');
                    }} // logs={[]}
                    // setLogs={(value: SetStateAction<LogMessage[]>) => {
                    //     console.warn(new Error('Function not implemented.'));
                    // }}
                    // selectedRows={selectedRows}
                />
            ),
            [localSearchFilter, meta, selectedRows]
        );

        // ### НАЙДЕННЫЕ по ПОИСКУ данные из dataSource
        const searchedDataSource = useMemo(() => {
            if (localSearchFilter) {
                const index = new JsSearch.Search('Id');
                index.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

                fields?.forEach((item) => {
                    index.addIndex(item.FieldName);
                    index.addIndex([item.FieldName, 'Name', language]);
                    index.addIndex([item.FieldName, 'PluralName', language]);
                    index.addIndex([item.FieldName, 'SingularName', language]);
                    index.addIndex([item.FieldName, language]);
                });

                index.addDocuments(
                    (data || []).map((d) => ({ ...d, Parent: undefined, children: undefined }))
                );

                return index.search(localSearchFilter);
            }

            return data || [];
        }, [data, fields, language, localSearchFilter]);

        const handleOk = useCallback(() => {
            onOk(selectedRows);
            setSelectedRows([]);
            onCancel();
        }, [onCancel, onOk, selectedRows, setSelectedRows]);

        const handleCancel = useCallback(() => {
            onCancel();
            setSelectedRows([]);
        }, [onCancel, setSelectedRows]);

        const viewFieldName = metaStore.meta.get(meta)?.info?.ViewFieldName;

        return (
            <Modal
                centered
                // destroyOnClose
                width="95%"
                title={t(title || 'search')}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t('ok')}
                cancelText={t('cancel')}
            >
                <Suspense fallback={<Skeleton />}>
                    <SmartTable
                        meta={meta}
                        data={searchedDataSource}
                        titleRender={titleRender}
                        fields={fields}
                        selectionType={selectionType}
                        selectedRows={selectedRows}
                        onRowSelectionChange={setSelectedRows}
                        layout="fixed"
                        virtual
                        leftFixedField={viewFieldName}
                        scroll={{ y: window.innerHeight / 2 }}
                    />
                </Suspense>
            </Modal>
        );
    }
);
