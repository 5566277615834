import { CopyOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse, Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { v4 } from 'uuid';

import { Meta } from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips } from 'ui';

import { metaStore } from 'utils/store/MetaStore';
import { isFunction } from 'is-lite/exports';
import { SmartTable } from '../../../../components';

interface SmartDetailPageChildTabContentProps {
    data: any;
    rootData?: any;
    setData: (newData: any) => void;
    onChange: (key: string, newData: any) => void;
    childNodeMeta: Meta;
    mode: 'view' | 'edit';
    padding?: number | string;
    readOnly?: boolean;
    enableCollapse?: boolean;
    forceEnableToolbar?: boolean;
    forceEnableSelection?: boolean;
    tableTitle?: string;
    onChildDoubleClick?: 'openRowModal' | ((row: any, index?: number) => void);
    rootMeta?: string;
    scroll?: { y: number | string; x?: string | number };
}

const rowsKeyColumnName = 'Id';
const SIZE = 'small';

const parseDefaultSortOrder = (sortOrder: string | undefined) => {
    const defaultSortOrder: {
        descend: string[];
        ascend: string[];
    } = { ascend: [], descend: [] };

    if (!sortOrder) {
        defaultSortOrder.ascend.push('ChildIndex');
        return defaultSortOrder;
    }

    const [column, order] = sortOrder.split(' ');
    const isASC = order === 'ASC';

    if (isASC) {
        defaultSortOrder.ascend.push(column);
    } else {
        defaultSortOrder.descend.push(column);
    }

    return defaultSortOrder;
};

export const SmartDetailPageChildTabContent = observer<SmartDetailPageChildTabContentProps>(
    ({
        data,
        rootData = data,
        childNodeMeta,
        mode,
        setData,
        onChange,
        padding,
        readOnly,
        tableTitle,
        enableCollapse,
        forceEnableToolbar,
        forceEnableSelection,
        onChildDoubleClick = 'openRowModal',
        rootMeta,
        scroll
    }) => {
        const { t } = useTranslation();
        const [selectedData, setSelectedData] = useState<any[]>([]);
        const isBigMobile = useMedia('(max-width: 480px)');

        const isEditable = !readOnly && mode === 'edit';
        const viewFieldName = childNodeMeta.ViewFieldName;

        const isRowsSelected = selectedData.length > 0;

        useEffect(() => {
            (async () => {
                const inlineEditMaxItems = await metaStore.getParam({
                    param_name: 'INLINE_EDIT_MAX_ITEMS'
                });

                console.log('[Aside] INLINE_EDIT_MAX_ITEMS = ', inlineEditMaxItems);
            })();
        }, []);

        const inlineEditMaxItems =
            metaStore.meta.get('all')?.params?.INLINE_EDIT_MAX_ITEMS || false;

        useEffect(() => {
            // сброс выделения при отмене
            setSelectedData([]);
        }, [mode]);

        const getSelectedRowKeys = useCallback(() => {
            return selectedData.map((row) => row[rowsKeyColumnName]) as string[];
        }, [selectedData]);

        const handleChangeChild = useCallback(
            (updater: any[] | ((prevValue: any) => any[])) => {
                const key = childNodeMeta.ArrayNameInRoot;

                if (key) onChange(key, isFunction(updater) ? updater(data[key]) : updater);
            },
            [childNodeMeta.ArrayNameInRoot, data, onChange]
        );

        const dataSource = useMemo(() => {
            return childNodeMeta.ArrayNameInRoot ? data[childNodeMeta.ArrayNameInRoot] || [] : [];
        }, [childNodeMeta.ArrayNameInRoot, data]);

        const handleAddRow = useCallback(() => {
            const key = childNodeMeta.ArrayNameInRoot;

            if (key) {
                const childArrayNewData = data[key] ? [...data[key]] : [];
                const firstSelectedRow = selectedData.at(0);
                const Parent = firstSelectedRow?.Parent;

                childArrayNewData.push({
                    Id: v4(),
                    Parent,
                    ChildIndex: 0
                });

                onChange(key, childArrayNewData);
            }
        }, [childNodeMeta.ArrayNameInRoot, data, onChange, selectedData]);

        const handleCopyRow = useCallback(() => {
            const key = childNodeMeta.ArrayNameInRoot;

            if (key) {
                const childArrayNewData = data[key] ? [...data[key]] : [];
                const firstSelectedRow = selectedData.at(0);
                const Parent = firstSelectedRow?.Parent;

                childArrayNewData.push({
                    ...firstSelectedRow,
                    Id: v4(),
                    Parent,
                    ChildIndex: (firstSelectedRow?.ChildIndex ?? 0) + 1
                });

                onChange(key, childArrayNewData);
            }
        }, [childNodeMeta.ArrayNameInRoot, data, onChange, selectedData]);

        const handleRemoveRow = useCallback(() => {
            const key = childNodeMeta.ArrayNameInRoot;

            if (key) {
                const childArrayNewData = data[key] ? [...data[key]] : [];
                const selectedKeys = getSelectedRowKeys();

                for (let i = 0; i < selectedKeys.length; i++) {
                    const deletedIndex = childArrayNewData.findIndex(
                        (row) => row[rowsKeyColumnName] === selectedKeys[i]
                    );
                    if (deletedIndex >= 0) {
                        childArrayNewData.splice(deletedIndex, 1);
                    }
                }

                onChange(key, childArrayNewData);
            }
        }, [childNodeMeta.ArrayNameInRoot, data, getSelectedRowKeys, onChange]);
        // }, [setData, childNodeMeta.ArrayNameInRoot, getSelectedRowKeys]);

        const titleRender = useCallback(() => {
            return (
                <Flex gap={5}>
                    <ButtonWithTooltips
                        id="add_row"
                        icon={<PlusOutlined />}
                        tooltipTitle={t('add_row')}
                        type="text"
                        onClick={handleAddRow}
                    />
                    <ButtonWithTooltips
                        id="remove_row"
                        icon={<MinusOutlined />}
                        tooltipTitle={t('remove_row')}
                        type="text"
                        onClick={handleRemoveRow}
                        disabled={!isRowsSelected}
                    />
                </Flex>
            );
        }, [handleAddRow, handleRemoveRow, isRowsSelected, t]);

        const defaultSortOrder = parseDefaultSortOrder(childNodeMeta.SortOrder);

        // const rowContextMenuItems: MenuProps['items'] = useMemo(() => {
        //     const upFromDivider: MenuProps['items'] = [];
        //     const downFromDivider: MenuProps['items'] = [];

        //     if (uiAllowCreate) {
        //         upFromDivider.push({
        //             key: 'create',
        //             label: t('create'),
        //             icon: <PlusOutlined />,
        //             onClick: create,
        //             disabled: isMetaReadOnly
        //         });
        //         downFromDivider.push({
        //             key: 'clone',
        //             label: t('clone'),
        //             icon: <FileAddOutlined />,
        //             onClick: copy,
        //             disabled: isMetaReadOnly
        //         });
        //     }

        //     if (uiAllowEdit) {
        //         downFromDivider.push({
        //             key: 'edit',
        //             label: t('edit'),
        //             icon: <EditOutlined />,
        //             onClick: edit,

        //             disabled: !uiAllowEdit || isMetaReadOnly
        //         });
        //     }

        //     if (uiAllowDelete) {
        //         downFromDivider.push({
        //             key: 'delete',
        //             label: t('delete'),
        //             icon: <DeleteOutlined />,
        //             onClick: remove,
        //             disabled: !uiAllowDelete || isMetaReadOnly
        //         });
        //     }

        //     return [
        //         // {
        //         //     key: 'reload',
        //         //     label: t('reload'),
        //         //     icon: <ReloadOutlined />,
        //         //     onClick: () => {
        //         //         setSelectedRows(() => []);
        //         //         refresh();
        //         //     }
        //         // },
        //         // {
        //         //     key: 'copy',
        //         //     label: t('copy'),
        //         //     icon: <CopyOutlined />,
        //         //     onClick: (textToCopy: string) => () => {
        //         //         navigator.clipboard.writeText(textToCopy);
        //         //     }
        //         //     // disabled: isMetaReadOnly // Саша написал что копирование не зависит от readonly
        //         // },
        //         // TODO: поправить работу с полем поиска
        //         // {
        //         //     key: 'find_by_text',
        //         //     label: t('find_by_text'),
        //         //     icon: <SearchOutlined />,
        //         //     onClick: (textToFind: string) => () => {
        //         //         setLocalSearchFilter(textToFind);
        //         //     }
        //         // },
        //         ...upFromDivider,
        //         { type: 'divider' },
        //         ...downFromDivider
        //         // ...contextMenuActions
        //     ];
        // }, [copy, create, edit, isMetaReadOnly, remove, t, uiAllowCreate, uiAllowDelete, uiAllowEdit]);

        // console.log('childNodeMeta.Code: ', childNodeMeta.Code);
        // console.log('defaultSortOrder: ', defaultSortOrder);

        const collapseItems = [
            {
                key: tableTitle,
                label: <strong>{tableTitle}</strong>,
                children: (
                    <SmartTable
                        scroll={scroll}
                        rowContextMenuItems={
                            isEditable
                                ? [
                                      {
                                          key: 'add_row',
                                          label: t('add_row'),
                                          icon: <PlusOutlined />,
                                          onClick: handleAddRow
                                          // disabled: isMetaReadOnly
                                      },
                                      { type: 'divider' },
                                      {
                                          key: 'remove_row',
                                          label: t('remove_row'),
                                          icon: <MinusOutlined />,
                                          onClick: handleRemoveRow
                                          // disabled: isMetaReadOnly
                                      },
                                      {
                                          key: 'copy',
                                          label: t('copy'),
                                          icon: <CopyOutlined />,
                                          onClick: handleCopyRow
                                          // disabled: isMetaReadOnly
                                      }
                                  ]
                                : undefined
                        }
                        virtual={dataSource?.length > 30}
                        meta={childNodeMeta.Code}
                        rootMeta={rootMeta || childNodeMeta.RootMeta_Code}
                        rootData={rootData}
                        data={dataSource}
                        fields={childNodeMeta.Fields}
                        editable={isEditable}
                        viewMode={
                            isEditable &&
                            childNodeMeta.Fields?.filter((f) => !f.IsHiddenOnTable)?.length <=
                                inlineEditMaxItems &&
                            dataSource.length <= inlineEditMaxItems
                                ? 'inline'
                                : !isEditable &&
                                  childNodeMeta.Fields?.filter((f) => !f.IsHiddenOnTable)?.length <=
                                      inlineEditMaxItems
                                ? 'inline'
                                : 'modal'
                        }
                        selectable={forceEnableSelection || isEditable}
                        // clickable={false}
                        layout="fixed"
                        setData={handleChangeChild}
                        titleRender={forceEnableToolbar || isEditable ? titleRender : undefined}
                        selectedRows={selectedData}
                        onRowSelectionChange={setSelectedData}
                        doubleClickable={!!onChildDoubleClick}
                        onDoubleClick={onChildDoubleClick}
                        defaultSortOrder={defaultSortOrder}
                        leftFixedField={viewFieldName}
                    />
                )
            }
        ];

        return (
            <Flex
                vertical
                className="smart_detail_page__child_content"
                style={{ padding, width: '100%' }}
            >
                {enableCollapse && tableTitle ? (
                    <Collapse
                        className="smart_detail_page__collapse"
                        size={SIZE}
                        defaultActiveKey={[tableTitle]}
                        items={collapseItems}
                    />
                ) : (
                    collapseItems.map(({ children }) => (
                        <div style={{ width: '100%' }}>
                            {tableTitle ? (
                                <span
                                    style={
                                        isBigMobile
                                            ? {
                                                  color: 'rgba(0, 0, 0, 0.65)',
                                                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                                  display: 'block',
                                                  padding: '8px 16px',
                                                  width: 'calc(100% + 22px)',
                                                  transform: 'translateX(-11px)'
                                              }
                                            : {
                                                  display: 'block',
                                                  alignSelf: 'center',
                                                  color: 'rgba(0, 0, 0, 0.45)',
                                                  paddingBottom: 5
                                              }
                                    }
                                >
                                    {`${tableTitle}:`}
                                </span>
                            ) : null}
                            {children}
                        </div>
                    ))
                )}
            </Flex>
        );
    }
);
