import { i18n } from 'utils/i18n/i18n';

// FIXME: поставить корректный тип
export const renderOptionLabel = (item: any, viewFieldName: string) => {
    if (item) {
        const displayName = item.DisplayName;
        const serviceName = item.Key ?? item.Code ?? item.Id;

        const multilangName = (item.Name ?? item.PluralName ?? displayName)?.[i18n.language];

        const defaultLabel =
            multilangName ?? (typeof displayName === 'string' ? displayName : serviceName);

        if (!item[viewFieldName]) {
            return defaultLabel;
        }

        if (typeof item[viewFieldName] === 'object') {
            return item[viewFieldName][i18n.language] ?? defaultLabel;
        }

        return item[viewFieldName];
    }

    return item;
};
