import { Filter } from 'modules/supabase/utils/supabaseClient';
import { ConvertFilterFunctionType } from '../../../types';
import { convertUIFilterColumnName } from './convertUIFilterColumnName';
import { convertUIFilterValue } from './convertUIFilterValue';

export const convertIdArrayFilterWithContainsOptionToFilter = (
    rawFilterSetting: ConvertFilterFunctionType
): Filter => {
    const column = convertUIFilterColumnName(
        rawFilterSetting.complexFilter.field.ColumnName,
        rawFilterSetting.complexFilterValue.operator!,
        rawFilterSetting.language
    );

    const filterValue: Filter = {
        column,
        operator: 'cs',
        value: rawFilterSetting.complexFilterValue.value[column.slice(0, -1)]
    };

    const value = convertUIFilterValue(
        filterValue.value,
        filterValue.column,
        rawFilterSetting.language,
        filterValue.operator,
        rawFilterSetting.complexFilter.field.ValueType
    );

    filterValue.value = value;

    return filterValue;
};
