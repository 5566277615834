import dayjs, { QUnitType } from 'dayjs';

// import { getDateRangeByDynamicDateRangeValue } from 'components/fields';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { UserSpecificFormat } from 'utils/helpers/dates';
import { ConvertFilterFunctionType } from '../../../types';
import { convertUIFilterColumnName } from './convertUIFilterColumnName';
import { mapMUITableFilterOperationAndSupabaseFilterOperation } from './mapMUITableFilterOperationAndSupabaseFilterOperation';

type DynamicDateRangeOptions =
    | 'OPTION_TODAY' // Сегодня
    | 'OPTION_YESTERDAY' // Вчера
    | 'OPTION_WEEK' // Текущая неделя
    | 'OPTION_MONTH' // Текущий месяц
    | 'OPTION_YEAR' // Текущий год
    | 'OPTION_QUARTER' // Текущий квартал
    | 'OPTION_LAST_N_DAYS' // Последние N дней
    | 'OPTION_LAST_N_MONTHS' // Последние N месяцев
    | 'OPTION_LAST_N_YEARS' // Последние N лет
    | 'OPTION_LAST_N_QUARTERS' // Последние N кварталов
    | 'OPTION_LAST_AND_NEXT_N_DAYS' // -N и +N дней от текущего дня ;
    | 'OPTION_LAST_AND_NEXT_N_MONTH' // -N и +N месяцев от текущего дня
    | 'OPTION_LAST_AND_NEXT_N_YEARS' // -N и +N лет от текущего дня
    | 'OPTION_LAST_AND_NEXT_N_QUARTER'; // -N и +N кварталов от текущего дня

type DynamicDateRangeValueType = {
    option: DynamicDateRangeOptions;
    parametr?: number;
};

const isOptionWithParameter = (option: DynamicDateRangeOptions | undefined): boolean => {
    if (
        option === 'OPTION_LAST_AND_NEXT_N_DAYS' ||
        option === 'OPTION_LAST_AND_NEXT_N_MONTH' ||
        option === 'OPTION_LAST_AND_NEXT_N_QUARTER' ||
        option === 'OPTION_LAST_AND_NEXT_N_YEARS' ||
        option === 'OPTION_LAST_N_DAYS' ||
        option === 'OPTION_LAST_N_MONTHS' ||
        option === 'OPTION_LAST_N_QUARTERS' ||
        option === 'OPTION_LAST_N_YEARS'
    ) {
        return true;
    }

    return false;
};

export const getDateRangeByDynamicDateRangeValue = (
    dynamicDateRangeValue: DynamicDateRangeValueType
): {
    from: Date;
    to: Date;
} | null => {
    if (!dynamicDateRangeValue.option) {
        return null;
    }

    if (isOptionWithParameter(dynamicDateRangeValue.option) && !dynamicDateRangeValue.parametr) {
        return null;
    }

    const today = dayjs();

    if (dynamicDateRangeValue.option) {
        switch (dynamicDateRangeValue.option) {
            case 'OPTION_TODAY':
                return {
                    from: today.startOf('day').toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_WEEK':
                return {
                    from: today.startOf('week').toDate(),
                    to: today.endOf('week').toDate()
                };
            case 'OPTION_MONTH':
                return {
                    from: today.startOf('month').toDate(),
                    to: today.endOf('month').toDate()
                };
            case 'OPTION_YEAR':
                return {
                    from: today.startOf('year').toDate(),
                    to: today.endOf('year').toDate()
                };
            case 'OPTION_QUARTER':
                return {
                    from: today.startOf('quarter' as QUnitType).toDate(),
                    to: today.endOf('quarter' as QUnitType).toDate()
                };
            case 'OPTION_YESTERDAY':
                return {
                    from: today.add(-1, 'day').startOf('day').toDate(),
                    to: today.add(-1, 'day').endOf('day').toDate()
                };
            case 'OPTION_LAST_N_DAYS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'day')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_N_MONTHS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'month')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_N_YEARS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'year')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_N_QUARTERS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'quarter')
                        .startOf('day')
                        .toDate(),
                    to: today.endOf('day').toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_DAYS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'day')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'day')
                        .endOf('day')
                        .toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_MONTH':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'month')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'month')
                        .endOf('day')
                        .toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_QUARTER':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'quarter')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'quarter')
                        .endOf('day')
                        .toDate()
                };
            case 'OPTION_LAST_AND_NEXT_N_YEARS':
                return {
                    from: today
                        .add(-1 * (dynamicDateRangeValue.parametr as number), 'year')
                        .startOf('day')
                        .toDate(),
                    to: today
                        .add(dynamicDateRangeValue.parametr as number, 'year')
                        .endOf('day')
                        .toDate()
                };
            default:
                return null;
        }
    }

    return null;
};

export const convertDynamicDateRangeToFiler = (
    rawFilterSetting: ConvertFilterFunctionType
): Filter => {
    const rangeDates = getDateRangeByDynamicDateRangeValue(
        rawFilterSetting.complexFilterValue.value
    );

    let toValue = ''; // dayjs(rangeDates.to).local().format('YYYY-MM-DD').toString();
    let fromValue = ''; // dayjs(rangeDates.from).local().format('YYYY-MM-DD').toString();

    // const rangeKeys = Object.keys(rawFilterSetting.complexFilterValue.value);
    const lessThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('lessThanOrEqualTo');

    const filterValueTo: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field.ColumnName,
            lessThanOrEqualTo,
            rawFilterSetting.language
        ),
        operator: lessThanOrEqualTo,
        value: null
    };

    const greaterThanOrEqualTo =
        mapMUITableFilterOperationAndSupabaseFilterOperation('greaterThanOrEqualTo');

    const filterValueFrom: Filter = {
        column: convertUIFilterColumnName(
            rawFilterSetting.complexFilter.field.ColumnName,
            greaterThanOrEqualTo,
            rawFilterSetting.language
        ),
        operator: greaterThanOrEqualTo,
        value: null,
        ANDCondition: filterValueTo
    };

    if (rangeDates) {
        if (rawFilterSetting.complexFilter.field.ValueType === 'date') {
            fromValue = dayjs(rangeDates.from).local().format('YYYY-MM-DD').toString();
            toValue = dayjs(rangeDates.to).local().format('YYYY-MM-DD').toString();
        }

        if (rawFilterSetting.complexFilter.field.ValueType === 'datetime') {
            fromValue = dayjs(rangeDates.from)
                .local()
                .format(UserSpecificFormat.getDateTimeFormat())
                .toString();
            toValue = dayjs(rangeDates.to)
                .local()
                .format(UserSpecificFormat.getDateTimeFormat())
                .toString();
        }

        if (rawFilterSetting.complexFilter.field.ValueType === 'datetimelocal') {
            fromValue = dayjs(rangeDates.from)
                .local()
                .format(UserSpecificFormat.getDateTimeLocalFormat())
                .toString();
            toValue = dayjs(rangeDates.to)
                .local()
                .format(UserSpecificFormat.getDateTimeLocalFormat())
                .toString();
        }
    }

    filterValueFrom.value = fromValue;
    filterValueTo.value = toValue;

    return filterValueFrom;
};
