// Code generated by tygo. DO NOT EDIT.
/* eslint-disable */
import { CODE, ANY_DATA, OBJECT_ID, MULTILANG_TEXT, VALUE_TYPE, COLUMN_NAME } from './generated_types';
import { LogMessage } from './generated_smart_context';
import { Meta, HandlerRun, ObjectLock } from './generated_info';

//////////
// source: requests.go

/**
 * структура чтобы только прочитать request id
 */
export interface SafeRequest {
    request_id: string; // will be returned back in response packets
}
/**
 * Base Request structure
 * TODO: добавить уровень вложенности - команды - и может быть массив команд
 */
export interface Request {
    tenant_id?: string; // Для поддержки Multitenancy в будущем. Пока не используется
    auth_provider: string; // provider name for authentication = supabase or keycloak
    access_token: string; // JWT access token for authentication
    request_id: string; // will be returned back in response packets
    return_log_messages: boolean; // should the server return log messages in the response
    Lang?: string; // язык с которого сделали запрос
    UserTimeZone?: string;
    /**
     * Пинг-понг для проверки связи
     */
    ping?: PingRequest;
    /**
     * Получение всех мета данных системы
     */
    infos?: MetasRequest;
    /**
     * Получение мета-данных по мета-коду
     */
    info?: MetaRequest;
    /**
     * Получение массива объектов по мета-коду и фильтру (для табличной страницы)
     */
    select?: SelectRequest;
    /**
     * Получение одного объекта по мета-коду и ID (для детальной страницы)
     */
    get?: GetRequest;
    /**
     * Получение параметра (глобального, пользовательского, из парасета или из объекта)
     */
    get_param?: GetParamRequest;
    /**
     * Сохранение объекта (из детальной страницы)
     */
    save?: SaveRequest; // can be *Entity or Entities
    /**
     * Запуск действия (обработчика)
     */
    run?: RunRequest;
    /**
     * Получение информации о меню с учетом ролей пользователя
     */
    menu?: MenuRequest;
    /**
     * Получение информации о роутах (доступных URL и привязанных к ним компонентах) с учетом ролей пользователя
     */
    routes?: RoutesRequest;
    /**
     * Выполнение ассоциации (получение связанных объектов)
     */
    association?: AssociationRequest;
    /**
     * Работа с локами: захват, продление, освобождение
     */
    acquire_lock?: AcquireLockRequest;
    extend_lock?: ExtendLockRequest;
    release_lock?: ReleaseLockRequest;
    /**
     * Работа с логами: получение логов по запросу
     */
    logs?: LogsRequest;
    /**
     * Подписка на нотификации
     */
    subscribe_notifications?: SubscribeNotifications;
    /**
     * Обмен токена приглашения (из емейла) на открытую сессию - то есть по факту логин через приглашение
     */
    exchange_invite_token_for_session?: ExchangeInviteTokenForSession;
}
/**
 * Specific request payload structures
 */
export interface MetasRequest {
}
export interface MetaRequest {
    meta: CODE;
}
/**
 * api.get({meta: "PRODUCT", id: 1}) -> {id: 1, name: "Product 1", ...}
 */
export interface GetRequest {
    meta: CODE;
    id: OBJECT_ID; // TODO: решить - тут ID или условие сложное тоже нужно?
    expandAll?: boolean;
}
export interface Entity {
    meta: CODE;
    object: any;
}
export interface SelectResponse {
    /**
     * TODO: решить может быть тут еще мету возвращать?
     */
    objects: { [key: string]: any}[];
}
export interface SaveRequest {
    meta: CODE;
    unique_fields?: string[]; // список полей, по которым нужно искать существующие объекты (для upsert)
    /**
     * TODO: а что мы делаем с детьми которые тут не указаны? удаляем? оставляем?
     */
    objects?: any[];
}
export interface DictRequest {
    meta: CODE;
    code: CODE;
}
export interface RunRequest {
    /**
     * update: убрать поле meta - оно лишнее - нет - оно НЕ лишнее. оно нужно когда у handler нет meta! это когда handler обобщенный типа SetStatus - тогда мета приходит здесь из Action
     */
    Action_Id: OBJECT_ID; // ID действия, если оно есть. Используется чтобы проверить разрешения на выполнение действия относительно переданных объектов
    handler: CODE; // теперь это поле становится не нужным - а нужным - ActionId!
    meta: CODE; // на самом деле это не нужно - ведь у HandlerCode уже указан Meta. Может быть убрать? UPDATE: у Handler может не быть Meta - общий хандер, тогда берем отсюда
    ids: string[]; // в зависимости от типа обработчика он либо будет запущен ДЛЯ КАЖДОГО объекта отдельно или для всех сразу
    args: ANY_DATA; // TODO: rename to Params?
}
export interface PingRequest {
    payload: string;
}
export interface MenuRequest {
}
export interface RoutesRequest {
}
export interface AssociationRequest {
    /**
     * код конкретной ассоциации
     */
    association: CODE;
    /**
     * ID объектов модели SourceMeta из ассоциации для которых нужно вернуть результирующие ID модели TargetMeta
     * IDs of associated objects - могут быть как int так и uuid
     */
    ids: string[];
}
export interface SelectRequest {
    meta: CODE;
    fields: string[]; // Fields to select
    filters: string; // Filters e.g., "Id=gt.10,Name=eq.John"
    sort: string; // Sorting e.g., "Name asc,Id desc"
    page: number /* int */;
    page_size: number /* int */;
}
export interface AcquireLockRequest {
    meta: CODE;
    id: OBJECT_ID; // NB! всегда заплашиваем на лок только последнюю версию! Даже если клиент не знает какая она.
    force?: boolean; // перезаписать чужой лок если он есть
}
export interface ExtendLockRequest {
    lock_id: OBJECT_ID; // ID блокировки
}
/**
 * Снимает блокировку если объект все еще был нами залочен
 */
export interface ReleaseLockRequest {
    lock_id: OBJECT_ID; // ID блокировки
}
/**
 * Запрашивает параметры с парасета
 */
export interface GetParamRequest {
    param_name: string;
    /**
     * TODO: meta_code, object_id вместо paraset_id
     */
    meta_code?: CODE;
    object_id?: OBJECT_ID;
}
export interface LogsRequest {
    request_id: string;
    filters: ANY_DATA; // json
}
export interface SubscribeNotifications {
}
export interface ExchangeInviteTokenForSession {
    token: string;
}

//////////
// source: responses.go

export interface Response {
    session_id: string;
    request_id: string;
    status_code: StatusCode;
    error_text?: string;
    /**
     * тут только то что юзеру разрешено в настройках!
     */
    log_messages?: LogsResponse;
    /**
     * пинг-понг для проверки связи
     */
    ping?: PingResponse;
    /**
     * получения всех мета данных системы
     */
    infos?: (Meta | undefined)[];
    /**
     * получение мета-данных по мета-коду
     */
    info?: Meta;
    /**
     * получение массива объектов по мета-коду и фильру (для табличной страницы)
     */
    select?: SelectResponse; // массив объектов запрошенной меты с полями которые могут быть объектами и массивами
    /**
     * получение оддного объекта по мета-коду и ID (для детальной страницы)
     */
    get?: GetResponse; // тут сам объект
    /**
     * получение параметра (глобального, пользовательского, из парасета или из объект)
     */
    get_param?: GetParamResponse;
    /**
     * сохранение объекта (из детальной страницы)
     */
    save?: SaveResponse; // тут возвращаем все те же отправленные на сохранение объекты но в них уже могут быть дозаполнены некоторые поля - например ID
    /**
     * запуск действия (обработчика)
     */
    run?: (HandlerRun | undefined)[];
    /**
     * получение информации о меню с учетом ролей пользователя
     */
    menu?: MenuResponse;
    /**
     * получение информации о роутах (доступных URL и привязанных к ним компонентах) с учетом ролей пользователя
     */
    routes?: Route[];
    /**
     * выполнение ассоциации (получение связанных объектов)
     */
    association?: AssociationResponse;
    /**
     * работа с локами: захват, продление, освобождение
     */
    acquire_lock?: ObjectLock;
    extend_lock?: ObjectLock;
    release_lock?: ReleaseLockResponse;
    /**
     * работа с логами: получение логов по запросу
     */
    logs?: LogsResponse;
    /**
     * работа с уведомлениями: получение количества непрочитанных уведомлений
     */
    notification?: NotificationResponse;
    /**
     * обмен токена приглашения на сессию
     */
    exchange_invite_token_for_session?: ExchangeInviteTokenForSessionResponse;
}
export interface PingResponse {
    payload: string;
}
export interface Route {
    id: OBJECT_ID; // Unique ID (taken from nav_item.id)
    path: string; // can be with :id
    component?: string; // какой компонент нужно рендерить по этому пути - опциональное поле
    meta?: CODE; // NULLABLE. что в этот компонент нужно передавать в качестве меты чтобы он знал что дальше делать
    detail_route_id?: OBJECT_ID; // NULLABLE. если это табличная страница то тут будет ID роута с детальной страницей
    layout?: any /* info.Layout */; // NULLABLE. layout для этого роута
    is_on_springboard: boolean;
}
export interface SaveResponse {
    objects?: { [key: string]: any}[]; // or many objects
}
export interface MenuItem {
    id: OBJECT_ID; // Unique ID
    name: MULTILANG_TEXT; // название страницы
    icon: string; // Material-UI icon name
    parent_id?: OBJECT_ID; // Pointer to allow null values for top-level items
    child_index?: number /* int */; // порядок внутри родителя (если есть) или внутри вернхнего уровня
    is_folder: boolean; // папки или не папка (определяется по тому есть ли дети у этого пункта меню)
    /**
     * у папки путь всегда будет отсутствовать!
     */
    nav_item_id: string; // ID of nav_item (for routes)
    path: string; // URL route path
    is_hidden: boolean; // скрытый пункт меню (не показывать в меню но показывать в роутах - хотя для роутов у нас другой метод есть - так что вопрос нужно ли эту галочку - можно было бы весь пункт не возвращать на фронтенд!?)
    is_on_springboard: boolean;
}
export interface MenuResponse {
    items: MenuItem[];
}
export interface AssociationResponse {
    hash_key: string; // Hash Key перехода (для переиспользование табов с одним и тем же hash_key)
    ids: string[]; // IDs of associated objects - могут быть как int так и uuid (передается через state)
}
export interface GetResponse {
    /**
     * TODO: решить может быть тут еще мету возвращать? или это не нужно?
     */
    object: { [key: string]: any};
}
/**
 * Запрашивает параметры с парасета
 */
export interface GetParamResponse {
    param_value: any /* types.ANY_TYPE */;
    param_value_type: VALUE_TYPE;
}
export interface ReleaseLockResponse {
}
export interface LogMessageResponse {
    CurrentPath: string;
    children: (LogMessageResponse | undefined)[];
    LogMessage: LogMessage;
}
export interface LogsResponse {
    created_objects?: LogMessage[];
    log_messages?: (LogMessageResponse | undefined)[];
}
export interface NotificationResponse {
    num_unread: number /* int64 */;
}
export interface ExchangeInviteTokenForSessionResponse {
    User: any /* supabase.AuthenticatedDetails */;
}

//////////
// source: status.go

export type StatusCode = number /* int */;
export const StatusCodeOK: StatusCode = 200;
/**
 * бизнес ошибки удобно чтобы API возвращало: get не нашел объект, get_param не нашел параметр
 * но их логгировать не нужно как ошибки => НЕ логгируем их
 */
export const StatusCodeBadRequest: StatusCode = 400;
export const StatusCodeUnauthorized: StatusCode = 401;
export const StatusCodeForbidden: StatusCode = 403;
export const StatusCodeNotFound: StatusCode = 404;
export const StatusCodeMethodNotAllowed: StatusCode = 405;
export const StatusCodeRequestTimeout: StatusCode = 408;
export const StatusCodeConflict: StatusCode = 409;
export const StatusCodeInternalServerError: StatusCode = 500;
export const StatusCodeNotImplemented: StatusCode = 501;
export const StatusCodeBadGateway: StatusCode = 502;
export const StatusCodeServiceUnavailable: StatusCode = 503;
export const StatusCodeGatewayTimeout: StatusCode = 504;
