import { Flex, Progress } from 'antd';

import { Loader } from 'ui/Loader/Loader';

export const AppLoader: React.FC<{ percent: number }> = ({ percent }) => {
    return (
        <Flex vertical align="center" gap={20}>
            <Loader />
            <Progress
                // type="circle"
                status="active"
                style={{ width: 300, position: 'absolute', top: '52%' }}
                // style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                percent={percent}
            />
        </Flex>
    );
};
