import { Route } from 'modules/services/backend-api/generated_api';
import { metaStore } from 'utils/store/MetaStore';

export const findRoute = (metaName: string): Route | undefined => {
    const allMeta = metaStore.meta.get('all');

    if (!allMeta) {
        console.log('[SmartSelectField.handleNavigate] no meta routes.');
        return undefined;
    }

    const routes = allMeta.routesMap;

    if (!routes) {
        console.log('[SmartSelectField.handleNavigate] no routes.');
        return undefined;
    }

    const needle = routes.get(metaName)?.[0];

    if (!needle) return undefined;

    return needle;
};
