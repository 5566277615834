import { Descriptions, DescriptionsProps, Flex, Space, Typography } from 'antd';
import { memo, useMemo } from 'react';

import './FieldsBox.scss';
import { HelpIcon, SmartTooltip } from 'smart/ui';
import { isNumber } from 'is-lite/exports';
import { useMedia } from 'react-use';
import { MobileFieldsBox } from './MobileFieldsBox';

export type FieldsBoxItemType = NonNullable<DescriptionsProps['items']>[number] & {
    vertical?: boolean;
    description?: string;
    required?: boolean;
};

export interface FieldsBoxProps {
    items: FieldsBoxItemType[];
    size: 'small' | 'middle' | 'default';
    column?: DescriptionsProps['column'];
    labelWidth?: number | string;
    contentWidth?: number | string;
    longestElementWidth?: number;
    bordered?: boolean;
    contentStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    helpIconPlacement?: 'end' | 'start';
    layout?: 'vertical' | 'horizontal';
}

const getLabelStyle = (labelWidth?: number | string, style?: React.CSSProperties) => ({
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    padding: 5,
    // width: labelWidth || 'auto',
    width: isNumber(labelWidth) ? labelWidth - 19 : `calc(${labelWidth} - 19px)`,
    display: 'flex',
    backgroundColor: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...style
});

const getContentStyle = (contentWidth?: number | string, style?: React.CSSProperties) => ({
    paddingLeft: 5,
    alignSelf: 'center',
    width: contentWidth || 'calc(100% - 250px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    ...style
    // width: contentWidth
    //     ? isNumber(contentWidth)
    //         ? contentWidth - 19
    //         : `calc(${contentWidth} - 19px)`
    //     : 'calc(100% - 250px)',
});

const DEFAULT_LABEL_WIDTH = '17vw';

const DesktopFieldsBox = memo<FieldsBoxProps>(
    ({
        items,
        column,
        contentWidth,
        // labelWidth = '40%',
        // labelWidth = '240px',
        longestElementWidth,
        bordered,
        labelStyle,
        contentStyle,
        helpIconPlacement,
        layout = 'horizontal',
        labelWidth = '17vw',
        size = 'default'
    }) => {
        const isSm = useMedia('(max-width: 767px)');

        const labelAdaptiveWidth = isSm && labelWidth === DEFAULT_LABEL_WIDTH ? '34vw' : labelWidth;

        const tooltipedItems = useMemo(
            () =>
                items.map((item) => ({
                    ...item,
                    label: item.label ? (
                        <div style={{ position: 'relative' }}>
                            {item.required && (
                                <Typography.Text
                                    style={{ position: 'absolute', top: '-3px', left: '-6px' }}
                                    type="danger"
                                >
                                    *
                                </Typography.Text>
                            )}
                            <SmartTooltip style={{ color: 'rgba(0,0,0,0.45)' }}>
                                {item.label}
                            </SmartTooltip>
                        </div>
                    ) : undefined,
                    children: (
                        <HelpIcon
                            text={item.description}
                            placement={
                                helpIconPlacement ??
                                ((item.span as number) > 1 || item.vertical ? 'end' : 'start')
                            }
                            // text={'asdasdsadasd'}
                            // style={{ width: 'fit-content' }}
                        >
                            {item.children}
                        </HelpIcon>
                    )
                })),
            [items, helpIconPlacement]
        );

        const span1 = tooltipedItems?.filter((item) => item.span !== 2 && !item.vertical) || [];
        const span2 = tooltipedItems?.filter((item) => item.span === 2 && !item.vertical) || [];
        const vertical = tooltipedItems?.filter((item) => item.vertical) || [];

        return (
            <Flex vertical gap={5}>
                {!!span1.length && (
                    <Descriptions
                        layout={layout}
                        bordered={bordered}
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(
                            longestElementWidth || labelAdaptiveWidth,
                            labelStyle
                        )}
                        contentStyle={getContentStyle(contentWidth, contentStyle)}
                        items={span1}
                        column={column ?? { xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                    />
                )}
                {!!span2.length && (
                    <Descriptions
                        layout={layout}
                        bordered={bordered}
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(labelAdaptiveWidth, labelStyle)}
                        contentStyle={getContentStyle(contentWidth, contentStyle)}
                        items={span2}
                        column={column ?? { xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                    />
                )}
                {!!vertical.length && (
                    <Descriptions
                        bordered={bordered}
                        layout="vertical"
                        size={size}
                        className="fields_box"
                        labelStyle={getLabelStyle(labelAdaptiveWidth, labelStyle)}
                        contentStyle={getContentStyle(
                            contentWidth,
                            bordered
                                ? {
                                      ...contentStyle,
                                      padding: 0
                                  }
                                : contentStyle
                        )}
                        items={vertical}
                        column={column ?? { xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                    />
                )}
            </Flex>
        );
    }
);

export const FieldsBox = memo<FieldsBoxProps>((props) => {
    const isBigMobile = useMedia('(max-width: 480px)');

    if (isBigMobile) return <MobileFieldsBox {...props} />;

    return <DesktopFieldsBox {...props} />;
});
